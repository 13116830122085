import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[appLoader]'
})
export class LoaderDirective implements OnInit, OnChanges{
  @Input() isLoading = false;
  @Input() text = ""
  constructor(private el: ElementRef) { }


  setText() {
    this.el.nativeElement.innerHTML = this.isLoading == true ? '<i class="fa fa-spin fa-spinner"></i>' : this.text;
  }

  ngOnInit() {
    this.setText();
  }
  ngOnChanges() {
    this.setText();
  }
}
