import { NgForm } from '@angular/forms';
import { CommonService } from './../../_services/common.service';
import { UtilService } from './../../_services/util.service';
import { DataService } from './../../_services/data.service';
import { ApiService } from './../../_services/api.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AnimationService } from './../../_services/animation.service';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-tie-breaker',
  templateUrl: './tie-breaker.component.html',
  styleUrls: ['./tie-breaker.component.css']
})
export class TieBreakerComponent implements OnInit {
  data = [];
  isLoding = false;
  constructor(private animation: AnimationService, private spinner: NgxUiLoaderService,
              private api: ApiService, private dataService: DataService,
              private util: UtilService, private common: CommonService) { }

  ngOnInit(): void {
    this.dataService.closeResultPopup.emit();
    this.animation.init();
    this.fetchQuestions();
  }
  loadingStart() {
    this.isLoding = true;
    this.spinner.start();
  }

  loadingStop() {
    this.isLoding = false;
    this.spinner.stopAll();
  }
  fetchQuestions() {
    if (this.dataService.isAllowToOpenTieBreakerPage() == false) {
      this.dataService.checkUserProgressAndRedirect();
    }
    this.loadingStart();
    this.api.getTieBreakerQuestions(this.dataService.TOKEN)
    .subscribe(data => {
      const questions = _.get(data, 'data.question', []);
      const answers = _.get(data, 'data.answers', []);
      this.data = _.map(questions, (e )=> {
        const ab = _.pickBy(e, (value, key) => {
              return _.startsWith(key, "op_");
        });
        const ans = _.find( answers, (a) => {
          return a['question_id'] == e.id;
        });
        return {
          id : e.id,
          options: ab,
          'user_answer': !this.util.isEmpty(ans) ? ans['user_answers'] : ''
        };
      });
      this.loadingStop();
    }, err => {
      this.loadingStop();
    });
  }
  getOptionCharachter(index: number) {
    return String.fromCharCode(65 + index);
  }

  onChangeOptionChange($evnet, id) {
    const quest = _.find(this.data, (e) => {
      return e.id == id;
    });
    if (!this.util.isEmpty(quest)) {
      this.common.saveTieBreakerAnswer(this.dataService.TOKEN, quest['id'], quest['user_answer']);
    }
  }
  getData() {
    return _.map(this.data, (e)=> {
      return {id: e.id, ans:e['user_answer']};
    });
  }
  onNextButtonClick(f: NgForm) {
    if (!f.valid) {
      return;
    }
    this.dataService.openResultPopup.emit();
  }
}
