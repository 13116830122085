<section class="multi_step_form">
    <form id="msform"> 
    <fieldset class="congratulations_wrap">
        <div data-aos="flip-down" data-aos-delay="1000" data-aos-duration="1000" class="congratulations_cnt">
            <h1>Congratulations</h1>
            <p>You have taken the first step to choosing the right curriculum for your child</p>

            <div class="congratulations_text">
                <p>Take your time, think through and answer all the questions in the following personalized assessment. For your better understanding we have added a few videos. Watch them to make informed decisions.</p>
            </div>		 
        </div>
    <a (click)="onHomeButtonClick()"  data-aos="fade-up" data-aos-delay="1300" data-aos-duration="1500" class="primary_btn">Let’s Get Started</a>
    </fieldset> 
    </form>
</section> 