<div class="form-group">
    <label class="form-label mb30">{{q.title}}</label>
    <ng-container *ngIf="!util.isEmpty(q?.video_url)">
        <div class="btn_play adj">
            <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
        </div>
    </ng-container>
    <div class="row rank_filled">
        <ng-container *ngFor="let item of getOrderableData() ; let i=index;">
            <div class="sps col-sm-2" *ngIf="!editRank && valid">
                <div class="nb_count">
                    <span>{{data[dataService.getIdByKey(item.key)]}}</span>
                </div>
            </div>
        
            <div class="sps" [ngClass]="{'col-sm-10': editRank == false,'col-sm-9': editRank == true}">
                <div class="noselection">{{item.value}}</div>
            </div>
            <div *ngIf="editRank" class="sps col-sm-3">
                <div class="selector_wrap">
                    <span *ngIf="i == 0" class="choose">Choose rank</span>
                    <div class="b-select-wrap">
                      <select (focusout)="onRankFocusOut()" [ngClass]="{'rank-required': tiggerChangeEvent == true && btnClicked && !valid && util.isEmpty(data[dataService.getIdByKey(item.key)]) || tiggerChangeEvent == false && isShowConfirmButton && !valid && util.isEmpty(data[dataService.getIdByKey(item.key)])}" [id]="item.key" [name]="item.key" (change)="onRankeInputChange()" [(ngModel)]="data[dataService.getIdByKey(item.key)]" required="true" class="form-control b-select">
                        <option value="">Rank</option>
                        <option *ngFor="let op of getOptions(item.key)" [value]="op">{{op}}</option>
                      </select>
                    </div>
                </div>
            </div>   
        </ng-container>                                     
    </div> 
    <div class="row" *ngIf="tiggerChangeEvent == true && btnClicked == true && !valid && editRank || tiggerChangeEvent == false && isShowConfirmButton == true && !valid && editRank">
        <div class="edit_remarks">
            <span class="error_msg" [@fade] *ngIf="tiggerChangeEvent == true && btnClicked == true && !valid && editRank || tiggerChangeEvent == false && isShowConfirmButton == true && !valid && editRank">Please select Rank</span>
        </div>
    </div>

    <div class="row" [@fade] *ngIf="editRank && tiggerChangeEvent == false &&  isShowSuccessMessage == true">
        <div class="edit_remarks">
            <span class="error_msg">{{sucessMessage}}</span>
        </div>
    </div>
    
</div>
<div class="row" *ngIf="tiggerChangeEvent == true && editRank">
    <div class="edit_remarks">
        <ng-container>
            <button type="button" (click)="toggleEdit()" class="next primary_btn cntrl" [disabled]="isLoading == true" *ngIf="editRank" appLoader [text]="getBtnText()" [isLoading]="isLoading"></button>
            <!-- <ng-container *ngIf="tiggerChangeEvent == false; else withTrigger">
                <button type="button" (click)="toggleEdit()" class="next primary_btn cntrl" [disabled]="isLoading == true" *ngIf="editRank" appLoader [text]="getBtnText()" [isLoading]="isLoading"></button>
            </ng-container>
            <ng-template #withTrigger>
                <button type="button" (click)="toggleEdit()" class="next primary_btn cntrl" *ngIf="editRank"> {{ tiggerChangeEvent == true ? 'Next' : 'Confirm'}} </button>
            </ng-template> -->
        </ng-container>
    </div>
</div>