<ng-container *ngIf="!isLoading">
  <section class="multi_step_form video_section">
    <form id="msform" name="form" #f="ngForm" [autocomplete]="'off'">
      <div class="assessment_sec ">
        <div class="pagination_wrap page_wrap">
        </div>
        <div class="fieldset_wrap">
          <fieldset>
            <div class="assessment_cnt">
              <!-- <button type="button" class="next skip"><a>Continue Assessmnet</a></button> -->
              <div class="form_cnt session">
  
                <div class="row">
                  <div class="col-sm-12">
                    <p class="txt_right text-center bdr">Choose your suitable time to discuss with<br> Counsellor in
                      detail.</p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-sm-6">
                    <img class="mrg_top" src="assets/images/icon55.svg" alt="">
                  </div>
                  <div class="col-sm-6">
                    <p class="txt_right bdr1">Select Preferred Date</p>
                    <div class="row">
                      <!-- <input type="text" class="form-control" placeholder="Datepicker" bsDatepicker [(ngModel)]="datepickerModel" name="date"/> -->
                      <div class="col-sm-12">
                        <div class="form-group">
                          <input type="text"
                                 (keydown)="onUserInput()"
                                 [minDate]="getDate(datePickerConfig?.minDate)"
                                 [maxDate]="getDate(datePickerConfig?.maxDate)"
                                 class="form-control"
                                 #dp="bsDatepicker"
                                 [placeholder]="datePickerConfig?.format"
                                 bsDatepicker 
                                 required
                                 name="name"
                                 [bsConfig]="{ containerClass: 'theme-blue',isAnimated: true, dateInputFormat: datePickerConfig?.format}"
                                 [(ngModel)]="data.date"
                                 #date="ngModel"
                                 [required]="true"
                                 (bsValueChange)="onDateChange($event)"
                        >
                        </div>
                      </div>
                    </div>
                    <p class="txt_right bdr1">Select Preferred Time</p>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <div class="arrow_right">
                            <select [required]="true" [disabled]="date?.errors!=null && date?.errors?.required && timeslots.length == 0" class="form-control" name="timeslot" [(ngModel)]="data.timeSlot">
                              <option value="">Timeslot</option>
                              <option *ngFor="let slot of timeslots" [value]="getValueFormat(slot)">{{getTime(slot?.from)}} - {{getTime(slot?.to)}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="primary_btn cntrl select" [disabled]="!f.valid || isSubmitting" (click)="submit(f)"><a appLoader [text]="'Confirm'" [isLoading]="isSubmitting"></a></button>
                  </div>
  
                </div>
  
              </div>
            </div>
  
  
          </fieldset>
        </div>
      </div>
    </form>
  </section>  
</ng-container>
<ng-template #schedulePopup>
  <!-- Modal -->
  <div class="modal-body">
    <p class="session">Your session is scheduled for</p>
    <div class="row">
      <div class="col-sm-6">
        <div class="left_side">
          <span>{{  getFormatedDate(data?.date) }}</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="right_side">
          <span>{{ getFormatedTime(data?.timeSlot) }}</span>
        </div>
      </div>
      <ng-container *ngIf="isJoinNowVisible() == true; else viewResult">
        <button type="button" class="primary_btn cntrl" (click)="onJoinNowClick()"><a>Join Now</a></button>
      </ng-container>
      <ng-template #viewResult>
        <button type="button" class="primary_btn cntrl"><a (click)="onViewResultClick()">View Result</a></button>
      </ng-template>
    </div>
  </div>
  <a class="reshule text-center" *ngIf="isReScheduleVisible()" (click)="onReScheduleClick()">Reschedule</a>
  <div class="modal-footer" *ngIf="isContactToSuppoerMsgVisible()">		   
    <div class="rechedule text-center">
      <div class="width_adj">
        <img class="quetion" src="assets/images/warning.svg" width="23px"><p>To Reschedule or for any other quries please contact our support team.</p>
        <p><img class="whatsapp" src="assets/images/whatsapp.svg" width="23px"> <b>+91 9898 98 9898</b></p>
      </div>
    </div>
  </div>
</ng-template>