import { trigger, transition, style, animate } from '@angular/animations';
import { UtilService } from './../../_services/util.service';
import { VideoPopupService } from './../../_services/video-popup.service';
import { ControlValueAccessor, NgModel, NG_VALUE_ACCESSOR, NgControl, NG_VALIDATORS, NgForm } from '@angular/forms';
import { AssessmentComponent } from './../../views/assessment/assessment.component';
import { DataService } from './../../_services/data.service';
import { Component, OnInit, Input, ViewChild, Optional, Host, forwardRef, Self, Inject, Injector, OnChanges } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-rank-question',
  templateUrl: './rank-question.component.html',
  styleUrls: ['./rank-question.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RankQuestionComponent),
      multi: true
    }
  ],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }), 
        animate(1000, style({ 'opacity': 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }), 
        animate(1000, style({ 'opacity': 0 }))
      ])
    ])
  ]
})
export class RankQuestionComponent implements OnInit , ControlValueAccessor {
  @Input() editRank = true;
  btnClicked = false;
  @Input() questionId: any;
  @Input() name: string;
  @Input() q: any;
  @Input() valid = false;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  @Input() options = [];
  @Input() data = {
  };
  prevData = {

  }
  @Input() tiggerChangeEvent = true;
  @Input() isSkippedQuestionModule = true;
  isShowConfirmButton = true;
  isShowSuccessMessage = false;
  sucessMessage = "";
  isLoading = false;
  constructor(public dataService: DataService, public videoService: VideoPopupService, public util: UtilService,
              @Host() private form: NgForm
  ) { }

  get model() {
    return this.data;
  }
  @ViewChild(AssessmentComponent) assesmentComponent: AssessmentComponent;
  ngOnInit(): void {
    this.btnClicked = false;
    if (this.form.valid && this.tiggerChangeEvent == false) {
      this.isShowConfirmButton = false;
      this.prevData = {...this.data};
    } 
  }

  getOptions(key) {
    let arr = [];
    const keys = _.map(this.options, 'key', []);
    for (let i = 1; i <= keys.length; i++) {
      arr.push(i);
    }
    _.forEach(keys, (k) => {
      if (key != k && !this.util.isEmpty(this.dataService.getIdByKey(k))) {
        _.remove(arr, (e) => {
            return e == this.data[this.dataService.getIdByKey(k)];
        });
      }
    });
    return arr;
  }

  toggleEdit() {
    this.btnClicked = true;
    if (!this.valid) { return false; }
    this.isLoading = true;
    this.dataService.setQuestionsAnswer(this.questionId, this.data, false, this.isSkippedQuestionModule);
    this.prevData = {...this.data};
    if (this.tiggerChangeEvent === true) {
      this.editRank = !this.editRank;
      this.dataService.emitToggleEditEvent(this.editRank);
    }
    setTimeout(() => {
      this.isShowConfirmButton = false;
      this.isLoading = false;
      this.showSuccessMessage("Rank Saved Successfully");
    }, 1000);
  }

  writeValue(value: any): void {
    this.data = value;
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this.data = value;
    this.onChange(this.data);
  }

  onRankeInputChange() {
    this.onChange(this.data);
    this.isShowConfirmButton = true;
  }

  getOrderableData() {
    let result = [ ...this.options ];
    if (!this.editRank) {
      result = _.sortBy(result, (a) => {
        return _.isEmpty(this.data[this.dataService.getIdByKey(a.key)]) ? 0 : this.data[this.dataService.getIdByKey(a.key)];
      });
    }
    return result;
  }

  showSuccessMessage(msg) {
    this.sucessMessage = msg;
    this.isShowSuccessMessage = true;
    setTimeout(() => {
      this.isShowSuccessMessage = false;
      this.sucessMessage = "";
      this.isShowConfirmButton = false;
      this.isLoading = false;
    }, 3000);
    
  }
  getBtnText() {
    return this.tiggerChangeEvent == true ? 'Next' : 'Confirm'
  }
  onRankFocusOut() {
    if (this.tiggerChangeEvent == false && this.valid && _.isEqual(this.data, this.prevData) == false) {
      this.toggleEdit();
    }
  }
}
