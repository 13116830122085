import { PhoneMaskDirective } from './../../_directives/phone-mask.directive';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from './../../_services/data.service';
import { ApiService } from './../../_services/api.service';
import { UtilService } from './../../_services/util.service';
import { CONFIGCONSTANTS } from './../../config/app-constants';
import { NgForm } from '@angular/forms';
import { VideoPopupService } from './../../_services/video-popup.service';
import { AnimationService } from './../../_services/animation.service';
import { Component, OnInit, TemplateRef, AfterViewInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('600ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class PersonalInformationComponent implements OnInit, AfterViewInit {
  isLoading = false;
  modalRef: BsModalRef;
  registraionDone = false;
  data = {
    fullName : '',
    email: '',
    phone: '',
    childName: '',
    relation: null
  };
  config = CONFIGCONSTANTS.PHONE_MASK_CONFIG;
  childRelations = ['Mother', 'Father', 'Guardian'];
  submited = false;
  constructor(private animation: AnimationService, private modalService: BsModalService,
              private router: Router, private videoService: VideoPopupService,
              private api: ApiService, public dataService: DataService, private util: UtilService,
              private loader: NgxUiLoaderService) { }

  ngOnInit(): void {
    if (this.dataService.isToAllowOpenPersonalInfoPage() == false) {
      this.dataService.checkUserProgressAndRedirect();
    }
    this.loader.start();
    this.isLoading = true;
      this.getPersonalInfo();
  }

  onPersonalInfoSubmitHandler(f: NgForm) {
    if (f.valid) {
      this.submited = true;
      const data = new FormData();
      data.append('token', this.dataService.TOKEN);
      data.append('name', this.data.fullName);
      data.append('email', this.data.email);
      data.append('mobile', this.data.phone.replace(/\s/g,''));
      data.append('child_name', this.data.childName);
      data.append('relation', this.data.relation);
      this.api.savePersonalInfo(data)
      .subscribe(item => {
        this.dataService.data.currentPage = 1;
        this.dataService.setValueToDataObject(CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED, 1);
        this.registraionDone = true;
        this.submited = false;
        this.animation.init();
        this.util.scrollPageToTop();
      }, error => {
        this.api.checkIfUnAuthorized(error);
        this.submited = false;
        this.registraionDone = true;
        this.animation.init();
        this.util.scrollPageToTop();
      });
    }
  }
  ngAfterViewInit() {
    
  }

  openModel() {
    this.videoService.openModel(CONFIGCONSTANTS.LANDING_PAGE_VIDEO_URL, true);
  }
  onRelationChange(data) {
    this.data.relation = data;
  }
  getPersonalInfo() {
    this.api.getEmailPhone(this.dataService.TOKEN).subscribe(data => {
      if (!_.isEmpty(_.get(data, 'data.email', null))) {
        this.data.email = _.get(data, 'data.email', null);
      }
      if (!_.isEmpty(_.get(data, 'data.phone', null))) {
        this.data.phone = _.get(data, 'data.phone', null);
      }
      this.animation.init();
      this.loader.stopAll();
      this.isLoading = false;
    }, error => {
      this.animation.init();
      this.loader.stopAll();
      this.isLoading = false;
    })
  }
  getSectionClass(obj) {
    return _.get(obj, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.REGISTRATION_SECTION_CLASS , '');
  }
  getSectionImage(obj) {
    const imgPath = _.get(obj, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.ASSESMENT_ICON , '');
    return !this.util.isEmpty(imgPath) ? this.dataService.data.page_info.path + imgPath :imgPath;
  }
  getTotalNumberOfQuestions(id) {
    const arr = _.filter(this.dataService.data.questions, (d)=> d['category_id']==id);
    return arr.length;
  }
  getLength(obj) {
    return _.values(obj).length;
  }
}
