
            <div [class]="'row '+ util.getSectionClass(q)">
                <ng-container *ngFor="let ans of dataService.getDataList(q); let i=index;">
                <ng-container *ngIf="ans == constants.INPUT_TYPE.OTHERS else field">
                <div class="col-sm-12">
                    <ng-container *ngIf="util.isQuestionTypeIsAutoComplete(q) else rawText">
                        <app-autocomplete  [callStateOnChange]="false" #l="ngModel" [(ngModel)]="otherval" [data]="otherval" (autocompleteChange)="onAutoCompleteComponentChange($event)" [question]="q" [questionId]="q.id" [id]="q.id" [required]="required" [name]="q.id"></app-autocomplete>
                    </ng-container>
                    <ng-template #rawText>
                    <input type="text" appOtherInputValidator [onlyTextField]="true" [ngClass]="{'minimum-required': errors!=null && errors?.otherMinimumRequired!=null}" #rText="ngModel" (change)="InputChange(rText?.valid)" [(ngModel)]="otherval" class="form-control" [id]="q.id+'_'+i" [name]="q.id+'_other'" placeholder="Others">
                        <ng-container *ngIf="errors!=null && errors?.otherMinimumRequired!=null">
                            <span class="error_msg">{{errors?.otherMinimumRequired}}</span>
                        </ng-container>
                    </ng-template>
                </div>
                </ng-container>
                <ng-template #field>
                    <div [class]="getClass(q)">
                        <div class="radio-group">
                            <input appOnlyCharachters [validate]="util.isOnlyAllowCharachters(q)" [(ngModel)]="q.user_answer" (click)="onRadioClick($event, ans)" (change)="valueChange()" [required]="required" appPlaySound [id]="q.id+'_'+i" [name]="q.id" type="radio" [value]="ans">
                            <label [for]="q.id+'_'+i">{{ans}}</label>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </div>