import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../_services/api.service';
import { UtilService } from './../../_services/util.service';
import { DataService } from './../../_services/data.service';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-submit-result-popup',
  templateUrl: './submit-result-popup.component.html',
  styleUrls: ['./submit-result-popup.component.css']
})
export class SubmitResultPopupComponent implements OnInit , OnDestroy{
  @ViewChild('resultConfirmationPopup') resultConfirmationPopup: TemplateRef<any>;
  modelRef: BsModalRef;
  onOpenSubscription: Subscription;
  onCloseSubscription: Subscription;
  submited = false;
  constructor(private modalService: BsModalService, private dataService: DataService, private util: UtilService,
              private api: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.onOpenSubscription = this.dataService.openResultPopup.subscribe(data => {
      this.openModel();
    });
    this.onCloseSubscription = this.dataService.closeResultPopup.subscribe(data => {
      this.closeModel();
    });
  }

  ngOnDestroy(): void {
    this.onOpenSubscription.unsubscribe();
    this.onCloseSubscription.unsubscribe();
  }

  openModel() {
    this.modelRef = this.modalService.show(this.resultConfirmationPopup,
      { class: 'modal-md video_modal bg_grad ctm_model modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  closeModel() {
    this.modelRef.hide();
  }
  async onViewResultClick() {
    this.submited = true;
    const submitResp = await this.dataService.submitAllQuestions();
    const finalResult = await this.api.storeUserFinalResult(this.dataService.TOKEN).subscribe(data => {
      this.dataService.closeResultPopup.emit();
      setTimeout(() => {
        this.util.navigate('/recommendations');
      }, 100);
      this.submited = false;
    }, error => {
      const err = _.get(error, "error.meta.message", "Something Went Wrong");
      this.toastr.error(err);
      this.submited = false;
    });
  }
}
