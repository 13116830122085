	<ng-autocomplete #auto [isLoading]="isLoading" [data]="options" [searchKeyword]="keyword" placeHolder="Your answer"
			(inputChanged)="onChangeSearch()" (inputCleared)="onClearInput($event)" (selected)='selectEvent($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
			[(ngModel)]="data" [id]="questionId+'_auto-comp'" [name]="questionId">
	</ng-autocomplete>
	<ng-template #itemTemplate let-item>
				<a [innerHTML]="item.name"></a>
	</ng-template>

	<ng-template #notFoundTemplate let-notFound>
				<div *ngIf="isNotFoundVisible(data)" [innerHTML]="notFound"></div>
	</ng-template>