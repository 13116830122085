import { CONFIG } from './../../config/app-config';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  mainPortalLink = CONFIG.MAIN_PORTAL_URL;
  constructor() { }

  ngOnInit(): void {
  }
  redirectToMainPortal() {
    window.open(this.mainPortalLink, "_blank");
  }
}
