<ng-template #resultConfirmationPopup>
    <div class="modal-body">
        <div class="popup_section">	
            <h3>Voila, Your result is <br>almost ready</h3>
            <p>Please go through the Deep Fitment Analysis and schedule your counselling session to discuss the most suitable curriculum for your child.</p>
        </div>
        </div>
        <div class="modal-footer">		   
          <a class="primary_btn cntrl" (click)="onViewResultClick()" appLoader [text]="'View Result'" [isLoading]="submited"></a>
        </div>
</ng-template>
  