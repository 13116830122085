import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private api: ApiService) { }

  setAnswer(token, questionId, userAnswers, pageNumber, answerType, additional?) {
    const fData = new FormData();
    fData.append('token', token);
    fData.append('question_id', questionId);
    fData.append('user_answers', typeof userAnswers == 'object' ? JSON.stringify(userAnswers) : userAnswers);
    fData.append('page_number', pageNumber);
    fData.append('answer_type', answerType);
    fData.append('additional', typeof additional == 'object' ? JSON.stringify(additional) : additional);
    this.api.storeAnswer(fData).subscribe(data => {
    });
  }

  setProgressFlag(flag, value, token) {
    const fData = new FormData();
    fData.append('token', token);
    fData.append(flag, value);
    this.api.setProgressFlag(fData).subscribe(data => {
    });
  }

  saveTieBreakerAnswer(token, questionId, userAnswer) {
    const fData = new FormData();
    fData.append('token', token);
    fData.append('question_id', questionId);
    fData.append('user_answers', userAnswer);
    fData.append('is_tie_bracker_question', '1');
    this.api.storeAnswer(fData).subscribe(data => {
    });
  }
  storeAskMeLater(token, ids) { 
    return this.api.storeAskMeLater(token, ids);
  }
}
