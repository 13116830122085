import { DataService } from './../../_services/data.service';
import { UtilService } from './../../_services/util.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  iconHeader  = ['/', '/personal-information', '/404'];
  assesment = ['/recommendations', '/tie', '/review', '/assessment', '/skipped-questions'];
  bookSessionPageUrl = "/book-session";
  isShow = false;
  @Input() includeLogoOnly = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
              private util: UtilService, public dataService: DataService) { }

  ngOnInit(): void {
    this.isShow = false;
  }

  isHeaderVisible(): boolean {
    const url = this.router.url.split("?")[0];
    return !this.iconHeader.includes(url);
  }
  isAssesmentActive() {
    const url = this.router.url.split("?")[0];
    return this.assesment.includes(url);
  }
  onAssesmentClick($event) {
    if (this.isAssesmentActive()) {
      $event.preventDefault();
      return;
    }
    this.util.navigate('/assessment');
    this.onLinkClick(200);
  }
  onLinkClick(timeout = 2010) {
    setTimeout(() => {
      this.isShow = false;
    }, timeout);
  }
  headerClickHandler() {
    this.dataService.checkUserProgressAndRedirect(true);
  }
  isBookSessionPage() {
    const url = this.router.url.split("?")[0];
    return url == this.bookSessionPageUrl;
  }
}
