import { environment } from './../../environments/environment';
export const CONFIGCONSTANTS = {
    INPUT_TYPE : {
        TEXT: 'text',
        RADIO: 'radio',
        OTHERS: 'Others',
        CHECKBOX: 'checkbox',
        RANK: 'rank'
    },
    ADDITIONAL: {
        IS_CITY: 'auto-complete_city',
        IS_STATE: 'auto-complete_state',
        IS_COUNTRY: 'auto-complete_country',
        IS_CLASS: 'is_class',
        IS_MULTIPLE: 'multiple_answer',
        IS_RENDER_KEY: 'render_key',
        CSS_CLASS: 'css_class',
        IS_BASED_ON_CLASS: 'is_based_on_class',
        ASSESMENT_CSS_CLASS: 'assessment_success_css_class',
        ASSESMENT_ICON: 'assessment_success_image',
        REGISTRATION_SECTION_CLASS: 'registation_class',
        QUESTION_NOTE:  {
            KEY: 'note_based_on_answers',
            NOTE: 'note',
            ANSWER: 'answers'
        },
        VALIDATIONS: {
            ONLY_CHARACHTERS: "allow_only_charachters",
            NOT_ALLOWED_SPECIAL_CHARACHTERS: 'not_allow_special_charachters',
            NOT_ALLOWED_ALL_NUMBERS: 'not_allowed_all_numbers'
        },
        SECTION_CLASS:'section_class'
    },
    CONSTANTS: {
        PLAY_SCHOOL: {
            TEXT: 'Pre-school',
            ORDER: -1
        },
        CLASS_CONDITION_ALL: 'All',
        IMAGE: {
            CSS_CLASS: 'css_class',
            ANIMATION: 'animation_dir',
            IMG: 'image'
        },
        ASSESMENT_POPUP_DEFAULT: {
            ICON: 'icon23.svg',
            CSS_CLASS: 'model_bg_red'
        }
    },
    TOKEN_KEY: 'token',
    INPUT_CHANGE_SOUND_SOURCE: 'assets/audio/button_click.mp3',
    PHONE_MASK_CONFIG: {
        country: 'IN',
        include_country_code: false
    },
    IS_ASSESMENT_COMPLETED: 'is_assesment_completed',
    IS_SUBMIT_ALL_QUESTIONS: 'is_submit_all_questions',
    IS_REVIEW_SUBMITED: 'is_review_submitted',
    IS_PERSONAL_INFO_SUBMITED: 'is_user_personal_information',
    IS_LAST_CATEGORY: 'is_last',
    IS_FEEDBACK_SUBMITED: 'is_feedback_submitted',
    FEEDBACK_RATING: 'user_rating',
    BOOK_SESSION_CONFIG: {
        API_RESPONSE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
        START_AFTER_TODAY: 2,
        END_AFTER_START_DATE: 15,
        DATE_FORMAT: 'YYYY-MM-DD',
        DATE_DISPLAY_FORMAT: 'MM-DD-YYYY',
    },
    IS_SESSION_DONE: 'is_session_done',
    BOARD_DATA: {
        CBSE: {
            CONTENT: "<b class='blue'>CBSE</b>, also known as the Central Board of Secondary Education, was established in 1962, making it one of the oldest education boards in the country. <b class='blue'>CBSE</b> is known for its focus on Mathematics and Sciences and is suitable for students interested in Engineering/Medicine.",
            IMAGE: "assets/images/central_border.png"
        },
        CAM: {
            CONTENT: "The Cambridge Assessment International Education, popularly known as <b class='blue'>“Cambridge International”</b>, also offers a curriculum for class 11 and 12 apart from <b class='blue'>IGCSE</b> and O-Level (which is offered till class 10). This curriculum is called the Cambridge International A-Level Qualification.",
            IMAGE: "assets/images/CAMBRIDGE.png"
        },
        IB: {
            CONTENT: "Founded in 1968, the International Baccalaureate Organization (IBO) is a non-profit educational organization based in Geneva, Switzerland. IBO offers high quality programs of international education to a worldwide community of schools. <b class='blue'>IB</b> is a curriculum where one can take a really wide variety of subjects and be extremely creative.",
            IMAGE: "assets/images/iblogo.png"
        },
        ICSE: {
            CONTENT: "Established in 1958, the <b class='blue'>CISCE</b>, also known as The Council for the Indian School Certificate Examinations is a national level private education board. The <b class='blue'>CISCE</b> conducts examinations for Grade 10, called the Indian Certificate of Secondary Education (ICSE) and also for Grade 12, called the Indian School Certificate (ISC).",
            IMAGE: "assets/images/CISCE.png"
        }
    },
    TIME_CONFIG: {
        SPAN: 60, // in mins
        JOIN_NOW: 30, //in mins
        RESCHEDULE: 1440, //in mins
    },
    SOCIAL_SHARE: {
        ALLOWED_SITES: [{
            title: 'facebook',
            img: 'assets/images/s1.svg'
        },{
            title: 'twitter',
            img: 'assets/images/s3.svg'
        },{
            title: 'whatsapp',
            img: 'assets/images/s5.svg'
        },{
            title: 'pinterest',
            img: 'assets/images/s6.svg'
        }],
        URL: environment.portalURL,
        DESCRIPTION: ` Which curriculum befits your child’s Learning Style? Curriculum Evaluator by Univariety, is designed to help parents like you in finding the answer.
        The curriculum selection program combines inputs from industry experts with analytical data to  help you make decisions on choosing the right school curriculum for your children.
        `,
    },
    BOARDS: ['IB','ICSE','CICSE','CBSE','CAM','CAMBRIDGE','CISE', 'Cambridge'],
    BOARD_ORDER_TEXT: {
        1: "We suggest <b class='blue'>##BOARD##</b> as the most suitable curriculum for your child.",
        2: "The second board suggested for your child is <b class='blue'>##BOARD##</b>"
    },
    CHECKBOX_EXCEPTIONAL_OPTIONAS: ["Not Sure", "None", "I choose not to be involved in his/her choices", "Not sure"],
    RULED_OUT_PERCENTAGE: 10,
    RULED_OUT_GRAPH_COLOR: '#727272',
    MEDIA_TYPES: {
        VIDEO: 'video',
        DOCUMENT: 'pdf'
    },
    ANSWER_LATER: 'answer_later',
    BOARD_STRING_REPLACE: {
    'CISCE (ICSE)': ['CISCE', 'ICSE', 'CISE'],
    'Cambridge (IGCSE)': ['IGCSE', 'Cambridge', 'CAM', 'CAMBRIDGE']
    },
    VIDEOS_BOARDS_FILTER : ['CBSE', 'Cambridge', 'IB', 'CISCE', 'State Board', 'Others'],
    PAGES: {
        ANSWER_LATER_ALLOW:"answer_later_allow"
    },
    LANDING_PAGE_VIDEO_URL: 'https://www.youtube.com/embed/5fyE1Ds2eD4',
    REPORT_GENERATE_VIA_ADMIN: 'is_report_generate_via_admin',
    TIME_ZONE: 'IST',
    USER_EMAIL: 'user_email',
    VIEWR_URL: "https://docs.google.com/gview?&embedded=true&url="
};
