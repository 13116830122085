import { DataService } from './../../_services/data.service';
import { Component, forwardRef, Self, Optional, Input} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModelGroup, NgForm } from '@angular/forms';

@Component({
    selector: 'multiple-questions',
    template: `<ng-content></ng-content>`,
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => MultipleQuestionsComponent),
          multi: true
        }
    ],
    styles: [`
    :host {
        width: 100%;
    }
    `]
})
export class MultipleQuestionsComponent  {
    @Input() form: NgForm;
    @Input() questionId: any;
    @Input() tiggerChangeEvent = true;
    @Input() isSkippedQuestionModule = false;
    constructor(@Optional() @Self() private _group: NgModelGroup, private dataService: DataService) {
    }

    changeEvent() {
        if (this._group.valid) {
            this.dataService.setQuestionsAnswer(this.questionId, this._group.value, this.tiggerChangeEvent, this.isSkippedQuestionModule);
            // if (this.form.valid) {
            //     this.dataService.emitChangePage();
            // }
        }
    }
}
