    <section *ngIf="!isLoading" class="multi_step_form personal_info" data-aos="fade-down" data-aos-delay="0.5" data-aos-duration="500">  
        <form id="msform" #f="ngForm" (submit)="onPersonalInfoSubmitHandler(f)" [autocomplete]="'off'">    
        <fieldset class="personal_info_wrap" *ngIf="registraionDone == false else assesmentBlock">
            <div>
                <h2>Personal Information</h2>
                <div>
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label">Full Name</label>
                        <input appOtherInputValidator appOnlyCharachters [validate]="true" type="text" [onlyTextField]="true" [ngClass]="{'minimum-required': f?.submitted && name?.errors!=null}" #name="ngModel" class="form-control" required="true" [(ngModel)]="data.fullName" name="fullName" placeholder="Enter Name">
                        <ng-container *ngIf="f.submitted && name?.errors">
                                <ng-container *ngIf="name?.errors?.required"><span class="error_msg">Please enter Full Name</span></ng-container>
                                <ng-container *ngIf="name?.errors?.otherMinimumRequired"><span class="error_msg">{{name?.errors?.otherMinimumRequired}}</span></ng-container>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label">Email Address</label>
                        <input type="text" #email="ngModel" [pattern]="'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'" [ngClass]="{'minimum-required': f?.submitted && email?.errors!=null}" class="form-control" required="true" [(ngModel)]="data.email" name="email" placeholder="Email Address">
                        <ng-container *ngIf="f.submitted && email?.errors">
                            <ng-container *ngIf="email?.errors?.required else invalidEmail"><span class="error_msg">Please enter Email Address</span></ng-container>
                            <ng-template #invalidEmail>
                                <ng-container *ngIf="email?.errors?.pattern"><span class="error_msg">Please enter valid Email Address</span></ng-container>
                            </ng-template>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label">Phone Number</label>
                        <input phoneMask #contact_number="ngModel" [config]="config" type="text" [ngClass]="{'minimum-required': f?.submitted && contact_number?.errors!=null}" class="form-control" required="true" [(ngModel)]="data.phone" name="phone" placeholder="00000 00000">
                        <ng-container *ngIf="f.submitted && contact_number?.errors">
                            <ng-container *ngIf="contact_number?.errors?.required ; else invalidCheck"><span class="error_msg">Please enter Phone Number</span></ng-container>
                            <ng-template #invalidCheck>
                                <ng-container *ngIf="contact_number?.errors?.isNumberValid"><span class="error_msg">{{contact_number?.errors?.isNumberValid}}</span></ng-container>
                            </ng-template>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label">Your Child's Name</label>
                        <input type="text" appOnlyCharachters [validate]="true" #child="ngModel" class="form-control" required="true" [ngClass]="{'minimum-required': f?.submitted && child?.errors!=null}" [(ngModel)]="data.childName" name="childName" placeholder="Your Child's Name">
                    <ng-container *ngIf="f.submitted && child?.errors">
                            <ng-container *ngIf="child?.errors?.required"><span class="error_msg">Please enter Your Child's Name</span></ng-container>
                            <ng-container *ngIf="child?.errors?.otherMinimumRequired"><span class="error_msg">{{child?.errors?.otherMinimumRequired}}</span></ng-container>
                    </ng-container>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label">What's your relationship with the child?</label>
                        <div class="row">
                            <div class="col" [ngClass]="{'col-sm-6': i < childRelations.length-1 ,'col-sm-12': i == childRelations.length-1 }" *ngFor="let op of childRelations; let i=index">
                                <div class="radio-group">
                                    <input appPlaySound [id]="op" [(ngModel)]="data.relation" [checked]="data.relation == op" [required]="true" name="relation" type="radio" [value]="op" (change)="onRelationChange(op)">
                                    <label [for]="op">{{op}}</label>
                                </div>
                            </div>
                        </div> 
                        <span *ngIf="f.submitted && data.relation==null" class="error_msg">Please select your relationship with the child</span>
                    </div>
                </div>
            </div>
            
            <button  type="submit" class="next primary_btn" [disabled]="submited" appLoader [text]="'Submit'" [isLoading]="submited"></button>
        </fieldset>
        <ng-template #assesmentBlock>
            <fieldset [@slideInOut] class="assessments_blocks_wrap">
                <p>The Curriculum Assessment’s {{getLength(dataService.data.categories)}} sections help you get a holistic idea of what’s best for your child, in line with your expectations and aspirations</p>
                <ng-container *ngFor="let cat of dataService.data.categories|keyvalue">
                    <div class="assessments_block  {{getSectionClass(cat?.value)}}">
                        <div>
                            <h4>{{cat?.value?.name}}</h4>
                            <span><b>1 - {{getTotalNumberOfQuestions(cat?.value?.id)}}</b> Questions to attempt</span>
                        </div>
                        <figure>
                            <img [src]="getSectionImage(cat?.value)">
                        </figure>
                    </div>
                </ng-container>
                <button type="button" class="btn primary_btn" data-toggle="modal" data-target="#exampleModalCenter" (click)="openModel()"> Start Now! </button>
                 
            </fieldset>
        </ng-template>
        </form>  
    </section>