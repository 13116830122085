<section class="multi_step_form content_left">  
    <form id="msform"> 
        <div class="assessment_sec">
            <br><br>            
            <div class="fieldset_wrap">	
                <fieldset>	
                    <div class="error_404 text-center">
                        <img src="assets/images/404.svg" alt="">
                        <br><span>Look like you’re lost</span>
                        <p>The page you are looking for not available!</p>
                        <button class="primary_btn" (click)="goToHome()"><a>Back to Home</a></button>	
                    </div>                      
                </fieldset>
            </div>
        </div>
    </form>  
</section> 