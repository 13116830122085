import { UtilService } from './../_services/util.service';
import { DataService } from './../_services/data.service';
import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';
@Directive({
  selector: '[appRankRequired]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => RankRequiredDirective),
    multi: true
  }]
})
export class RankRequiredDirective implements Validator {

  constructor(private dataService: DataService, private util: UtilService) { }
  validate(control: AbstractControl): ValidationErrors {
    const keys = _.keys(control.value, []);
    let error = false;
    _.forEach(keys, (e) => {
        if (this.util.isEmpty(control.value[e])) {
          error = true;
        }
    });
    if (error) {
      return { 'required-rank': true};
    }
    return null;
  }
}
