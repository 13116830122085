import { animate } from '@angular/animations';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { UtilService } from './util.service';
import { CONFIGCONSTANTS } from './../config/app-constants';
import { Injectable, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment'
@Injectable({
  providedIn: 'root'
})
export class DataService {
  isSkipNowEnable = true;
  currentTimeIntervalId = null;
  TOKEN: string = "";
  data = {
    'dateTime': null,
    'categories': {
    },
    'questions': [
    ],
    'currentPage': 1,
    'class_question': {
      'question_id': null
    },
    'page_info': {
      "path": "assets/images/",
      "pages": {}
    },
    [CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED]: "0",
    [CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS]: "0",
    [CONFIGCONSTANTS.IS_REVIEW_SUBMITED]: "0",
    [CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED]: "0",
    [CONFIGCONSTANTS.IS_FEEDBACK_SUBMITED]: "0",
    [CONFIGCONSTANTS.REPORT_GENERATE_VIA_ADMIN]: "0",
    [CONFIGCONSTANTS.USER_EMAIL]: "",
    "keys": {
      "key_by_id": {

      },
      "id_by_key": {
        
      }
    },
    "pages": {

    },
    "skipped_aswers" : []
  };
  isAllQuestionRequired = true;
  @Output() dataChanges: EventEmitter<any> = new EventEmitter();
  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Output() checkChangePageEvent: EventEmitter<any> = new EventEmitter();
  @Output() assesmentCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() toggleEditEvent: EventEmitter<any> = new EventEmitter();
  @Output() cityStateCountryChange: EventEmitter<any> = new EventEmitter();
  @Output() skipIfValid: EventEmitter<any> = new EventEmitter();
  @Output() openResultPopup: EventEmitter<any> = new EventEmitter();
  @Output() closeResultPopup: EventEmitter<any> = new EventEmitter();
  @Output() emitSlideChangeEffect: EventEmitter<any> = new EventEmitter();
  @Output() pageVideoOpen: EventEmitter<any> = new EventEmitter();
  @Output() skipAnswerUpdate: EventEmitter<any> = new EventEmitter();
  constructor(private util: UtilService, private commonService: CommonService, private router: Router) { }

  setData(data) {
    this.data = data;
    this.emitChange();
  }

  emitChange() {
    this.dataChanges.emit(this.data);
  }

  getChanges(): EventEmitter<any> {
    return this.dataChanges;
  }

  getCategories(page) {
    const categories = _.get(this.data, 'categories', []);
    for (const k in categories) {
      if (categories.hasOwnProperty(k)) {
        categories[k]['counts'] = this.getCategoryCount(k, page);
      }
    }
    return categories;
  }

  getPage(pageNumber: number) {
    const questions = this.getCategoryData();
    const stepWiseQuestions = _.mapValues(_.groupBy(questions, 'page_number'),
      qlist => _.orderBy(qlist.map(q => _.omit(q, 'page_number')), 'question_order', 'asc'));
    return _.get(stepWiseQuestions, pageNumber, []);
  }

  getActivePage(): number {
    return _.get(this.data, 'currentPage', 1);
  }

  getCategoryCount(categoryId: any, page: number) {
    const questions = this.getCategoryData();
    const categoryQuestion = _.orderBy(_.filter(questions, (o) => o.category_id === categoryId), ['page_number', 'question_order'], 'asc');
    const attemptedQuestions = _.filter(categoryQuestion,
      (o) => {
        return !this.util.isAnswerEmpty(o);
      });
    const stepWiseQuestions = _.mapValues(_.groupBy(categoryQuestion, 'page_number'),
      qlist => _.orderBy(qlist.map(q => _.omit(q, 'page_number')), 'question_order', 'asc'));
    const pageQuestions = _.get(stepWiseQuestions, page, []);
    let minId = null;
    let maxId = null;
    let minIndex = -1;
    let maxIndex = -1;
    if (pageQuestions.length > 0) {
      minId = pageQuestions[0]?.id;
      maxId = pageQuestions[pageQuestions.length - 1]?.id;
      minIndex = categoryQuestion.findIndex(e => e.id === minId);
      maxIndex = categoryQuestion.findIndex(e => e.id === maxId);
    }
    return {
      total: categoryQuestion.length,
      min: minIndex + 1,
      max: maxIndex + 1,
      atttempted: attemptedQuestions.length
    };
  }

  getIsAllQuestionsRequired() {
    return this.isAllQuestionRequired;
  }

  setQuestions(data: any) {
    const questions = _.get(data, 'data.question', []);
    _.set(this.data, 'dateTime', _.get(data, 'data.dateTime', null));
    _.set(this.data, 'skipped_aswers', _.get(data, 'data.skipped_aswers', []));
    _.set(this.data, CONFIGCONSTANTS.USER_EMAIL, _.get(data, 'data.' + CONFIGCONSTANTS.USER_EMAIL, ""));
    this.clearCurrentTimeInterval();
    this.setCurrentTimeInterval();
    this.data[CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED] = _.get(data, 'data.' + CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED, "0");
    this.data[CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED] = _.get(data, 'data.' + CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED, "0");
    this.data[CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS] = _.get(data, 'data.' + CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS, "0");
    this.data[CONFIGCONSTANTS.IS_REVIEW_SUBMITED] = _.get(data, 'data.' + CONFIGCONSTANTS.IS_REVIEW_SUBMITED, "0");
    this.data[CONFIGCONSTANTS.IS_SESSION_DONE] = _.get(data, 'data.' + CONFIGCONSTANTS.IS_SESSION_DONE, "0");
    this.data[CONFIGCONSTANTS.IS_FEEDBACK_SUBMITED] = _.get(data, 'data.' + CONFIGCONSTANTS.IS_FEEDBACK_SUBMITED, "0");
    this.data[CONFIGCONSTANTS.FEEDBACK_RATING] = _.get(data, 'data.' + CONFIGCONSTANTS.FEEDBACK_RATING, "0");
    this.data[CONFIGCONSTANTS.REPORT_GENERATE_VIA_ADMIN] = _.get(data, 'data.' + CONFIGCONSTANTS.REPORT_GENERATE_VIA_ADMIN, "0");
    const userAnswers = _.keyBy(_.get(data, 'data.answers', []), 'question_id');
    this.data.questions = questions;
    _.forEach(this.data.questions, (q) => {
      if (this.util.isKeyPresent(q, 'additional', 'key') && !this.util.isEmpty(_.get(q, 'additional.key', null))) {
        _.set(this.data.keys.id_by_key, _.get(q, 'additional.key', null), q.id);
        _.set(this.data.keys.key_by_id, q.id, _.get(q, 'additional.key', null));
      }
    });
    this.data.questions = _.map(questions, q => {
      if (this.util.isKeyPresent(q, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_MULTIPLE)) {
        const pans = _.keys(_.get(q, 'possible_answers', []));
        q['user_answer'] = {};
        _.forEach(pans, (e) => {
          q['user_answer'][e] = _.get(q, 'answer_type', null) === CONFIGCONSTANTS.INPUT_TYPE.CHECKBOX ? [] : '';
        });
        if (!this.util.isEmpty(userAnswers[q.id]) && !this.util.isEmpty(userAnswers[q.id]['user_answers'])) {
          q['user_answer'] = JSON.parse(userAnswers[q.id]['user_answers']);
        }
      } else if (_.get(q, 'answer_type', null) === CONFIGCONSTANTS.INPUT_TYPE.RANK) {
        const ans = _.get(q, 'possible_answers', []);
        const pans = _.keys(_.get(q, 'possible_answers', []));
        let possible_ans = [];
        _.forEach(pans, (e) => {
          possible_ans.push({
            key: e,
            value: ans[e],
          });
        });
        q['possible_answers'] = possible_ans;
        q['user_answer'] = {};
        if (this.util.isEmpty(_.get(userAnswers[q.id], 'user_answers', null))){
          _.forEach(pans, (e) => {
            q['user_answer'][this.getIdByKey(e)] = _.get(q, 'answer_type', null) === CONFIGCONSTANTS.INPUT_TYPE.CHECKBOX ? [] : '';
          });
        } else {
          q['user_answer'] = JSON.parse(userAnswers[q.id]['user_answers']);
          q['user_answer'] = _.invert(q['user_answer']);
        }
      } else {
        if (_.get(q, 'answer_type', null) === CONFIGCONSTANTS.INPUT_TYPE.CHECKBOX) {
          q['user_answer'] = [];
          if (!this.util.isEmpty(userAnswers[q.id])) {
            q['user_answer'] = JSON.parse(userAnswers[q.id]['user_answers']);
          }
        } else {
          q['user_answer'] = '';
          if (!this.util.isEmpty(userAnswers[q.id])) {
            q['user_answer'] = userAnswers[q.id]['user_answers'];
          }
        }
      }

      return q;
    });
    this.setQuestionAnswerLater();
    this.setClassQuestionsId();
    this.skipQuestionsBasedOnClassCondition();
    this.checkAllDependentQuestions();
    this.getQuestionPage();
    this.data.currentPage = !this.util.isEmpty(_.get(data, 'data.page_number', null)) ? this.getPageIndexById(_.get(data, 'data.page_number', 1)) : 1;
    this.setInitialSkipAnswer();
    this.emitChange();
    setTimeout(() => {
      // this.skipIfValid.emit();
      const currentId = this.getCurrentPageId();
      const currentIndex = this.getPageIndexById(currentId);
      const cPage = this.data.pages[currentIndex];
      if (cPage['total'] == cPage["attempted"]) {
        this.setNextPage();
      }
    }, 600);
  }

  setPage(page: number) {
    this.data.currentPage = page;
    this.emitChange();
  }

  setNextPage(pageId: number = null) {
    const currentCatId = this.getCategoryBasedOnPage(this.data.currentPage);
    const currentPage = this.data.currentPage;
    let assesmentChangeEvent = false;
    if (this.data.currentPage == this.getMaxPage() && this.util.isEmpty(pageId)) {
      if (this.data[CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED] != 1) {
        this.emitAssesmentCompletedEvent(this.data.categories[currentCatId]);
        assesmentChangeEvent = true;
      }
      return;
    }
    if (this.util.isEmpty(pageId)) {
      this.getPossibleNextPage();
    } else {
      this.data.currentPage = this.getPageIndexById(pageId);
    }
    const nextCatId = this.getCategoryBasedOnPage(this.data.currentPage);
    const nextPage = this.data.currentPage;
    let timOut = 0;
    const isCurrentLast = _.get(this.data.categories[currentCatId], CONFIGCONSTANTS.IS_LAST_CATEGORY, false);
    if (!this.util.isEmpty(currentCatId) && !this.util.isEmpty(nextCatId) && currentCatId != nextCatId || (!this.util.isEmpty(currentCatId) && this.util.isEmpty(nextCatId) && isCurrentLast == true) && this.util.isEmpty(pageId)) {
      const currentCat = this.getCategoryCount(currentCatId, currentPage);
      if (this.util.isEmpty(pageId)) {
        this.emitAssesmentCompletedEvent(this.data.categories[currentCatId]);
        assesmentChangeEvent = true;
        timOut = 300;
      }
    }
    setTimeout(() => {
      this.util.scrollPageToTop();
      this.emitChange();
      if (assesmentChangeEvent == false) {
        this.pageVideoOpen.emit();
      }
    }, timOut);
  }

  private getCategoryData() {
    const visibleQuestions = this.getVisibleQuestions();
    const questions = _.map(visibleQuestions, e => {
      e['page_number'] = parseInt(e['page_number']);
      e['question_order'] = parseInt(e['question_order']);
      return e;
    });
    return questions;
  }

  setQuestionsAnswer(questionId: any, answer: any, emitPageChange = true, isSkippedQuestionModule = false) {
    const quest = this.getQuestionById(questionId);
    const queType = _.get(quest, 'answer_type', null);

    _.forEach(this.data.questions, row => {
      if (row.id === questionId) {
        row['user_answer'] = answer;
        row[CONFIGCONSTANTS.ANSWER_LATER] = false;
      }
    });
    let an = answer;
    if (queType == CONFIGCONSTANTS.INPUT_TYPE.RANK) {
      an = _.invert(an);
    }
    this.commonService.setAnswer(this.TOKEN, questionId, an, _.get(quest, 'page_number', null), queType, _.get(quest, 'additional', null));
    if (questionId == _.get(this.data.class_question, 'question_id', null)) {
      this.skipQuestionsBasedOnClassCondition();
      this.checkAllDependentQuestions();
    }
    this.skipDependentQuestion(questionId, true, isSkippedQuestionModule);
    this.getQuestionPage();
    this.emitChange();
    if (emitPageChange === true) {
      this.checkChangePageEvent.emit();
    }
  }

  getChangePage() {
    return this.changePage;
  }

  emitChangePage() {
    return this.changePage.emit();
  }

  setClassQuestionsId() {
    _.forEach(this.data.questions, (e) => {
      if (this.util.isKeyPresent(e, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_CLASS)) {
        this.data.class_question.question_id = e['id'];
      }
    });
  }

  skipQuestionsBasedOnClassCondition() {
    if (!this.util.isEmpty(this.data.class_question.question_id)) {
      const findClassQuestion = this.getClassQuestion();
      if (!this.util.isEmpty(findClassQuestion)) {
        let questionAnswer = _.get(findClassQuestion[0], 'user_answer', null);
        if (questionAnswer == CONFIGCONSTANTS.CONSTANTS.PLAY_SCHOOL.TEXT) {
          questionAnswer = CONFIGCONSTANTS.CONSTANTS.PLAY_SCHOOL.ORDER;
        }
        if (!this.util.isEmpty(questionAnswer)) {
          _.forEach(this.data.questions, (q) => {
            let qClassCondition = _.get(q, 'class_condition', undefined);
            if (!this.util.isEmpty(qClassCondition) && qClassCondition == CONFIGCONSTANTS.CONSTANTS.CLASS_CONDITION_ALL) {
              qClassCondition = null;
            }
            if (!this.util.isEmpty(qClassCondition)) {
              q['skip'] = (!(parseInt(questionAnswer) >= parseInt(qClassCondition)));
            }
          });
        }
      }
    }
  }

  public getClassQuestion() {
    return _.filter(this.data.questions, (o) => o.id == this.data.class_question.question_id);
  }
  public getVisibleQuestions(): any[] {
    let questions = _.get(this.data, 'questions', []);
    questions = _.filter(questions, q => {
      return _.get(q, 'skip', false) == false;
    });
    return questions;
  }

  getCategoryBasedOnPage(page: number) {
    const currentPageCatQues = this.getPage(page);
    const currentPageCat = _.get(currentPageCatQues[0], 'category_id', null);
    return currentPageCat;
  }

  getAssesmentCompletedEvent() {
    return this.assesmentCompletedEvent;
  }

  emitAssesmentCompletedEvent(data) {
    return this.assesmentCompletedEvent.emit(data);
  }

  private getPossibleNextPage() {
    this.data.currentPage++;
    if (this.data.currentPage > this.getMaxPage()) {
      this.data.currentPage = this.getMaxPage()
      return;
    };
    // const questions = this.getPage(this.data.currentPage);
    if (this.util.isEmpty(this.data.pages[this.data.currentPage]) || 
      (!this.util.isEmpty(this.data.pages[this.data.currentPage]) && this.data.pages[this.data.currentPage]['skip'] == true)) {
      this.getPossibleNextPage();
    }
  }

  getToggleEditEvent() {
    return this.toggleEditEvent;
  }
  emitToggleEditEvent(data: boolean) {
    return this.toggleEditEvent.emit(data);
  }

  getMaxPage() {
    const questions = this.getVisibleQuestions();
    const obj = _.maxBy(questions, (o) => o.page_number);
    return _.get(obj, 'page_number', null);
  }

  getPrevPage() {
    if (this.data.currentPage > 1) {
      this.data.currentPage--;
      this.emitChange();
    }
  }

  setCategories(data) {
    const categories = _.get(data, 'data.category', []);
    if (categories.length > 0) {
      _.set(categories[categories.length - 1], CONFIGCONSTANTS.IS_LAST_CATEGORY, true);
    }
    const pagesInfo = _.get(data, 'data.page_info', []);
    this.data.categories = {};
    _.forEach(categories, (c) => {
      this.data.categories[c['id']] = c;
    });

    if (!this.util.isEmpty(pagesInfo)) {
      this.data.page_info.path = _.get(pagesInfo, 'path', 'assets/images/');
      const pages = _.get(pagesInfo, 'pages', []);
      this.data.page_info.pages = {};
      _.forEach(pages, (p) => {
        this.data.page_info.pages[p['id']] = p;
      });
    }
  }
  skipDependentQuestion(questionId, needToCallSKipCallAPI = false, isSkippedQuestionModule = false) {
    const question = this.getQuestionById(questionId);
    const filterQuestions = _.filter(this.data.questions, (q) => _.get(q, "additional.parent_id", null) == questionId);
    const filterQuestionsIds = _.map(filterQuestions, (q) => q.id, []);
    const questionState = {
      add: [],
      remove: []
    };
    _.forEach(this.data.questions, (q) => {
      if (this.util.isKeyPresent(q, 'additional', 'parent_id')) {
        if (_.includes(filterQuestionsIds, q.id)) {
          const possibleAns = _.get(q, 'additional.visible_on', []);
          if (!_.includes(possibleAns, _.get(question, 'user_answer', null))) {
            q['skip'] = true;
            q[CONFIGCONSTANTS.ANSWER_LATER] = false;
            questionState['remove'].push(q);
          } else {
            q['skip'] = false;
            if (isSkippedQuestionModule == true) {
              q[CONFIGCONSTANTS.ANSWER_LATER] = true;
              questionState['add'].push(q);
            }
          }
        }
      }
    });
    
    if (needToCallSKipCallAPI == true && filterQuestions. length > 0) {
      this.sendSkipedAnswerAPICall();
      if (questionState.add.length > 0 || questionState.remove.length > 0) {
        this.skipAnswerUpdate.emit(questionState);
      }
    }
  }
  getQuestionById(questionId) {
    const questions = _.filter(this.data.questions, (q) => _.get(q, "id", null) == questionId, []);
    return questions.length > 0 ? questions[0] : null;
  }
  checkAllDependentQuestions() {
    _.forEach(this.data.questions, (q) => {
      this.skipDependentQuestion(q.id);
    });
  }

  public getDataList(question: any) {
    let answers = _.get(question, 'possible_answers', []);
    const isBasedOnClass = this.util.isBasedOnClass(question);
    if (isBasedOnClass) {
      const classQuestion = this.getClassQuestion();
      if (!this.util.isEmpty(classQuestion)) {
        const classQuestionAnswer = _.get(classQuestion[0], 'user_answer', null);
        answers = _.get(answers, classQuestionAnswer, []);
      }
    }
    const isNeedToIterateKey = this.util.isToIterateOverKeys(question);
    const result =  isNeedToIterateKey ? _.keys(answers) : answers;
    if (Array.isArray(result)) {
      return [...result];
    }
    return result;
  }

  public getDataWithoutOtherField(question, value) {
    const data = this.getDataList(question);
    _.remove(data, (q) => q == CONFIGCONSTANTS.INPUT_TYPE.OTHERS);
    return _.includes(data, value) ? "" : value;
  }
  public setValueToDataObject(key, value) {
    _.set(this.data, key, value);
  }

  public checkUserProgressAndRedirect(refreshPageOnSameRoute = false) {
    const url = this.router.url.split("?")[0];
    let conditionUrl = "/";
    if (this.data[CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS] == 1) {
      this.util.navigate('/recommendations');
      conditionUrl = '/recommendations';
    } else if (this.data[CONFIGCONSTANTS.IS_REVIEW_SUBMITED] == 1) {
      this.util.navigate('/tie');
      conditionUrl = '/tie';
    } else if (this.data[CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED] == 1) {
      this.util.navigate('/review');
      conditionUrl = '/review';
    } else if (this.data[CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED] == 1) {
      if (this.data.currentPage == 0) {
        this.data.currentPage = 1;
      }
      this.util.navigate('/assessment');
      conditionUrl = '/assessment';
    } else if (this.data[CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED] == 0) {
      if (url === "/") {
        this.util.navigate('/');
        if (refreshPageOnSameRoute === true) {
          this.util.checkIfSaveAsUrlThenRefreshPage(url, conditionUrl);
        }
        conditionUrl = '/assessment';
      } else {
        this.util.navigate('/personal-information');
        conditionUrl = '/personal-information';
      }
    }

    if (refreshPageOnSameRoute === true) {
      this.util.checkIfSaveAsUrlThenRefreshPage(url, conditionUrl);
    }
  }

  public isToAllowOpenPersonalInfoPage() {
    if (this.data[CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED] != 1) {
      return true;
    }
    return false;
  }

  public isAllowToOpenAssesmentPage() {
    if (this.data[CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED] != 1 && this.data[CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED] == 1) {
      return true;
    }
    return false;
  }

  public isAllowToOpenTieBreakerPage() {
    if (this.data[CONFIGCONSTANTS.IS_REVIEW_SUBMITED] == 1
        && this.data[CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS] != 1) {
      return true;
    }
    return false;
  }

  public getIdByKey(key) {
    const keys = this.data.keys;
    return keys['id_by_key'][key];
  }

  setAssesmentCompleted() {
    _.set(this.data, CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED, 1);
    this.commonService.setProgressFlag(CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED, 1, this.TOKEN);
  }

  async setReviewQuestionsSubmited() {
    _.set(this.data, CONFIGCONSTANTS.IS_REVIEW_SUBMITED, 1);
    await this.commonService.setProgressFlag(CONFIGCONSTANTS.IS_REVIEW_SUBMITED, 1, this.TOKEN);
  }

  isAllowToOpenReviewPage() {
    if (this.data[CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED] == 1 && this.data[CONFIGCONSTANTS.IS_REVIEW_SUBMITED] != 1) {
      return true;
    }
    return false;
  }
  isAllowToBookSession() {
    const submit = _.get(this.data, CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS, null);
    const sessionDone = _.get(this.data, CONFIGCONSTANTS.IS_SESSION_DONE, null);
    const reportGenerated = _.get(this.data, CONFIGCONSTANTS.REPORT_GENERATE_VIA_ADMIN, null);
    return submit == 1 && (sessionDone != 1 ||(sessionDone == 1 && reportGenerated != 1)) ? true : false;
  }
  async submitAllQuestions() {
    _.set(this.data, CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS, 1);
    await this.commonService.setProgressFlag(CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS, 1, this.TOKEN);
  }
  isAllowToOpenRecommendationsPage() {
    if (this.data[CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS] != 1) {
      return false;
    }
    return true;
  }
  isAllowToOpenViewReportPage() {
    if (this.data[CONFIGCONSTANTS.IS_SESSION_DONE] == 1 && this.data[CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS] == 1 && this.data[CONFIGCONSTANTS.REPORT_GENERATE_VIA_ADMIN] == 1){
      return true;
    }
    return false;
  }

  setCurrentTimeInterval() {
    this.currentTimeIntervalId = setInterval( () => {
      this.data.dateTime = moment(this.data.dateTime, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]).add(1, 'minutes').format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT);
    }, 1000*60);
  }
  clearCurrentTimeInterval() {
    if (this.currentTimeIntervalId!=null) {
      clearInterval(this.currentTimeIntervalId);
    }
  }
  resetStore() {
    this.data = {
      'dateTime': null,
      'categories': {
      },
      'questions': [
      ],
      'currentPage': 1,
      'class_question': {
        'question_id': null
      },
      'page_info': {
        "path": "assets/images/",
        "pages": {}
      },
      [CONFIGCONSTANTS.IS_ASSESMENT_COMPLETED]: "0",
      [CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS]: "0",
      [CONFIGCONSTANTS.IS_REVIEW_SUBMITED]: "0",
      [CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED]: "0",
      [CONFIGCONSTANTS.IS_FEEDBACK_SUBMITED]: "0",
      [CONFIGCONSTANTS.REPORT_GENERATE_VIA_ADMIN]: "0",
      "keys": {
        "key_by_id": {
  
        },
        "id_by_key": {
          
        }
      },
      "pages": {
  
      },
      "skipped_aswers" : []
    };
  }

  getQuestionPage() {
    let questionsArr = _.get(this.data, 'questions', []);
    
    const questions = _.map(questionsArr, e => {
      e['page_number'] = parseInt(e['page_number']);
      e['question_order'] = parseInt(e['question_order']);
      return e;
    });
    const pages = {
    };
    const stepWiseQuestions = _.mapValues(_.groupBy(questions, 'page_number'),
      qlist => _.orderBy(qlist.map(q => _.omit(q, 'page_number')), 'question_order', 'asc'));
    _.each(stepWiseQuestions, (value,key) => {
        pages[_.keys(pages).length + 1] = {
          "id": key,
          "all_questions": value,
          "skip": true,
          "total": 0,
          "attempted": 0
        };
    });
    const visibleQuestionsArr = this.getVisibleQuestions();
    const visibleQuestions = _.map(visibleQuestionsArr, e => {
      e['page_number'] = parseInt(e['page_number']);
      e['question_order'] = parseInt(e['question_order']);
      return e;
    });
    const pageWise = _.groupBy(visibleQuestions, 'page_number');
    
    _.forEach(pageWise, (value, key) => {
      const attemptedQuestions = _.filter(value,
        (o) => {
          return !this.util.isAnswerEmpty(o);
        });
      if (value.length > 0) {
        _.forEach(pages, (qv,qk) => {
          if (qk == key) {
            qv['total'] = value.length,
            qv['attempted'] = attemptedQuestions.length,
            qv['skip'] = false
          }
        })
      }
    });
    this.data.pages = pages;
  }

  getPageIndexById(id: any) {
    return _.findKey(this.data.pages, (item) => item['id'] == id);
  }
  getIdByPageIndex(index: any) {
    return this.util.isEmpty(this.data.pages[index]) ? null : this.data.pages[index];
  }
  getCurrentPageId() {
    return this.data.pages[this.data.currentPage]['id'];
  }
  setFeedBackSubmited() {
    this.data[CONFIGCONSTANTS.IS_FEEDBACK_SUBMITED] = "1";
  }
  setFeedBackRating(rating) {
    this.data[CONFIGCONSTANTS.FEEDBACK_RATING] = rating;
  }

  setQuestionAnswerLater() {
    _.forEach(this.data.questions, (q) => {
      q[CONFIGCONSTANTS.ANSWER_LATER] = false
    })
  }
  setAnswerLaterToPagesQuestion() {
    const questions = this.getPage(this.data.currentPage);
    const notAttemptedAnswer = _.filter(questions,
      (o) => { return this.util.isAnswerEmpty(o); });
    const idArrays = _.map(notAttemptedAnswer,"id");
    _.forEach(this.data.questions, (q) => {
      if (_.includes(idArrays, _.get(q, 'id', null))) {
        q[CONFIGCONSTANTS.ANSWER_LATER] = true;
      }
    });
    if (idArrays.length > 0) {
      const skipQuestionsArr = this.getSkipedQuestions();
      const skipIds = _.map(skipQuestionsArr,"id");
      this.commonService.storeAskMeLater(this.TOKEN, skipIds).subscribe();
    }
    this.setNextPage();
  }
  isShowANswerLaterBookNow() {
    const questions = this.getSkipedQuestions();
    return questions.length > 0 ? true : false;
  }
  getSkipAnswerCount() {
    const questions = this.getSkipedQuestions();
    return questions.length;
  }
  getSkipedQuestions() {
    const questions = this.getVisibleQuestions();
    const skipQuestions = _.filter(questions, (q) => q['skip']!=true && q[CONFIGCONSTANTS.ANSWER_LATER] == true);
    return skipQuestions;
  }
  getCategoryWiseSkippedQuestions() {
    const questions = _.groupBy(_.orderBy(this.getSkipedQuestions(), ['page_number', 'question_order'], 'asc'), 'category_id');
    return questions;
  }
  showAnswerLater(page) {
    if (!this.util.isEmpty(this.data.page_info.pages[page]) && _.get(this.data.page_info.pages[page], CONFIGCONSTANTS.PAGES.ANSWER_LATER_ALLOW, null) == 1) {
      return true;
    }
    return false;
  }
  setInitialSkipAnswer() {
    _.forEach(this.data.questions, (q) => {
      if (_.includes(this.data.skipped_aswers, _.get(q, 'id', null))) {
        q[CONFIGCONSTANTS.ANSWER_LATER] = true;
      }
    });
    if (!this.util.isEmpty(this.data.skipped_aswers) && this.data.skipped_aswers.length > 0) {
      const skipAnsIds = _.map(this.data.skipped_aswers, (id) => parseInt(id));
      const maxId = _.max(skipAnsIds);
      const visibleQuestions = this.getVisibleQuestions();
      const results = _.filter(visibleQuestions, (q) => q['id'] == maxId);
      if (!this.util.isEmpty(results) && results.length > 0) {
        const quest = results[0];
        if (this.data.currentPage < parseInt(this.getPageIndexById(quest['page_number'])+"")) {
          this.data.currentPage = this.getPageIndexById(quest['page_number']);
          if (this.getPageIndexById(quest['page_number']) < this.getMaxPage()) {
            this.getPossibleNextPage();
          }
        }
      }
    }
  }
  checkIfLastPageIsAnswerLater() {
    const qs = this.getSkipedQuestions();
    const questArr  = _.map(qs, (q) => q['id']);
    if (!this.util.isEmpty(questArr) && questArr.length > 0) {
      const skipAnsIds = _.map(questArr, (id) => parseInt(id));
      const maxId = _.max(skipAnsIds);
      const visibleQuestions = this.getVisibleQuestions();
      const results = _.filter(visibleQuestions, (q) => q['id'] == maxId);
      if (!this.util.isEmpty(results) && results.length > 0) {
        const quest = results[0];
        if (this.getMaxPage() == parseInt(this.getPageIndexById(quest['page_number'])+"")) {
          const catId = quest['category_id'];
          this.emitAssesmentCompletedEvent(this.data.categories[catId]);
        }
      }
    }
  }
  checkIfLastPageAndAllAnswerAttempted() {
    if (this.data.currentPage == this.getMaxPage()) {
      let result = true;
      _.forEach(this.data.categories, (value, key) => {
          const cat = this.getCategoryCount(key, this.data.currentPage);
          if (result == true && cat['total'] != cat['atttempted']) {
            result = false;
          }
       });
       if (result == true) {
         const questions = this.getPage(this.data.currentPage);
         if (questions.length > 0) {
          const quest = questions[0];
          this.emitAssesmentCompletedEvent(this.data.categories[quest['category_id']]);
         }
       } else {
         const quests = this.getPage(this.getMaxPage());
         let allAttempted = true;
         _.forEach(quests, (q) => {
           if (this.util.isEmpty(q['user_answer'])) {
            allAttempted = false;
           }
         })
        if ((this.getMaximumSkipANswerPage() == this.getMaxPage() || allAttempted == true) && quests.length > 0) {
          this.emitAssesmentCompletedEvent(this.data.categories[quests[0]['category_id']]);
        }
       }
    }
  }
  sendSkipedAnswerAPICall() {
    const skipQuestionsArr = this.getSkipedQuestions();
    const skipIds = _.map(skipQuestionsArr,"id");
    if (skipIds.length > 0) {
      this.commonService.storeAskMeLater(this.TOKEN, skipIds).subscribe();
    }
  }
  getMaximumSkipANswerPage() {
    let result = null;
    let quest = this.getSkipedQuestions();
    if (quest.length > 0) {
    const questIds = _.map(quest, (q) => {
        return parseInt(q['id']);
     });
     const maxId = _.max(questIds);
     _.forEach(quest, (q) => {
       if (q['id'] == maxId) {
        result = q['page_number']
       }
     })
    }
    return result;
  }
  isSessionBook() {
    return _.get(this.data, CONFIGCONSTANTS.IS_SESSION_DONE, null) == 1 ? true : false;
  }

  public isAllowToOpenVideosPage() {
    if (this.data[CONFIGCONSTANTS.IS_PERSONAL_INFO_SUBMITED] == 1) {
      return true;
    }
    return false;
  }
  isAllAnswerSubmited() {
    const questions = this.getVisibleQuestions();
    let result = true;
    _.forEach(questions, (q) => {
      if (this.util.isEmpty(q['user_answer']) && result == true) {
        result = false;
      }
    });
    return result;
  }
}