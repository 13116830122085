<section class="multi_step_form content_left">  
    <form id="msform"> 
        <div class="assessment_sec">
            <br><br>
            <div class="fieldset_wrap">	
                
                
                <fieldset>	
                    <h1 class="Our_Recommendations_h1">Our Recommendation
                        <img src="assets/images/recomendation.svg" alt="">
                        
                    </h1>
                    <div class="assessment_cnt our confetti_bg">	
                        <div class="Our_Recommendations">
                            <div class="content_section">
                                <p >Our evaluator and expert counsellors stay up to date with information on education systems around the world to suggest the most suitable curriculum for your child. </p>
                                    <br>
                                <p >After analysing your responses against the background of highly reliable research data compiled by experienced industry professionals, we have a personalised recommendation for you</p>
                            </div>	

                            <div class="chart_section tb_space">
                                <span class="main_head" [innerHTML]="util.textChangeService(util.addTagToBoardText(data?.our_recommendation))"></span>
                                <div class="chart" [ngStyle]="{'height': innerWidth <= 540 ? '150px' : '300px'}">
                                    <ng-container *ngIf="innerWidth > 540;else mobileChart">
                                        <canvas baseChart [datasets]="chart.data" [labels]="chart.barChartLabels" [options]="barChartOptions"
                                        [plugins]="chart.barChartPlugins" [legend]="chart.barChartLegend" [chartType]="'bar'"></canvas>
                                    </ng-container>
                                    <ng-template #mobileChart>
                                        <canvas baseChart [datasets]="chart.data" [labels]="chart.barChartLabels" [options]="barChartOptionsMobile"
                                        [plugins]="chart.barChartPlugins" [legend]="chart.barChartLegend" [chartType]="'horizontalBar'"></canvas>
                                    </ng-template>
                                </div>
                                <p class="graph_text" *ngIf="!util.isEmpty(data?.graph_text)"><br>{{data?.graph_text}}</p>
                            </div>		
                            <div *ngIf="!util.isEmpty(data?.location_feasibility)" class="content_section">
                                <h4 class="head_section">Location Feasibility</h4>
                                <p [innerHTML]="util.textChangeService(util.addTagToBoardText(data?.location_feasibility))"></p>
                            </div>


                            <div class="content_section">
                                <h4 class="head_section">Financial Feasibility</h4>
                                <div class="chart_section">
                                    <div class="chart financial_chart" [style.height]=" innerWidth > 540 ? '300px' : '150px'">
                                        <img [src]="innerWidth > 540 ? 'assets/images/chart_desktop.png' : 'assets/images/chart_mobile.png'"/>
                                    </div>
                                <p class="financial_chart_text" [innerHTML]="util.textChangeService(util.addTagToBoardText(data?.financial_feasibility))"></p>
                                
                            </div>	
                            </div>	
                            <div class="content_section">
                                <h4 class="head_section">Expectations & Preferences</h4>
                                <p [innerHTML]="util.addTagToBoardText(data?.expectations_preferences)"></p>
                            </div>
                            <ng-container *ngFor="let b of getBoardData(); let i = index;">
                                <div class="chart_section we_suggest">
                                    <span class="suggest" [innerHTML]="util.textChangeService(getBoardOrderText(i+1,b.key))"></span>
                                    <span *ngIf="!util.isEmpty(b) && !util.isEmpty(b.reason) && b.reason.length > 0" class="sub_haeding">THE REASONS ARE:</span>
                                    <ul>
                                        <li *ngFor="let r of b.reason" [innerHTML]="r"></li>
                                    </ul>
                                </div>
    
    
                                <div class="content_section">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <p class="cbse_logo" [innerHTML]="boardData[b.key]['CONTENT']"></p>
                                        </div>
                                        <div class="col-md-3  d-flex align-items-center  justify-content-center">
                                            <img [src]="boardData[b.key]['IMAGE']" alt="boardData[b.key]['IMAGE']">
                                        </div>
                                    </div>
                                </div>	
    
                            </ng-container>	
                            
                            <div class="content_section">
                                <h4 class="head_section">Disclaimer</h4>
                                <div class="blue_bg">
                                <p>Our team of experts has carried out a detailed analysis of your responses and accordingly made recommendations to you. However, the final decision is in your hands.</p><br>
                                <p>We advise you to make your decision based on all the inputs and knowledge you have gained through this program.</p>
                                </div>      
                            </div>	
                            <ng-container *ngIf="dataService.isSessionBook() == false">
                                <ng-container *ngIf="isShowJoinNowNotification() == true; else bookNowNotification">                                
                                    <div class="fxd_btm" *ngIf="isJoinNowVisible()">
                                        <div class="row align-items-center">
                                            <div class="col-md-9">
                                                <p class="fxd_btm_p">Counselling session scheduled for {{getFormatedDate(sessionData.date)}}, {{getTime(sessionData.timeSlot)}} {{timezone}}</p>
                                            </div>
                                            <div class="col-md-3">
                                                <button *ngIf="isJoinNowEnable() == false; else joinWithEnable" [popover]="myPopover" [popoverDismissTimeout]="2000" [popoverOnHover]="true" popoverPlacement="top-right" class="primary_btn pull-right disabled">Join</button>
                                                <ng-template #joinWithEnable>
                                                    <button class="primary_btn pull-right" (click)="onJoinNowClick()">Join</button>
                                                </ng-template>
                                                <popover-content class="join-btn-tooltip" #myPopover>
                                                    <div class="tooltip-content">Button is enabled 30 mins before your session.</div>
                                                </popover-content>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #bookNowNotification>
                                    <div class="fxd_btm">
                                        <div class="row align-items-center">
                                            <div class="col-md-9">
                                                <p class="fxd_btm_p">Schedule a session with a counsellor to discuss your result</p>
                                            </div>
                                            <div class="col-md-3">
                                                <button class="primary_btn pull-right" (click)="navigateToBookSession()">Book Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </div>
                    </div>
                </fieldset>
            </div>
            </div>
    </form>  
</section>