import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { CONFIGCONSTANTS } from './../config/app-constants';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private router: Router, private sanitizer: DomSanitizer) { }

  public isKeyPresent(object, key: string, checkKey): boolean {
    return _.has(object, key + '.' + checkKey);
  }


  public isEmpty(value) {
    return _.isEmpty(value) ? _.isNumber(value) || _.isBoolean(value) ? false : true : false;
  }


  public isAnswerEmpty(question) {
    const answer = _.get(question, 'user_answer', null);
    const answerType = _.get(question, 'answer_type', null);
    if (answerType == CONFIGCONSTANTS.INPUT_TYPE.RANK ||
      this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_MULTIPLE)) {
      let empty = false;
      const keys = _.keys(answer, []);
      _.forEach(keys, (k) => {
        if (this.isEmpty(answer[k])) {
          empty = true;
        }
      });
      return empty;
    } else {
      return this.isEmpty(answer);
    }
  }

  public isValuePresent(arr, value) {
    return _.includes(arr, value);
  }

  public isToIterateOverKeys(question: any) {
    return this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_RENDER_KEY);
  }

  public isBasedOnClass(question: any) {
    return this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_BASED_ON_CLASS);
  }

  public isQuestionTypeIsAutoComplete(question: any) {
    let isAutoComplete = false;
    const questionType  = _.get(question, 'answer_type', null);
    if ((
      this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_CITY) ||
      this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_STATE) ||
      this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_COUNTRY)
    )) {
      isAutoComplete = true;
    }
    return isAutoComplete;
  }
  public getAutoCOmpleteType(question: any) {
    if (this.isQuestionTypeIsAutoComplete(question)) {
      if (this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_CITY)) {
        return CONFIGCONSTANTS.ADDITIONAL.IS_CITY;
      } else if (this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_STATE)) {
        return CONFIGCONSTANTS.ADDITIONAL.IS_STATE;
      } else if (this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_COUNTRY)) {
        return CONFIGCONSTANTS.ADDITIONAL.IS_COUNTRY;
       }
    }
    return null;
  }
  public navigate(url: string) {
    this.router.navigate([url], { queryParamsHandling: "preserve" });
  }

  public isQuestionHasNote(question: any) {
    const answer = _.get(question, 'user_answer', null);
    let result = false;
    if (this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.QUESTION_NOTE.KEY) && !this.isEmpty(answer)) {
      const keyObj = _.get(question, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.QUESTION_NOTE.KEY , {});
      const answers = _.get(keyObj, CONFIGCONSTANTS.ADDITIONAL.QUESTION_NOTE.ANSWER , []);
      result = _.includes(answers, answer);
    }
    return result;
  }

  public getQuestionNoteTxt(question: any) { 
    let text = '';
    if (this.isQuestionHasNote(question) == true) {
      const keyObj = _.get(question, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.QUESTION_NOTE.KEY , {});
      text = _.get(keyObj, CONFIGCONSTANTS.ADDITIONAL.QUESTION_NOTE.NOTE, '');
    }
    return text;
  }

  public getDateConfig(dateTime) {
    const date = moment(dateTime, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]);
    const minDate = date.clone().add(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.START_AFTER_TODAY, 'days');
    const maxDate = minDate.clone().add(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.END_AFTER_START_DATE - 1, 'days');
    return {
      date: date.format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_DISPLAY_FORMAT),
      minDate: minDate.format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_DISPLAY_FORMAT),
      maxDate: maxDate.format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_DISPLAY_FORMAT),
      format: CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_DISPLAY_FORMAT
    };
  }
  addTagToBoardText(val:string){
    const boards  = CONFIGCONSTANTS.BOARDS;
    if (!this.isEmpty(val)){
      _.forEach(boards, (b) => {
        val = _.replace(val, new RegExp(b, "g"), "<b class='blue'>" + b + "</b>");
      })
    }
    return val;
  } 
  scrollPageToTop() {
    window.scroll({
      top: 0, 
      left: 0, 
      // behavior: 'smooth'
    });
  }
  isOnlyAllowCharachters(question) {
    return this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.VALIDATIONS.ONLY_CHARACHTERS);
  }
  isNotAllowedSpecialCharachters(question) {
    return this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.VALIDATIONS.NOT_ALLOWED_SPECIAL_CHARACHTERS);
  }
  isNotAllowedAllNumbers(question) {
    return this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.VALIDATIONS.NOT_ALLOWED_ALL_NUMBERS);
  }
  checkIfSaveAsUrlThenRefreshPage(url, navigateUrl) {
      if (url == navigateUrl) {
        window.location.reload();
      }
  }
  getSecureUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  textChangeService(text) {
    let result = text;
    const keys= CONFIGCONSTANTS.BOARD_STRING_REPLACE;
    if (!this.isEmpty(result)) {
      _.forEach(keys, (value, key) => {
          let stop  = false;
        _.forEach(value, (d) =>{
          if (!this.isEmpty(result) && !this.isEmpty(d) && result.includes(d) === true && stop == false) {
              stop = true;
              result = _.replace(result, new RegExp(d, "g"), key);
            }
        })
      })
    }
    return !this.isEmpty(result) ? result : '';
  }
  getSectionClass(question) {
    return _.get(question, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.SECTION_CLASS, '');
  }
  checkIsCountry(question) {
    return this.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_COUNTRY);
  }
}
