<section *ngIf="!isLoding" class="multi_step_form content_left">  
    <form id="msform" #f="ngForm" name="tie-breaker-form"> 
        <div class="assessment_sec">
            <div class="pagination_wrap page_wrap">
                <div class="row">
                    <div class="col-sm-9">
                        <div class="left_side">
                            <span class="left_content">Your results are almost ready, 2 boards match closely with your preferences. To identify most suitable one , answer below questions</span>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="right_side">
                            <button type="button" class="primary_btn cntrl" (click)="onNextButtonClick(f)"><a>next</a></button>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="fieldset_wrap">	                
                <fieldset>	
                    <div class="assessment_cnt residence_multi">
                        <div class="form_cnt">
                            <label for="exampleInputEmail1" class="form-label head_top ml-md-5">Chooce higher priority choice among the 2 options</label>
                        </div>
                        <div class="form_cnt scroll_ques">
                            <ng-container *ngFor="let q of data; let i = index;">
                                <div class="form-group">
                                    <div class="row left_side_bdr">
                                        <div class="col-sm-12" *ngFor="let ans of q.options |keyvalue; let in = index;">
                                            <div class="radio-group">
                                                <input appPlaySound [id]="q.id+'_'+ans.key" [required]="true" [name]="q.id" type="radio" [(ngModel)]="q['user_answer']" [value]="ans.key" [checked]="q['user_answer'] == ans.key" (change)="onChangeOptionChange($event, q.id)">
                                                <label class="mb-2" [for]="q.id+'_'+ans.key"><span>{{getOptionCharachter(in)}}</span> {{ans.value}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>	                                                         
                            </ng-container>
                        </div>
                    </div> 
                </fieldset>
            </div>
            </div>
    </form>  
</section> 
<app-submit-result-popup></app-submit-result-popup>