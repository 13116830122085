import { ApiService } from './../_services/api.service';
import { DataService } from './../_services/data.service';
import { UtilService } from './../_services/util.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router, private util: UtilService, private dataService: DataService, private api: ApiService) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.util.isEmpty(_.get(next.queryParams, 'token', ''))) {
            this.api.isUnAuthorized = false;
        }
        if ((!this.util.isEmpty(this.dataService.TOKEN) && this.dataService.TOKEN !== _.get(next.queryParams, 'token', '')) ||
            this.util.isEmpty(_.get(next.queryParams, 'token', '')) || this.api.isUnAuthorized === true) {
            this.api.redirectToUnAuthorizedPage();
            return false;
        }
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }
}

