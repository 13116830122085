import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, Input } from '@angular/core';
import ConfettiParticle from 'src/app/_animations/ConfettiParticle';

@Component({
  selector: 'app-animation-effect',
  templateUrl: './animation-effect.component.html',
  styleUrls: ['./animation-effect.component.css']
})
export class AnimationEffectComponent implements OnInit,AfterViewInit {
  width = window.innerWidth;
  height = window.innerHeight;
  particles = [];
  @Input() zIndex = '0 !important';

  @ViewChild('myCanvas')
  myCanvas: ElementRef<HTMLCanvasElement>;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.init();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.createParticles();
  }
  changeBgColor = () => {
    const hue = Math.floor( Math.random() * 360 );
    document.body.style.backgroundColor = '#034A9B';
  }

  createParticles = () => {
    const context = this.myCanvas.nativeElement.getContext('2d');
    this.particles = [];
    let total = 100;
    if ( this.width > 1080 ) { total = 400; } else
    if ( this.width > 760 )  { total = 300; } else
    if ( this.width > 520 )  { total = 200; }
    for ( let i = 0; i < total; ++i ) {
      this.particles.push( new ConfettiParticle( context, this.width, this.height ) );
    }
  }

  animationFunc = () => {
    if (!this?.myCanvas?.nativeElement) {
      return;
    }
    const context = this.myCanvas.nativeElement.getContext('2d');
    requestAnimationFrame( this.animationFunc );
    if ( Math.random() > 0.98 ) {
      this.changeBgColor();
    }
    context.clearRect( 0, 0, this.width, this.height );
    for ( const p of this.particles ) {
      p.width = this.width;
      p.height = this.height;
      p.update();
      p.draw();
    }
  }

  init() {
    this.createParticles();
    this.changeBgColor();
    this.animationFunc();
  }
}
