<section class="multi_step_form video_section videos_page">
  <form id="msform">
    <div class="assessment_sec ">
      <div class="fieldset_wrap">
        <fieldset>
          <div class="assessment_cnt">
            <button type="button" class="next skip" [routerLink]="['/assessment']" queryParamsHandling="preserve"><a>Continue Assessment</a></button>
            <div class="form_cnt">
              <div class="row">
                <div class="col-sm-6">
                  <img src="assets/images/icon54.svg" alt="">
                </div>
                <div class="col-sm-6">
                  <p class="txt_right">Understanding about different aspects of Curriculum!</p>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-5 col-sm-12">
                  <div class="form-group board-dropdown optionDropdown">
                    <ng-multiselect-dropdown 
                      [ngClass]="{'rawText': boardSel.selectedItems.length == 0}"
                      [placeholder]="'Choose Board'" 
                      [data]="boards" 
                      [settings]="boardsMultiSelectSettings"
                      class="form-control board-select"
                      (onSelect)="checkSearchEnableDisable()"
                      (onSelectAll)="checkSearchEnableDisable()"
                      (onDeSelect)="checkSearchEnableDisable()"
                      #boardSel
                      [(ngModel)]="search.data.boards"
                      name="boards"
                      >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="input-group">
                    <input (keyup.enter)="checkSearchEnableDisable()" class="form-control py-2 border-right-0 border collapse_s" type="search" placeholder="search" name="search"
                    [(ngModel)]="search.data.search"
                      id="example-search-input">
                    <span class="input-group-append">
                      <div class="input-group-text bg-transparent collapse_s" (click)="checkSearchEnableDisable()"><i class="fa fa-search"></i></div>
                    </span>
                  </div>
                </div>
                <div (click)="onClearInput()" [@fade] class="col-sm-1" *ngIf="search?.isSearcScreenVisible == true">
                  <a class="clear">Clear</a>
                </div>
              </div>
              <ng-container *ngIf="search.isLoading == true; else videoContent">
                <div class="btm_video_section loader">
                  <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
              </ng-container>
              <br>
              <ng-template #videoContent>
                <div  [@fade] id="accordion" role="tablist" *ngIf="search?.isSearcScreenVisible == false; else videoSearchResult">
                  <accordion [isAnimated]="true" [closeOthers]="true">
                    <accordion-group #accordianGroup (click)="accordianChange($event)"  class="card" #groupval *ngFor="let a of data">
                      <div accordion-heading>
                        <a [ngClass]="{'show': groupval?.isOpen == true}">
                          <h5 class="mb-0">
                            {{a?.title}}
                          </h5>
                        </a>
                      </div>
                      <div class="clps">
                        <p>{{a?.description}}</p>
                        <div class="row">
                          <ng-container *ngFor="let v of a?.videos">
                            <div class="col-sm-4 video-div" (click)="openVideoInModel($event,v?.raw_url, false, v?.type)">
                              <div *ngIf="v.type == mediaTypes.VIDEO; else doc" class="videoEmbed video_list">
                                <span>{{v?.duration}}</span>
                              <iframe controls="0" playsinline="0" [src]="v?.video_url" width="100%" frameborder="0"></iframe>
                              </div>
                              <a *ngIf="v.type == mediaTypes.VIDEO">{{v?.video_title}}</a>
                              <ng-template #doc>
                                <div class="pdf_resource">
                                  <a> <i><img src="assets/images/pdf_icon.svg" /></i>  {{v?.video_title}}</a>
                                </div>
                              </ng-template>
                          </div>
                          </ng-container>
                        </div>
                    </div>
                    </accordion-group>
                  </accordion>
                </div>
                <ng-template #videoSearchResult>
                  <div  [@fade] class="btm_video_section">
                    <p>{{getVideosArray().length}} Results Found</p>
                    <div class="row">
                      <ng-container *ngFor="let v of getVideosArray()">
                        <div class="col-sm-4 video-div" (click)="videoService.openModel(v?.raw_url, false, v?.type)">
                          <div *ngIf="v.type == mediaTypes.VIDEO; else doc" class="videoEmbed video_list">
                            <span>{{v?.duration}}</span>
                            <iframe controls="0" playsinline="0" [src]="v?.video_url" width="100%" frameborder="0"></iframe>
                          </div>
                          <a *ngIf="v.type == mediaTypes.VIDEO">{{v?.video_title}}</a>
                          <ng-template #doc>
                            <div class="pdf_resource">
                              <a> <i><img src="assets/images/pdf_icon.svg" /></i>  {{v?.video_title}}</a>
                           </div>
                          </ng-template>
                      </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </form>
</section>
