import { Router } from '@angular/router';
import { CONFIG } from './../config/app-config';
import { map } from 'rxjs/operators';
import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  isUnAuthorized = false;
  constructor(private http: HttpClient, private router: Router) { }

  getQuestions(token: any) {
    const formData = new FormData();
    formData.append('token', token);
    return this.http.post<any>(CONFIG.GET_QUESTIONS, formData)
      .pipe(map(response => {
      return response;
    }));
  }

  getCategories() {
    return this.http.get<any>(CONFIG.GET_CATEGORIES)
      .pipe(map(response => {
      return response;
    }));
  }

  getStates(value: string = '') {
    const formData = new FormData();
    formData.append('param', value);
    return this.http.post<any>(CONFIG.GET_STATES, formData)
      .pipe(map(response => {
      return response;
    }));
  }

  getCities(value: string = '') {
    const formData = new FormData();
    formData.append('param', value);
    return this.http.post<any>(CONFIG.GET_CITIES, formData)
      .pipe(map(response => {
      return response;
    }));
  }

  getCountries(value: string = '') {
    const formData = new FormData();
    formData.append('param', value);
    return this.http.post<any>(CONFIG.GET_COUNTRIES, formData)
      .pipe(map(response => {
      return response;
    }));
  }
  checkIfUnAuthorized(data) {
    if (_.get(data, 'status', null) == 422) {
      this.redirectToUnAuthorizedPage();
    }
  }
  redirectToUnAuthorizedPage() {
    this.isUnAuthorized = true;
    this.router.navigate(['/404']);
  }
  storeToken(token) {
    const formData = new FormData();
    formData.append('token', token);
    return this.http.post<any>(CONFIG.STORE_TOKEN, formData)
      .pipe(map(response => {
      return response;
    }));
  }

  savePersonalInfo(data) {
    return this.http.post<any>(CONFIG.STORE_PERSONAL_INFO, data)
      .pipe(map(response => {
      return response;
    }));
  }
  storeAnswer(data) {
    return this.http.post<any>(CONFIG.STORE_ANSWER, data)
      .pipe(map(response => {
      return response;
    }));
  }

  setProgressFlag(data) {
    return this.http.post<any>(CONFIG.STORE_USER_PROGRESS, data)
      .pipe(map(response => {
      return response;
    }));
  }

  getTieBreakerQuestions(token) {
    const formData = new FormData();
    formData.append('token', token);
    return this.http.post<any>(CONFIG.FETCH_TIE_BREAKER_QUESTIONS, formData)
      .pipe(map(response => {
      return response;
    }));
  }

  getTimeSlots(token, date) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('date', date);
    return this.http.post<any>(CONFIG.GET_AVAILABLE_TIME_SLOTS, formData)
      .pipe(map(response => {
      return response;
    }));
  }

  bookTimeSlots(token, date, timeSlot) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('date', date);
    formData.append('time_sloat', timeSlot);
    return this.http.post<any>(CONFIG.BOO_TIME_SLOT, formData)
      .pipe(map(response => {
      return response;
    }));
  }

  getSessionDetails(token) {
    const formData = new FormData();
    formData.append('token', token);
    return this.http.post<any>(CONFIG.SESSION_DETAILS, formData)
      .pipe(map(response => {
      return response;
    }));
  }
  calculateResult(token, idArrays) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('final_questions', idArrays);
    return this.http.post<any>(CONFIG.CALCULATE_RESULT, formData)
      .pipe(map(response => {
      return response;
    }));
  }
  storeUserFinalResult(token) {
    const formData = new FormData();
    formData.append('token', token);
    return this.http.post<any>(CONFIG.STORE_USER_FINAL_RESULT, formData)
      .pipe(map(response => {
      return response;
    }));
  }
  getResult(token) {
    const formData = new FormData();
    formData.append('token', token);
    return this.http.post<any>(CONFIG.GET_RESULT, formData)
      .pipe(map(response => {
      return response;
    }));
  }
  saveFeedBack(token, rating, feedback) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('rating', rating);
    formData.append('feedback', feedback);
    return this.http.post<any>(CONFIG.SAVE_FEEDBACK, formData)
      .pipe(map(response => {
      return response;
    }));
  }
  downloadReport(token) {
    const formData = new FormData();
    formData.append('token', token);
    return this.http.post<any>(CONFIG.DOWNLOAD_REPORT, formData)
      .pipe(map(response => {
      return response;
    }));    
  }
  emailReport(token) {
    const formData = new FormData();
    formData.append('token', token);
    return this.http.post<any>(CONFIG.EMAIL_REPORT, formData)
      .pipe(map(response => {
      return response;
    }));    
  }
  getEmailPhone(token) {
    const params = new HttpParams().set('token', token);
    return this.http.get<any>(CONFIG.GET_PERSONAL_INFO, {params})
      .pipe(map(response => {
      return response;
    }));    
  }

  getVideos(token, tags, search) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('boards_tag', tags);
    formData.append('search', search);
    return this.http.post<any>(CONFIG.FETCH_VIDEOS, formData)
      .pipe(map(response => {
      return response;
    }));    
  }
  
  storeAskMeLater(token, ids) {
    if (ids.length > 0) {
        const formData = new FormData();
        formData.append('token', token);
        formData.append('question_id', ids);
        return this.http.post<any>(CONFIG.STORE_ASK_ME_LATER, formData)
          .pipe(map(response => {
          return response;
        }));
    }
  }
}
