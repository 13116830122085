import { QuestionsComponent } from './question.component';
import {Component, Input, Host } from '@angular/core';

@Component({
    selector: 'app-question-input',
    template: `
            <div class="radio-group">
                <input appPlaySound [type]="inputType" [required]="true" [checked]="isChecked()" [id]="id" (change)="toggleCheck()"/>
                <label [for]="id">{{value}}</label>
            </div>
        <ng-content></ng-content>
    `
})
export class QuestionInputComponent {
    @Input() value: any;
    @Input() id: any;
    @Input() required: any;
    @Input() inputType = 'radio';
    constructor(@Host() private question: QuestionsComponent) {
    }

    toggleCheck() {
        this.question.addOrRemove(this.value);
    }

    isChecked() {
        return this.question.contains(this.value);
    }
}
