import { MultipleQuestionsComponent } from './multiple-questions.component';
import { Component, forwardRef, Input, Host} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-question',
    template: `
            <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{index}}. {{lable}}</label>
                <div class="row">
                    <ng-container *ngFor="let item of data; let i = index;">
                    <div class="col-sm-4">
                        <app-question-input [inputType]="inputType" [required]="true" [value]="item" [id]="i+'_'+item+'_'+lable"></app-question-input>
                    </div>
                    </ng-container>
                </div>
            </div>
    `,
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => QuestionsComponent),
          multi: true
        }
    ],
    styles: [`
    :host {
        width: 100%;
    }
    `]
})
export class QuestionsComponent implements ControlValueAccessor {
    @Input() index = 0;
    @Input() lable = '';
    @Input() name = '';
    @Input() data = [];
    @Input() required = true;
    @Input() inputType = 'radio';
    private _model: any;
    private onChange: (m: any) => void;
    private onTouched: (m: any) => void;
    @Input() questionId: any;
    constructor(@Host() private question: MultipleQuestionsComponent) {

    }
    get model() {
        return this._model;
    }

    writeValue(value: any): void {
        this._model = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    set(value: any) {
        this._model = value;
        this.onChange(this._model);
    }

    addOrRemove(value: any) {
        if (this.inputType == 'radio') {
            this._model = value;
            this.onChange(this._model);
        } else if (this.inputType == 'checkbox') {
            if (this.contains(value)) {
                this.remove(value);
            } else {
                this.add(value);
            }
        }
        this.question.changeEvent();
    }

    contains(value: any): boolean {
        if (this._model instanceof Array) {
            return this._model.indexOf(value) > -1;
        } else if (!!this._model) {
            return this._model === value;
        }
        return false;
    }

    public add(value: any) {
        if (!this.contains(value)) {
            if (this._model instanceof Array) {
                this._model.push(value);
            } else {
                this._model = [value];
            }
            this.onChange(this._model);
        }
    }

    public remove(value: any) {
        const index = this._model.indexOf(value);
        if (!this._model || index < 0) {
            return;
        }

        this._model.splice(index, 1);
        this.onChange(this._model);
    }
}
