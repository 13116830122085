import { CONFIGCONSTANTS } from './../config/app-constants';
import { EmbedVideoService } from 'ngx-embed-video';
import { UtilService } from './util.service';
import { DataService } from './data.service';
import { AppComponent } from './../app.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Injectable, Output, EventEmitter, ViewChild, ComponentFactoryResolver, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class VideoPopupService {
  videoPopup = {
    URL_TXT: null,
    IS_FOOTER_VISIBLE: false,
    MODEL_REF: null,
    POPUP_HTML: '',
    TYPE: CONFIGCONSTANTS.MEDIA_TYPES.VIDEO
  };

  @Output() openModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeMoelEvent: EventEmitter<any> = new EventEmitter();

  constructor(private sanitizer: DomSanitizer, private dataService: DataService, private util: UtilService,
              private embedService: EmbedVideoService) { }

  openModel(URL_TXT, IS_FOOTER_VISIBLE = false, TYPE = CONFIGCONSTANTS.MEDIA_TYPES.VIDEO) {
    if (this.videoPopup.MODEL_REF != null) return;
    if (this.util.isEmpty(URL_TXT)) { return; }
    if (TYPE == CONFIGCONSTANTS.MEDIA_TYPES.DOCUMENT) {
      // this.videoPopup.URL_TXT = this.sanitizer.bypassSecurityTrustResourceUrl(CONFIGCONSTANTS.VIEWR_URL + URL_TXT);
      this.videoPopup.URL_TXT = URL_TXT;
    } else {
      this.videoPopup.URL_TXT = this.sanitizer.bypassSecurityTrustResourceUrl(URL_TXT);
    }
    this.videoPopup.IS_FOOTER_VISIBLE = IS_FOOTER_VISIBLE;
    this.videoPopup.TYPE = TYPE;
    return this.openModelEvent.emit();
  }

  closeModel() {
    return this.closeMoelEvent.emit();
  }

  resetPopupData() {
    this.videoPopup = {
      URL_TXT: null,
      IS_FOOTER_VISIBLE: false,
      MODEL_REF: null,
      POPUP_HTML: '',
      TYPE: CONFIGCONSTANTS.MEDIA_TYPES.VIDEO
    };
  }

  getVideoURLOfPage(page) {
    const questions = this.dataService.getPage(page);
    let link = null;
    if (questions.length > 0) {
      _.forEach(questions, (q) => {
        const url = _.get(q, 'video_url', null)
        if (this.util.isEmpty(link) && !this.util.isEmpty(url)) {
          link = url;
        }
      })
    }
    setTimeout(() => {
      if (!this.util.isEmpty(link)) {
        this.openModel(link);
      }
    }, 0);
  }
}
