import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CONFIGCONSTANTS } from './../../config/app-constants';
import { ApiService } from './../../_services/api.service';
import { DataService } from './../../_services/data.service';
import { UtilService } from './../../_services/util.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AnimationService } from './../../_services/animation.service';
import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import * as  moment from 'moment';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-book-session',
  templateUrl: './book-session.component.html',
  styleUrls: ['./book-session.component.css']
})
export class BookSessionComponent implements OnInit, OnDestroy {
  isLoading = false;
  modalRef: BsModalRef;
  data = {
    date: null,
    timeSlot: '',
    zoomLink: '',
    time: ''
  };
  timeslots: any[] = [];
  datePickerConfig;
  isSubmitting = false;
  @ViewChild('schedulePopup') schedulePopup: TemplateRef<any>;
  constructor(private animation: AnimationService, private modalService: BsModalService, private router: Router,
              private util: UtilService, private dataService: DataService,
              private api: ApiService, private loader: NgxUiLoaderService, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.getSessionDetails();
    if (!this.dataService.isAllowToBookSession()) {
      if (this.dataService.data[CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS] == 1) {
        this.util.navigate('/view-report');
      } else {
        this.dataService.checkUserProgressAndRedirect();
      }
    } 
    this.datePickerConfig = this.util.getDateConfig(this.dataService.data.dateTime);
    this.animation.init();
    this.isJoinNowVisible();
  }

  openModel() {
  this.modalRef = this.modalService.show(this.schedulePopup, { class: 'modal-md modal-dialog-centered video_modal bg_grad model_session book-session-schedule', backdrop: 'static', keyboard: false });
  }

  onBookSessionConfirm() {
    this.modalRef.hide();
  }
  onReScheduleClick() {
    this.modalRef.hide();
  }
  onViewResultClick() {
    this.modalRef.hide();
    setTimeout(() => {
      this.util.navigate('/recommendations');
    }, 100);
  }

  onUserInput() {
    return false;
  }

  getDate(date) {
    return !this.util.isEmpty(date) ? new Date(_.replace(date, /-/gi, "/")) : '';
  }

  onDateChange($event) {
    if ($event == null) {
      return;
    }
    const apiDate = moment($event).format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_FORMAT);
    // this.data.date = moment(this.data.date).format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_DISPLAY_FORMAT);
    this.timeslots = [];
    // this.data.timeSlot = '';
    this.api.getTimeSlots(this.dataService.TOKEN, apiDate)
    .subscribe(data => {
      this.timeslots = _.get(data, 'data', []);
    }, error => {
    });
  }
  getTime(time) {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  }

  submit(f) {
    this.isSubmitting = true;
    const apiDate = moment(this.data.date).format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_FORMAT);
    // this.data.date = moment(this.data.date).format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_DISPLAY_FORMAT);
    this.api.bookTimeSlots(this.dataService.TOKEN,
      apiDate, this.data.timeSlot)
       .subscribe(data => {
         this.openModel();
         this.isSubmitting = false;
       }, error => {
         const err = _.get(error, "error.meta.message", "Something Went Wrong");
         this.toastr.error(err);
        this.isSubmitting = false;
       });
  }

  getValueFormat(value) {
    return _.get(value, 'from', '') + '-' + _.get(value, 'to', '');
  }
  getSessionDetails() {
    this.isLoading = true;
    this.loader.start();
    this.api.getSessionDetails(this.dataService.TOKEN)
    .subscribe(data => {
      const apiData = _.get(data, 'data', {});
      if (!this.util.isEmpty(apiData) && !this.util.isEmpty(_.get(apiData, 'zoom_link', ''))) {
        if (!this.util.isEmpty(_.get(apiData, 'date', ''))) {
          this.data.date = new Date(_.get(apiData, 'date', ''));
        }
        setTimeout(() => {
          this.data.timeSlot = _.get(apiData, 'time_sloat', '');
          this.data.zoomLink = _.get(apiData, 'zoom_link', '');
        }, 0);
        this.openModel();
      }
      this.isLoading = false;
      this.loader.stop();
    }, error => {
      this.isLoading = false;
      this.loader.stop();
    });
  }
  getFormatedDate(date) {
    return moment(date).format("MMMM DD");
  }
  getFormatedTime(timeSlot) {
    const arrDates = _.split(timeSlot, "-");
    if (arrDates.length <=1) return "";
    const from = this.getTime(arrDates[0]);
    const to = this.getTime(arrDates[1]);
    return from + '-'+to;
  }
  isJoinNowVisible() {
    const duration = this.getDuration();
    if (duration != null) {
      return (duration.startDuration > 0 && duration.endDuration > 0 && duration.startDuration<=CONFIGCONSTANTS.TIME_CONFIG.JOIN_NOW || duration.startDuration < 0 && duration.endDuration > 0 && duration.endDuration <= CONFIGCONSTANTS.TIME_CONFIG.SPAN) ? true : false;
    }
    return false;
  }
  isReScheduleVisible() {
    const duration = this.getDuration();
    if (duration != null) {
      return (duration.startDuration > 0 && duration.endDuration > 0 && duration.startDuration >= CONFIGCONSTANTS.TIME_CONFIG.RESCHEDULE) ? true : false;
    }
    return false;
  }

  isContactToSuppoerMsgVisible() {
    const duration = this.getDuration();
    if (duration != null) {
      return this.isReScheduleVisible() == false && (duration.startDuration > 0 && duration.endDuration > 0 || duration.startDuration < 0 && duration.endDuration > 0);
    }
    return false;
  }

  getDuration() {
    const arrDates = _.split(this.data.timeSlot, "-");
    if (this.data.date!=null && !this.util.isEmpty(this.data.timeSlot) && arrDates.length > 0) {
      const startDate = moment(this.data.date).format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_FORMAT) +' ' + arrDates[0];
      const endDate = moment(this.data.date).format(CONFIGCONSTANTS.BOOK_SESSION_CONFIG.DATE_FORMAT) +' ' + arrDates[1];
      const startMoment = moment(startDate, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]);
      const endMoment = moment(endDate, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]);
      const startDuration = moment.duration(startMoment.diff(moment(this.dataService.data.dateTime, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]))).asMinutes();
      const endDuration = moment.duration(endMoment.diff(moment(this.dataService.data.dateTime, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]))).asMinutes();
      return {
        'startDuration': startDuration,
        'endDuration': endDuration
      };
    }
    return null;
  }
  onJoinNowClick() {
    const url = _.get(this.data, "zoomLink", null);
    if (!this.util.isEmpty(url)) {
      window.open(url, "_blank");
    }
  }
  ngOnDestroy() {
    if (this.modalRef !=null) {
      this.modalRef.hide();
    }
  }
}
