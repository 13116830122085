import { Directive, OnChanges, HostListener, ElementRef, Input } from '@angular/core';
import * as _ from 'lodash';
@Directive({
  selector: '[appOnlyCharachters]'
})
export class OnlyCharachtersDirective {
  @Input() validate = false;
  constructor(private ef: ElementRef) { }

  @HostListener('keypress', ['$event']) OnChanges(event) {
    if (!this.validate) return true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 97 && charCode <=122 || charCode >=65 && charCode <= 90 || charCode == 32) {
      return true;
    }
    return false;
  }

  @HostListener('focusout', ['$event']) OnFocusOut(event) {
    const value = _.trim(event.target.value, ' ');
    this.ef.nativeElement.value = value;
  }
}
