import { CONFIG } from './../../../config/app-config';
import { CONFIGCONSTANTS } from './../../../config/app-constants';
import { DOCUMENT } from '@angular/common';
import { AnimationService } from './../../../_services/animation.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private animation: AnimationService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.animation.init();
  }

  goToHome() {
    this.document.location.href = CONFIG.PORTAL_URL;
  }
}
