import { SkippedQuestionsComponent } from './views/skipped-questions/skipped-questions.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { SharerMethod } from 'ngx-sharebuttons';
import { ToastrModule } from 'ngx-toastr';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareModule } from 'ngx-sharebuttons';

import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AuthGuard } from './_guard/auth.guard';
import { RadioGroupComponent } from './_components/radio-group.component';
import { QuestionInputComponent } from './_components/multiple-questions/question-input.component';
import { QuestionsComponent } from './_components/multiple-questions/question.component';
import { MultipleQuestionsComponent } from './_components/multiple-questions/multiple-questions.component';
import { OtherInputComponent } from './_components/other-input.component';
import { GroupComponent } from './_components/group.component';
import { CheckboxComponent } from './_components/checkbox.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AnimationService } from './_services/animation.service';
import { PersonalInformationComponent } from './views/personal-information/personal-information.component';
import { HomeComponent } from './views/home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EmbedVideo } from 'ngx-embed-video';
import { PlaySoundDirective } from './_directives/play-sound.directive';
import { AssessmentComponent } from './views/assessment/assessment.component';
import { VideosComponent } from './views/videos/videos.component';
import { BookSessionComponent } from './views/book-session/book-session.component';
import { FeedbackComponent } from './views/feedback/feedback.component';
import { ProgressBarModule } from 'angular-progress-bar';
import { AnimationEffectComponent } from './views/animation-effect/animation-effect.component';
import { ReversePipe } from './_pipes/reverse.pipe';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InputChangeDirective } from './_directives/input-change.directive';
import { RankQuestionComponent } from './_components/rank-question/rank-question.component';
import { RankRequiredDirective } from './_directives/rank-required.directive';
import { OtherInputValidatorDirective } from './_directives/other-input-validator.directive';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NotFoundComponent } from './views/error/not-found/not-found.component';
import { AutocompleteComponent } from './_components/autocomplete/autocomplete.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PhoneMaskDirective } from './_directives/phone-mask.directive';
import { OnlyCharachtersDirective } from './_directives/only-charachters.directive';
import { ReviewQuestionsComponent } from './_components/review-questions/review-questions.component';
import { TieBreakerComponent } from './_components/tie-breaker/tie-breaker.component';
import { RecommendationsComponent } from './views/recommendations/recommendations.component';
import { ChartsModule } from 'ng2-charts';
import { PopoverModule } from 'ngx-smart-popover';
import { SubmitResultPopupComponent } from './_components/submit-result-popup/submit-result-popup.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationComponent } from './views/pagination/pagination.component';
import { LoaderDirective } from './_directives/loader.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TextValidatorDirective } from './_directives/text-validator.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PinchZoomModule } from 'ngx-pinch-zoom';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PersonalInformationComponent,
    PlaySoundDirective,
    AssessmentComponent,
    VideosComponent,
    BookSessionComponent,
    FeedbackComponent,
    AnimationEffectComponent,
    ReversePipe,
    InputChangeDirective,
    CheckboxComponent,
    GroupComponent,
    OtherInputComponent,
    MultipleQuestionsComponent,
    QuestionsComponent,
    QuestionInputComponent,
    RankQuestionComponent,
    RankRequiredDirective,
    RadioGroupComponent,
    OtherInputValidatorDirective,
    NotFoundComponent,
    AutocompleteComponent,
    PhoneMaskDirective,
    OnlyCharachtersDirective,
    ReviewQuestionsComponent,
    TieBreakerComponent,
    RecommendationsComponent,
    SubmitResultPopupComponent,
    PaginationComponent,
    LoaderDirective,
    TextValidatorDirective,
    SkippedQuestionsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    EmbedVideo.forRoot(),
    ProgressBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AutocompleteLibModule,
    NgxUiLoaderModule,
    ChartsModule,
    PopoverModule,
    BsDatepickerModule.forRoot(),
    ShareModule.withConfig({
      sharerMethod: SharerMethod.Window
    }),
    ShareIconsModule,
    ToastrModule.forRoot(),
    NgxStarRatingModule,
    AccordionModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    PdfViewerModule,
    PinchZoomModule
  ],
  providers: [AnimationService, BsModalService, AuthGuard,
    {
    provide: LocationStrategy, useClass: HashLocationStrategy
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
