import {Component, Input, Host } from '@angular/core';
import { GroupComponent } from './group.component';

@Component({
    selector: 'app-checkbox',
    template: `
        <div [class]="className">
            <div class="radio-group">
                <input appPlaySound type="checkbox" [checked]="isChecked()" [id]="id" (change)="toggleCheck()"/>
                <label [for]="id">{{value}}</label>
                </div>
        </div>
        <ng-content></ng-content>
    `
})
export class CheckboxComponent {
    @Input() value: any;
    @Input() id: any;
    @Input() className: string;
    constructor(@Host() private checkboxGroup: GroupComponent) {
    }

    toggleCheck() {
        this.checkboxGroup.addOrRemove(this.value);
    }

    isChecked() {
        return this.checkboxGroup.contains(this.value);
    }
}
