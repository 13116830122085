import { trigger, transition, style, animate } from '@angular/animations';
import { ApiService } from './../../_services/api.service';
import { CONFIGCONSTANTS } from './../../config/app-constants';
import { UtilService } from './../../_services/util.service';
import { DataService } from './../../_services/data.service';
import { AnimationService } from './../../_services/animation.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }), 
        animate(1000, style({ 'opacity': 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }), 
        animate(1000, style({ 'opacity': 0 }))
      ])
    ])
  ]
})
export class FeedbackComponent implements OnInit {
  socialShare = CONFIGCONSTANTS.SOCIAL_SHARE;
  successFeedback = {
    isLoading : false,
    message: '',
    show: false,
    css: ''
  }
  emailButton = {
    isLoading : false,
    message: '',
    show: false,
    css: ''
  }
  isLoading = false;
  downloadButton = {
    isLoading : false,
    message: '',
    show: false,
    css: ''
  }
  data = {
    rating : 0,
    text: ''
  }
  constants = CONFIGCONSTANTS;
  constructor(private animation: AnimationService, public dataService: DataService,
              public util: UtilService, private api: ApiService) { 
              }
  isSubmitted = false;
  ngOnInit(): void {
    this.animation.init();
    if (!this.dataService.isAllowToOpenViewReportPage()) {
      if (this.dataService.data[CONFIGCONSTANTS.IS_SUBMIT_ALL_QUESTIONS] == 1) {
        this.util.navigate('/book-session');
      } else {
        this.dataService.checkUserProgressAndRedirect();
      }
    }
  }
  submitReview() {
    this.saveFeedBack();
  }
  saveFeedBack() {
    this.successFeedback.isLoading = true;
    this.api.saveFeedBack(this.dataService.TOKEN, this.data.rating, this.data.text).subscribe(data => {
      this.dataService.setFeedBackSubmited();
      this.showMessage(this.successFeedback, 'Thank you for your valuable feedback', true, () => {
        this.dataService.setFeedBackRating(this.data.rating);
      })
    }, error => {
      const err = _.get(error, "error.meta.message", "Something Went Wrong");
      this.showMessage(this.successFeedback, err, false);
    })
  }
  downloadReport() {
    if (this.downloadButton.isLoading) return;
    this.downloadButton.isLoading = true;
    this.api.downloadReport(this.dataService.TOKEN).subscribe(data => {
      const url = _.get(data, "data.report_url", null);
      if (this.util.isEmpty(url)) {
        this.showMessage(this.downloadButton, 'Attachment not found', false);
      } else {
        this.showMessage(this.downloadButton, '', true); 
        window.open(url, "_blank");
      }
    }, error => {
      const err = _.get(error, "error.meta.message", "Something Went Wrong");
      this.showMessage(this.downloadButton, err, false);
    })
  }

  emailReport() {
    if (this.emailButton.isLoading) return;
    let msg = 'Report sent successfully';
    const email = _.get(this.dataService.data, CONFIGCONSTANTS.USER_EMAIL, '');
    if (!this.util.isEmpty(email))  {
        msg = msg + ' to "' + email + '"';
    }
    setTimeout(() => {
      this.showMessage(this.emailButton, msg, true);
    }, 2000);
    this.emailButton.isLoading = true;
    this.api.emailReport(this.dataService.TOKEN).subscribe(data => {
      // this.showMessage(this.emailButton, _.get(data, 'data', 'Report sent successfully') , true);
    }, error => {
      const err = _.get(error, "error.meta.message", "Something Went Wrong");
      this.showMessage(this.emailButton, err, false);
    })
  }
  showMessage(obj, message, isSuccess = true, callback?) {
    obj['message'] = message;
    obj['show'] = true;
    obj['css'] = isSuccess == true ? 'text-success' : 'text-danger';
    setTimeout(() => {
      obj['message'] = ''
      obj['show'] = false
      obj['css'] = ''
      obj['isLoading'] = false;
      if (callback != undefined) {
        callback();
      }
    }, 4000);
  }
}
