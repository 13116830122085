import { DataService } from './../../_services/data.service';
import { ApiService } from './../../_services/api.service';
import { CONFIGCONSTANTS } from './../../config/app-constants';
import { trigger, transition, style, animate } from '@angular/animations';
import { VideoPopupService } from './../../_services/video-popup.service';
import { UtilService } from './../../_services/util.service';
import { AnimationService } from './../../_services/animation.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
   trigger('fade', [
     transition(':enter', [
       style({ opacity: 0 }), 
       animate(500, style({ 'opacity': 1 }))
     ]),
     transition(':leave', [
       style({ opacity: 1 }), 
       animate(500, style({ 'opacity': 0 }))
     ])
   ])
 ]
})
export class VideosComponent implements OnInit {
   mediaTypes = CONFIGCONSTANTS.MEDIA_TYPES;
  data = [];

  boards = [
   ];
   search = {
      data: {
         boards :[],
         search : ""
      },
      isLoading: true,
      isSearcScreenVisible: false
   }
   boardsMultiSelectSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'name',
      enableCheckAll: false,
      allowSearchFilter: false,
      limitSelection: -1,
      maxHeight: 197,
      itemsShowLimit: 1,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
  constructor(private animation: AnimationService, public util: UtilService,
               public videoService: VideoPopupService, private api: ApiService, 
               private dataService: DataService) { }

  ngOnInit(): void {
   if (!this.dataService.isAllowToOpenVideosPage()) {
      this.dataService.checkUserProgressAndRedirect();
      return;
    }
     const boardsFilter = CONFIGCONSTANTS.VIDEOS_BOARDS_FILTER;
     _.forEach(boardsFilter, (bf)=> {
      this.boards.push({
         name: this.util.textChangeService(bf),
         value: bf
      })
     })
    this.animation.init();
    this.getVideos();
  }
  checkSearchEnableDisable() {
     this.data = [];
     this.getVideos();
  }
  onClearInput() {
     this.search.data = {
      boards :[],
      search : ""
   };
   this.search.isSearcScreenVisible = false;
   this.getVideos();
  }
  getVideos() {
     this.search.data.search = _.trim(this.search.data.search, ' ');
   if (!this.util.isEmpty(this.search.data.boards) || !this.util.isEmpty(this.search.data.search)) {
      this.search.isSearcScreenVisible = true;
   } else {
      this.search.isSearcScreenVisible = false;
   }
     this.search.isLoading = true;
     this.api.getVideos(this.dataService.TOKEN, _.map(this.search.data.boards, (v) => v.value), this.search.data.search).subscribe(data => {
      const videosData = _.get(data, 'data', []);
      this.data = [..._.values(videosData)];
      this.data = _.map(this.data, (d, i) => {
         const vObj = {...d};
         vObj['order'] = _.get(vObj, 'order', i + 1);
         if (_.isArray(vObj.videos)) {
            vObj.videos = _.map(vObj.videos, (v) => {
               const obj = _.clone(v);
               obj['raw_url'] = obj.video_url;
               (obj['video_url'] as any) = this.util.getSecureUrl(obj?.video_url);
               return obj;
            });
         } else {
               const obj = _.clone(vObj.videos);
               obj['raw_url'] = obj.video_url;
               (obj['video_url'] as any) = this.util.getSecureUrl(obj?.video_url);
            vObj.videos = [{...obj}]
         }
         return vObj;
      });
      this.data = _.orderBy(this.data, "order");
      this.search.isLoading = false;
     }, error => {
      this.data = [];
      this.search.isLoading = false;
     })
  }
  getVideosArray() {
   const result = [];
   _.forEach(this.data, (d) => {
      _.forEach(d.videos,(v) => {
         result.push(v);
      });
   });
   return result;
  }

  accordianChange($event) {
     setTimeout(() => {
      window.scroll({
         top: this.getTopOffset($event.target),
         left: 0,
         behavior: "smooth"
       });
     }, 500);
  }

  private getTopOffset(controlEl: HTMLElement): number {
   const labelOffset = 60;
   const controlElTop = controlEl.getBoundingClientRect().top;
   const absoluteControlElTop = controlElTop + window.scrollY - labelOffset;

   return absoluteControlElTop;
 }
 openVideoInModel(event, url, footerVisible , type) {
   event.stopPropagation();
   this.videoService.openModel(url, footerVisible, type);
 }
}
