import { SkippedQuestionsComponent } from './views/skipped-questions/skipped-questions.component';
import { RecommendationsComponent } from './views/recommendations/recommendations.component';
import { TieBreakerComponent } from './_components/tie-breaker/tie-breaker.component';
import { ReviewQuestionsComponent } from './_components/review-questions/review-questions.component';
import { AuthGuard } from './_guard/auth.guard';
import { NotFoundComponent } from './views/error/not-found/not-found.component';
import { AppComponent } from './app.component';
import { FeedbackComponent } from './views/feedback/feedback.component';
import { BookSessionComponent } from './views/book-session/book-session.component';
import { VideosComponent } from './views/videos/videos.component';
import { AssessmentComponent } from './views/assessment/assessment.component';
import { HomeComponent } from './views/home/home.component';
import { PersonalInformationComponent } from './views/personal-information/personal-information.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'personal-information',
        component: PersonalInformationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'assessment',
        component: AssessmentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'videos',
        component: VideosComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'book-session',
        component: BookSessionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'view-report',
        component: FeedbackComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'review',
        component: ReviewQuestionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'tie',
        component: TieBreakerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'recommendations',
        component: RecommendationsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'skipped-questions',
        component: SkippedQuestionsComponent,
        canActivate: [AuthGuard]
      }
    ],
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
