import { CONFIGCONSTANTS } from './../config/app-constants';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPlaySound]'
})
export class PlaySoundDirective {

  constructor() { }

  @HostListener('click') onClick() {
    this.playAudio();
  }

  @HostListener('change') onChange() {
    this.playAudio();
  }

  playAudio() {
    const audio = new Audio(CONFIGCONSTANTS.INPUT_CHANGE_SOUND_SOURCE);
    audio.load();
    audio.play();
  }
}
