import { CommonService } from './../../_services/common.service';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from './../../_services/api.service';
import { AnimationService } from './../../_services/animation.service';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CONFIGCONSTANTS } from './../../config/app-constants';
import { VideoPopupService } from './../../_services/video-popup.service';
import { UtilService } from './../../_services/util.service';
import { DataService } from './../../_services/data.service';
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-review-questions',
  templateUrl: './review-questions.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./review-questions.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(30%)', opacity: 0 }),
        animate('600ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(30%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class ReviewQuestionsComponent implements OnInit, OnDestroy {
  confirmFreezResponse = false;
  editRank = true;
  questions = [];
  constants = CONFIGCONSTANTS;
  isAllQuestionsRequired;
  tiggerChangeEvent = false;
  isSkippedQuestionModule = false;
  showCongratulations = true;
  isLoading = false;
  subcription: Subscription;
  constructor(public dataService: DataService, private cd: ChangeDetectorRef,
              public videoService: VideoPopupService, public util: UtilService, private animation: AnimationService,
              private api: ApiService, private loader: NgxUiLoaderService) {
    this.subcription = this.dataService.getChanges().subscribe(item => {
      this.loadData();
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    });
  }

  ngOnInit(): void {
    this.util.scrollPageToTop();
    this.dataService.closeResultPopup.emit();
    if (this.dataService.isAllowToOpenReviewPage() == false) {
      this.dataService.checkUserProgressAndRedirect();
    }
    this.animation.init();
    this.loadData();
    if (this.dataService.isAllAnswerSubmited() == false) {
      this.showCongratulations = false;
    }
  }

  public getClass(question: any) {
    const className = _.get(question, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.CSS_CLASS, '');
    if (!_.isEmpty(className)) {
      return className;
    }
    switch (true) {
      case this.questions.length > 3: return 'col-sm-3'; break;
      case this.questions.length > 1 && this.questions.length <= 3: return 'col-sm-6'; break;
      case this.questions.length === 1: return 'col-sm-12'; break;
      default: return 'col-sm-3';
    }
  }

  isPageContainsCheckBox() {
    const questionsWithCheckBox = _.filter(this.questions, (e) => _.get(e, 'answer_type', null) === CONFIGCONSTANTS.INPUT_TYPE.CHECKBOX);
    return questionsWithCheckBox.length > 0 ? true : false;
  }

  isQuestionWithMultipleAnswer(question: any) {
    return this.util.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_MULTIPLE);
  }

  loadData() {
    this.questions = this.dataService.getVisibleQuestions();
    this.isAllQuestionsRequired = this.dataService.getIsAllQuestionsRequired();
  }
  changeViewToReview() {
    if (this.isLoading) {
      return;
    }
    this.showCongratulations = false;
  }
  changeViewToCongratulations(f: NgForm) {
    if (!f.valid) {
      return;
    }
    this.showCongratulations = true;
  }

  openModel() {
    if (this.confirmFreezResponse == false) {
      return false;
    }
    this.isLoading = true;
    this.loader.start();
    this.loader.start();
    const idArrays = _.map(this.dataService.getVisibleQuestions(), (q) => q['id']);
    this.api.calculateResult(this.dataService.TOKEN, idArrays)
    .subscribe( data => {
      const isTieBreakerVisible = _.get(data, "data.is_tie_breaker", false);
      if (isTieBreakerVisible == true) {
        this.isRedirectToTieBreaker();      
      } else {
        this.isViewFinalResult();
      }
    }, error => {
        this.isViewFinalResult();
    })
  }
  isRedirectToTieBreaker() {
    this.dataService.setReviewQuestionsSubmited();
    this.isLoading = false;
    setTimeout(() => {
      this.loader.stopAll();
      this.dataService.checkUserProgressAndRedirect();
    }, 100);
  }
  isViewFinalResult() {
      this.dataService.setReviewQuestionsSubmited();
      this.isLoading = false;
      setTimeout(() => {
        this.loader.stopAll();
        this.dataService.openResultPopup.emit();
      }, 100);
  }
  closePopup() {
    this.dataService.closeResultPopup.emit();
  }
  onFreezCResponseChange($event) {
    this.confirmFreezResponse = $event.target.checked;
  }
  ngOnDestroy() {
    if(this.subcription!=null) {
      this.subcription.unsubscribe();
    }
  }
}
