import { CONFIGCONSTANTS } from './../../config/app-constants';
import { UtilService } from './../../_services/util.service';
import { ApiService } from './../../_services/api.service';
import { DataService } from './../../_services/data.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, OnChanges, ViewEncapsulation, Output, EventEmitter, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { concat } from 'rxjs';
import { AutocompleteComponent as comp } from 'angular-ng-autocomplete';
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor, OnChanges, AfterViewInit {
  @ViewChild("auto") autocomp;
  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  keyword = 'name';
  @Input() data = '';
  @Input() question: any;
  @Input() questionId: any;
  @Input() name: any;
  @Input() callStateOnChange = true;
  @Output() autocompleteChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() tiggerChangeEvent = true;
  @Input() isSkippedQuestionModule = false;
  options = [];
  isLoading = false;
  TYPE: any;
  @Input() isValid = false;
  constructor(private dataService: DataService, private api: ApiService, public util: UtilService) { }

  ngOnInit(): void {
    this.TYPE = this.util.getAutoCOmpleteType(this.question);
  }

  ngOnChanges() {
    this.TYPE = this.util.getAutoCOmpleteType(this.question);
  }

  onFocusOut() {
    if ((this.autocomp as comp).selectedIdx != -1) return;
    if (this.callStateOnChange === true) {
      this.dataService.setQuestionsAnswer(this.questionId, this._model, this.tiggerChangeEvent);
    }
    this.autocompleteChange.emit(this._model);
    setTimeout(() => {
      (this.autocomp as comp).close();
    }, 500);
  }
  ngAfterViewInit() {
    if (this.util.checkIsCountry(this.question) == true) { 
      (this.autocomp as comp).searchInput.nativeElement.addEventListener('focusout', this.onInputFocusOut.bind(this));
    }
  }
  onInputFocusOut() {
    this.saveData();  
  }
  saveData() {
    this.set(this.data);
  }
  onChangeSearch() {
    const value = _.trim(this.data);
    const regex = new RegExp(".*\\d+.*");
    if (this.util.isOnlyAllowCharachters(this.question) && regex.test(this.data) && !this.util.isEmpty(value)) {
      this.data = value.replace(/\d+/g, '');
    }
    const specialCharachters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (this.util.isNotAllowedSpecialCharachters(this.question) && specialCharachters.test(this.data) == true) {
      this.data = this.data.replace(/[^a-zA-Z ]/g, "");
    }
    if (this.util.checkIsCountry(this.question) == false) {
      this.saveData();
    }
    if (value.length >= 3) {
      if (this.util.checkIsCountry(this.question) == false) { 
        this.autocompleteChange.emit(this._model);
      }
      this.isLoading = true;
      if (this.TYPE == CONFIGCONSTANTS.ADDITIONAL.IS_CITY) {
        this.api.getCities(value).subscribe(data => {
          this.isLoading = false;
          this.options = _.get(data, 'data', []);
        }, error => {
          this.isLoading = false;
          this.options = [];
        });
      } else if (this.TYPE == CONFIGCONSTANTS.ADDITIONAL.IS_STATE) {
        this.api.getStates(value).subscribe(data => {
          this.isLoading = false;
          this.options = _.get(data, 'data', []);
        }, error => {
          this.isLoading = false;
          this.options = [];
        });
      } else if (this.TYPE == CONFIGCONSTANTS.ADDITIONAL.IS_COUNTRY) {
        this.api.getCountries(value).subscribe(data => {
          this.isLoading = false;
          this.options = _.get(data, 'data', []);
        }, error => {
          this.isLoading = false;
          this.options = [];
        });
      } else {
      }
    } else {
      this.options = [];
    }
  }

  writeValue(value: any): void {
    this._model = value;
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  set(value: any) {
      this._model = value;
      this.onChange(this._model);
  }
  selectEvent($event) {
    this.set($event.name);
    if (this.callStateOnChange === true) {
      this.dataService.setQuestionsAnswer(this.questionId, this._model, this.tiggerChangeEvent);
    }
    this.autocompleteChange.emit(this._model);
  }
  onClearInput($event) {
    this.data = '';
    this.set('');
    this.options = [];
    if (this.callStateOnChange === true) {
      this.dataService.setQuestionsAnswer(this.questionId, this._model, this.tiggerChangeEvent);
    }
    this.autocompleteChange.emit(this._model);
  }
  isNotFoundVisible(val){
    let value = this.util.isEmpty(val) ? '' : val;
    value = _.trim(value, ' ');
    return value.length < 3 ? false : true;
  }
  @HostListener('focusout', ['$event'])
  onModelChange(event) {
    setTimeout(() => {
      this.onFocusOut();
    }, 800);
  }
}
