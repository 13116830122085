<div aria-label="Page navigation example">
    <ul class="pagination">
    <li class="page-item" (click)="getPrevSpan()"><a class="page-link prev" [ngClass]="{'disable-pagination-btn': isFirstPage() == true}"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg></a></li>
        <ng-container *ngFor="let p of getPagesData(); let i = index">
            <ng-container *ngIf="i >= startViewSpanIndex && i <= endViewSpanIndex">
                <ng-container *ngIf="(isShowPaginationToolTip(p, i)); else withOutPopOver">
                    <li class="page-item" (click)="setPage(p, i)" [popover]="popover" [popoverOnHover]="true" popoverPlacement="bottom-right" >
                        <a class="page-link" [ngClass]="{'active_indi': dataService.getCurrentPageId() == p.id, 'active' : p?.total == p?.attempted}">{{i+1}}</a>
                    </li>
                </ng-container>
                <ng-template #withOutPopOver>
                    <li class="page-item" (click)="setPage(p, i)" >
                        <a class="page-link" [ngClass]="{'active_indi': dataService.getCurrentPageId() == p.id, 'active' : p?.total == p?.attempted}">{{i+1}}</a>
                    </li>
                </ng-template>
            </ng-container>
                <ng-container *ngIf="i == endViewSpanIndex + 1 && ((endViewSpanIndex + 1 < getPagesData().length - 1 && endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1) || (endViewSpanIndex + dotViewSpan + 1 > getPagesData().length - 1 && endViewSpanIndex + 1 < getPagesData().length - 1))">
                    <li class="page-item">
                        <a class="page-link dot-view disable_indi">...</a>
                    </li>
                    <ng-container *ngIf="isShowPaginationToolTip(getPagesData()[(endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1)], (endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1)); else lastPageWithOutPopOver">
                        <li class="page-item" (click)="setPage(getPagesData()[(endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1)], (endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1))" [popover]="popover" [popoverOnHover]="true" popoverPlacement="bottom-right">
                            <a class="page-link" [ngClass]="{'active' : getPagesData()[(endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1)]?.total == getPagesData()[(endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1)]?.attempted}">{{ endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan + 1 : getPagesData().length}}</a>
                        </li>
                    </ng-container>
                    <ng-template #lastPageWithOutPopOver>
                        <li class="page-item" (click)="setPage(getPagesData()[(endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1)], (endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1))" popoverPlacement="bottom-right">
                            <a class="page-link" [ngClass]="{'active' : getPagesData()[(endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1)]?.total == getPagesData()[(endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan : getPagesData().length - 1)]?.attempted}">{{ endViewSpanIndex + dotViewSpan + 1 < getPagesData().length - 1 ? endViewSpanIndex + dotViewSpan + 1 : getPagesData().length}}</a>
                        </li>
                    </ng-template>
                </ng-container>
        </ng-container>
      <li class="page-item" (click)="getNextSpan()"><a [ngClass]="{'disable-pagination-btn': isLastPage() == true}" class="page-link next"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg></a></li>
    </ul>
</div>
<popover-content #popover>
    <div class="tooltip-content">Answer all previous questions to access this question</div>
</popover-content>