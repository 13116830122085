import { Injectable, Output, EventEmitter, Renderer2, RendererFactory2 } from '@angular/core';
import * as AOS from 'aos';

@Injectable({
  providedIn: 'root'
})
export class AnimationService {
  animationLoading = true;
  showBlueBackGroundEffect = false;
  private renderer: Renderer2;
  @Output() animationLoadingStateChange: EventEmitter<any> = new EventEmitter();
  @Output() blueBackGroundEffectEvent: EventEmitter<any> = new EventEmitter();
  constructor(private rendererFectory: RendererFactory2) {
    this.renderer = this.rendererFectory.createRenderer(null, null);
  }

  init(animateOnce = true) {
    AOS.init({
      once: animateOnce
    });
    setTimeout(() => {
      this.refresh();
    }, 1000);
  }

  refresh() {
    AOS.refresh();
  }

  showAnimationEffect(resetBlueEffect = true) {
    if (resetBlueEffect) {
      this.showBlueBackGroundEffect = false;
      this.renderer.removeClass(document.body, 'successPopUp');
      this.emmitGetBlueBackGroundEffectEvent();
    }
    this.animationLoading = true;
    this.emitChange();
  }
  hideAnimationEffect() {
    this.animationLoading = false;
    this.showBlueBackGroundEffect = false;
    this.renderer.removeClass(document.body, 'successPopUp');
    this.emmitGetBlueBackGroundEffectEvent();
    this.emitChange();
  }
  emitChange() {
    this.animationLoadingStateChange.emit(this.animationLoading);
  }
  getAnimationLoadingStateChangeEvent() {
    return this.animationLoadingStateChange;
  }

  getBlueBackGroundEffectEvent() {
    return this.blueBackGroundEffectEvent;
  }
  emmitGetBlueBackGroundEffectEvent() {
    return this.blueBackGroundEffectEvent.emit(this.showAnimationEffect);
  }
}
