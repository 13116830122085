import { Subscription } from 'rxjs';
import { CONFIGCONSTANTS } from './../../config/app-constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from './../../_services/api.service';
import { AnimationService } from './../../_services/animation.service';
import { UtilService } from './../../_services/util.service';
import { VideoPopupService } from './../../_services/video-popup.service';
import { DataService } from './../../_services/data.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-skipped-questions',
  templateUrl: './skipped-questions.component.html',
  styleUrls: ['./skipped-questions.component.css']
})
export class SkippedQuestionsComponent implements OnInit, OnDestroy {

  constructor(public dataService: DataService, public videoService: VideoPopupService, 
    public util: UtilService, private animation: AnimationService,
    private api: ApiService, private loader: NgxUiLoaderService,
    private cd: ChangeDetectorRef) { }

  questions = [];
  catWiseQuestions = {};
  tiggerChangeEvent = false;
  constants = CONFIGCONSTANTS;
  isAllQuestionsRequired = true;
  isSkippedQuestionModule = true;
  editRank = true;
  getChangesSubscription: Subscription;
  ngOnInit(): void {
    this.util.scrollPageToTop();
    if (this.dataService.isShowANswerLaterBookNow() == false) {
      this.dataService.checkUserProgressAndRedirect();
    }

    this.getChangesSubscription = this.dataService.skipAnswerUpdate.subscribe(data => {
      const removeIds = _.map(data['remove'], (q) => q['id']);
      this.questions = _.filter(this.questions, (q) => {
        if (!_.includes(removeIds, q['id'])) {
          return true;
        }
        return false;
      });
      _.forEach(data['add'], (q) => {
        this.questions.push(q);
      });
      this.questions = _.orderBy(this.questions, ['page_number', 'question_order'], 'asc');
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    });

    this.animation.init();
    this.loadData();
  }
  navigateToAssesment() {
    this.util.navigate('/assessment');
  }
  loadData() {
    this.catWiseQuestions = this.dataService.getCategoryWiseSkippedQuestions();
    this.questions = this.dataService.getSkipedQuestions();
    this.isAllQuestionsRequired = this.dataService.getIsAllQuestionsRequired();
  }
  isPageContainsCheckBox() {
    const questionsWithCheckBox = _.filter(this.questions, (e) => _.get(e, 'answer_type', null) === CONFIGCONSTANTS.INPUT_TYPE.CHECKBOX);
    return questionsWithCheckBox.length > 0 ? true : false;
  }  
  public getClass(question: any) {
    const className = _.get(question, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.CSS_CLASS, '');
    if (!_.isEmpty(className)) {
      return className;
    }
    switch (true) {
      case this.questions.length > 3: return 'col-sm-3'; break;
      case this.questions.length > 1 && this.questions.length <= 3: return 'col-sm-6'; break;
      case this.questions.length === 1: return 'col-sm-12'; break;
      default: return 'col-sm-3';
    }
  }
  isQuestionWithMultipleAnswer(question: any) {
    return this.util.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_MULTIPLE);
  }
  isFirstQuestionOfCategory(q) {
    const catId = _.get(q, 'category_id', null);
    if (!this.util.isEmpty(catId)) {
      const questions = this.catWiseQuestions[catId];
      if (questions.length > 0 && questions[0]['id'] == q['id']) {
        return true;
      }
    }
    return false;
  }

  getCategoryTitle(catId) {
    return this.dataService.data.categories[catId]['name'];
  }

  ngOnDestroy(): void {
    if (this.getChangesSubscription != null) {
      this.getChangesSubscription.unsubscribe();
    }
  }
  getButtonText() {
    const questions = this.dataService.getSkipedQuestions();
    return questions.length > 0 ? 'Answer later' : 'Next';
  }
}
