<section class="multi_step_form">
    <form id="msform" name="form" #f="ngForm"> 
    <div class="assessment_sec">
      <div class="pagination_wrap page_wrap">
        <div class="row">
          <div class="sps col-sm-6">
            <div class="left_side">
              <span>Skipped Questions</span>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="right_side">
              <button type="button" class="primary_btn cntrl"><a (click)="navigateToAssesment()">{{getButtonText()}}</a></button>
            </div>
          </div>
        </div>
      </div>

      <div class="fieldset_wrap">
        <div class="row top_fixing">
        </div>

        <fieldset>
          <div class="assessment_cnt">
            <div class="form_cnt scroll_ques">
                <ng-container *ngFor="let q of questions">
                    <div class="general_heading" *ngIf="isFirstQuestionOfCategory(q) == true">
                        <label>{{getCategoryTitle(q['category_id'])}}</label>
                    </div>
                    <div class="form-group">
                        <ng-container *ngIf="isQuestionWithMultipleAnswer(q) == true else normalQuestions">
                            <label class="form-label">{{q.title}}</label>
                            <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                <div class="btn_play adj">
                                    <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                </div>
                            </ng-container>
                            <multiple-questions [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" [form]="f" [questionId]="q.id" ngModelGroup="{{q.id}}">
                                <ng-container *ngFor="let i of q.possible_answers | keyvalue; let ind = index;">
                                    <app-question [name]="i.key" [index]="ind+1" [lable]="i.key" [inputType]="q.answer_type" [(ngModel)]="q['user_answer'][i.key]" [(data)]="i.value" [required]="isAllQuestionsRequired"></app-question>
                                </ng-container>
                            </multiple-questions>
                        </ng-container>
                        <ng-template #normalQuestions>
                            <ng-container [ngSwitch]="q.answer_type">
                                <ng-container *ngSwitchCase="constants.INPUT_TYPE.RADIO">
                                    <label class="form-label">{{q.title}}</label>
                                    <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                        <div class="btn_play adj">
                                            <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                        </div>
                                    </ng-container>
                                        <app-radio-group [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" #l="ngModel" [errors]="l?.errors" appOtherInputValidator [question]="q" [required]="isAllQuestionsRequired" [name]="q.id" [pageContainsCheckBox]="isPageContainsCheckBox()" [questions]="questions" [(ngModel)]="q.user_answer" [q]="q">
                                        </app-radio-group>
                                </ng-container>
                                <ng-container *ngSwitchCase="constants.INPUT_TYPE.CHECKBOX">
                                    <label class="form-label">{{q.title}}</label>
                                    <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                        <div class="btn_play adj">
                                            <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                        </div>
                                    </ng-container>
                                    <p class="capt">Choose one or more.</p>
                                    <div class="row">
                                        <app-group [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" appOtherInputValidator [isWithCheckBox]="true" [question]="q" #group ="ngModel" [id]="q.id" [questionId]="q?.id" [required]="isAllQuestionsRequired" [(ngModel)]="q.user_answer" [name]="q.id">
                                        <ng-container  *ngFor="let ans of dataService.getDataList(q); let i=index;">
                                                <ng-container *ngIf="ans == constants.INPUT_TYPE.OTHERS else field">
                                                    <app-other-input [errors]="group?.errors" [value]="q.user_answer" [possibleValues]="q.possible_answers" [id]="q.id+'_'+i"></app-other-input>
                                                </ng-container>
                                            <ng-template #field>
                                                    <app-checkbox [className]="getClass(q)" [id]="q.id+'_'+i" [value]="ans"></app-checkbox>
                                             </ng-template> 
                                        </ng-container>
                                    </app-group>
                                    </div> 
                                </ng-container>
                                <ng-container *ngSwitchCase="constants.INPUT_TYPE.TEXT">
                                    <div class="form-group">
                                        <label [id]="q.id" class="form-label">{{q.title}}</label>
                                        <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                            <div class="btn_play adj">
                                                <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="util.isQuestionTypeIsAutoComplete(q) else rawText">
                                            <app-autocomplete [isSkippedQuestionModule]="isSkippedQuestionModule" [isValid]="acom?.valid" appOtherInputValidator [onlyTextField]="true" [tiggerChangeEvent]="tiggerChangeEvent" #acom="ngModel" [(ngModel)]="q.user_answer" [data]="q.user_answer" [question]="q" [questionId]="q.id" [id]="q.id" [required]="isAllQuestionsRequired" [name]="q.id"></app-autocomplete>
                                            <ng-container *ngIf="acom?.errors!=null && acom?.errors?.otherMinimumRequired!=null">
                                                <span class="error_msg">{{acom?.errors?.otherMinimumRequired}}</span>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #rawText>
                                            <input #l="ngModel" [isSkippedQuestionModule]="isSkippedQuestionModule" appTextValidator [question]="q" [tiggerChangeEvent]="tiggerChangeEvent" [ngClass]="{'minimum-required': l?.errors!=null && l?.errors?.otherMinimumRequired!=null}" [onlyTextField]="true"  appOtherInputValidator appInputChange appOnlyCharachters [validate]="util.isOnlyAllowCharachters(q)" [(ngModel)]="q.user_answer" [pageContainsCheckBox]="isPageContainsCheckBox()" [questionId]="q.id" [id]="q.id" [required]="isAllQuestionsRequired" [name]="q.id" type="text" class="form-control" placeholder="Your answer">
                                            <ng-container *ngIf="l?.errors!=null && l?.errors?.otherMinimumRequired!=null; else minumumReq">
                                                <span class="error_msg">{{l?.errors?.otherMinimumRequired}}</span>
                                            </ng-container>
                                            <ng-template #minumumReq>
                                                <span *ngIf="l?.errors!=null && l?.errors?.notAllowedNumber!=null" class="error_msg">{{l?.errors?.notAllowedNumber}}</span>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="constants.INPUT_TYPE.RANK">
                                    <br>
                                    <app-rank-question [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" [valid]="rank?.valid" [questionId]="q.id" [q]="q" #rank="ngModel" appRankRequired [id]="q.id" [name]="q.id" [required]="isAllQuestionsRequired" [options]="q.possible_answers" [data]="q.user_answer" [(ngModel)]="q.user_answer" [editRank]="editRank" [questionId]="q.id"></app-rank-question>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        <ng-container *ngIf="util.isQuestionHasNote(q)">
                            <p>{{util.getQuestionNoteTxt(q)}}</p>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            </div>
        </fieldset>
      </div>
    </div>
  </form>
</section>
