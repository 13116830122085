<ngx-ui-loader
  text= "Loading ..."
  fgsColor="#fff"
  [hasProgressBar]="false"
  bgsOpacity="1"
  overlayColor="#034A9B"
  blur="2"
></ngx-ui-loader>
<app-animation-effect *ngIf="showAnimationComponent"></app-animation-effect>
<div class="wrapper" [ngClass]="{blur: showBlueEffect}" *ngIf="!isAuthenticating">
    <app-header></app-header>
      <router-outlet></router-outlet>
     <app-footer></app-footer>
</div>
<div class="blurbg" *ngIf="showBlueEffect"></div>
<ng-template #videoPopup>
  <div class="modal-content"> 
      <div class="modal-body">
        <ng-container *ngIf="videoService.videoPopup.TYPE == mediaTypes.VIDEO; else pdfViewer">
          <div class="videoEmbed video_modal_resp">
            <iframe *ngIf="!util.isEmpty(videoService.videoPopup.URL_TXT)"
            [ngStyle]="{'height': videoService.videoPopup.TYPE == mediaTypes.VIDEO ? '': (innerHight - 150) + 'px'}"
            [src]="videoService.videoPopup.URL_TXT"
            frameborder="0"
            allowfullscreen
            ></iframe>
        </div>
        </ng-container>
        <ng-template #pdfViewer>
          <div class="frame-holder orverflow-auto" [ngStyle]="{'height': (innerHight - 150) + 'px','width': '100%', 'overflow-y': 'scroll',position: 'relative'}">
            <pinch-zoom [disableZoomControl]="'never'" [wheel]='false'>
              <pdf-viewer [src]="videoService.videoPopup.URL_TXT"
                [original-size]="false" 
            ></pdf-viewer>
            </pinch-zoom>
            <!-- <iframe
            [ngStyle]="{'height': '800 px','width': '100%'}"
            [src]="videoService.videoPopup.URL_TXT"
            frameborder="0"
            allowfullscreen
            >
          </iframe> -->
          </div>
        </ng-template>
          <!-- <ng-container *ngIf="videoService.videoPopup.IS_FOOTER_VISIBLE">
            <p class="complete_video">For complete video series</p>
          </ng-container> -->
      </div>
      <div class="modal-footer " *ngIf="videoService.videoPopup.IS_FOOTER_VISIBLE">
        <a (click)="onClickVideoModalContinue(videoPopup)" class="primary_btn cntrl">Continue</a>
      </div>
    </div>
</ng-template>
