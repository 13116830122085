
            <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">{{index}}. {{lable}}</label>
                <div class="row">
                    <ng-container *ngFor="let item of data; let i = index;">
                    <div class="col-sm-4">
                        <app-question-input [inputType]="inputType" [required]="true" [value]="item" [id]="i+'_'+item+'_'+lable"></app-question-input>
                    </div>
                    </ng-container>
                </div>
            </div>
    