import { CONFIGCONSTANTS } from './config/app-constants';
import { UtilService } from './_services/util.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { VideoPopupService } from './_services/video-popup.service';
import { DataService } from './_services/data.service';
import { ApiService } from './_services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AnimationService } from './_services/animation.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef, TemplateRef, OnChanges, Inject, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';
import { EmbedVideoService } from 'ngx-embed-video';
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  innerHight = window.innerHeight;
  mediaTypes = CONFIGCONSTANTS.MEDIA_TYPES;
  @ViewChild('videoPopup') videoPopupTemplate: TemplateRef<any>;
  showAnimationComponent: boolean;
  subscription: Subscription;
  showBlueEffect = false;
  isAuthenticating = false;
  paramSubscription: Subscription;
  constructor(private animation: AnimationService, private router: Router, private cd: ChangeDetectorRef,
              public api: ApiService, public dataService: DataService, public videoService: VideoPopupService,
              private modalService: BsModalService, public embedService: EmbedVideoService, public util: UtilService,
              private activatedroute: ActivatedRoute, @Inject(DOCUMENT) public document: Document,
              private spinner: NgxUiLoaderService) {
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.activatedroute.queryParams.subscribe(params => {
        this.dataService.TOKEN = params['token'];
        this.dataService.resetStore();
        this.startAuthentication();
        this.storeToken();
      });
    },0);
    this.initApp();
  }

  storeToken() {
    this.api.storeToken(this.dataService.TOKEN)
      .pipe(first())
      .subscribe(
        response => {
          this.fetchCategories();
        },
        error => {
          this.api.checkIfUnAuthorized(error);
          this.setAuthenticationDone();
        });
  }
  fetchQuestions() {
    this.api.getQuestions(this.dataService.TOKEN)
      .pipe(first())
      .subscribe(
        response => {
          this.dataService.setQuestions(response);
          this.setAuthenticationDone();
        },
        error => {
          this.api.checkIfUnAuthorized(error);
          this.setAuthenticationDone();
        });
  }

  fetchCategories() {
    this.api.getCategories()
      .pipe(first())
      .subscribe(
        response => {
          this.dataService.setCategories(response);
          this.fetchQuestions();
        },
        error => {
          this.api.redirectToUnAuthorizedPage();
          this.setAuthenticationDone();
        });
  }

  onClickVideoModalContinue(template: TemplateRef<any>) {
    this.videoService.closeModel();
    setTimeout(() => {
      this.router.navigate(['/assessment'], { queryParamsHandling: "preserve" });
    }, 500);
  }

  startAuthentication() {
    this.isAuthenticating = true;
    this.spinner.start();
  }
  setAuthenticationDone() {
    this.spinner.stopAll();
    setTimeout(() => {
      this.isAuthenticating = false;
      setTimeout(() => {
        this.dataService.checkIfLastPageIsAnswerLater();
      }, 0);
    }, 100);
  }
  ngOnDestroy() {
    this.dataService.clearCurrentTimeInterval();
  }

  initApp() {
    this.dataService.resetStore();
    this.startAuthentication();
    this.videoService.openModelEvent.subscribe(data => {
      if (this.videoService.videoPopup.TYPE == CONFIGCONSTANTS.MEDIA_TYPES.VIDEO) {
        this.videoService.videoPopup.MODEL_REF = this.modalService.show(this.videoPopupTemplate, { class: 'modal-md modal-dialog-centered' });
      } else {
        this.videoService.videoPopup.MODEL_REF = this.modalService.show(this.videoPopupTemplate, { class: 'modal-lg modal-dialog-centered'});
      }
    });
    this.modalService.onHide.subscribe(result => {
      this.videoService.resetPopupData();
    });
    this.videoService.closeMoelEvent.subscribe(data => {
      (this.videoService.videoPopup.MODEL_REF as BsModalRef).hide();
      this.videoService.resetPopupData();
    });
    this.subscription = this.animation.getAnimationLoadingStateChangeEvent()
      .subscribe(item => {
        this.showAnimationComponent = item;
        this.cd.detectChanges();
      });
    this.animation.getBlueBackGroundEffectEvent().subscribe(item => {
      this.showBlueEffect = this.animation.showBlueBackGroundEffect;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHight = window.innerHeight;
  }
}
