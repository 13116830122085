<section class="multi_step_form" data-aos="fade-down" data-aos-delay="500">  
    <form id="msform"  name="form" #f="ngForm"> 
        <div class="assessment_sec">
            <div class="pagination_wrap" >
                <app-pagination (pageChange)="onPageChangeHandler()"></app-pagination>
            </div>
            <div class="progress_sec">
                <ul>
                    <ng-container *ngFor="let cat of categories|keyvalue">
                        <li *ngIf="cat.value?.counts?.total > 0" [style.width]="getCategoryWidth()" [ngClass]="{'active': cat.key == questions[0]?.category_id }">
                            <progress-bar class="progress_bar" [progress]="getPercentage(cat.value?.counts?.atttempted, cat.value?.counts?.total)" [color]="'#86D61D'"></progress-bar>
                            <ng-container *ngIf="cat.key == questions[0]?.category_id; else InActiveCat">
                                <label>Question {{ cat.value?.counts?.min == cat.value?.counts?.max ? cat.value?.counts?.min : cat.value?.counts?.min + ' - ' + cat.value?.counts?.max }} /{{cat.value?.counts?.total}}</label>
                            </ng-container>
                            <ng-template #InActiveCat>
                                <label>Question {{getCategoryTotalValue(cat.key, cat.value) || "0"}} /{{cat.value?.counts?.total}}</label>
                            </ng-template>
                        </li>
                    </ng-container>
                </ul>
            </div>
            <div class="form_title" >
                <h2>{{getCategoryName()}}</h2>
            </div>
            <div class="fieldset_wrap" >
                <!-- *ngIf="show"  -->
                <fieldset *ngIf="show" [@slideInOut]>
                    <button  *ngIf="dataService.isSkipNowEnable && dataService.showAnswerLater(currentPage)" type="button" (click)="onAnswerLaterClick()" class="next skip"><a>{{getSkipNowText()}}</a></button> 			
                    <div class="assessment_cnt">								
                        <div class="form_cnt">
                            <div *ngFor="let q of questions" class="form-group">
                                <ng-container *ngIf="isQuestionWithMultipleAnswer(q) == true else normalQuestions">
                                    <label class="form-label">{{q.title}}</label>
                                    <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                        <div class="btn_play adj">
                                            <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                        </div>
                                    </ng-container>
                                    <multiple-questions  [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" [form]="f" [questionId]="q.id" ngModelGroup="{{q.id}}">
                                        <ng-container *ngFor="let i of q.possible_answers | keyvalue; let ind = index;">
                                            <app-question [name]="i.key" [index]="ind+1" [lable]="i.key" [inputType]="q.answer_type" [(ngModel)]="q['user_answer'][i.key]" [(data)]="i.value" [required]="isAllQuestionsRequired"></app-question>
                                        </ng-container>
                                    </multiple-questions>
                                </ng-container>
                                <ng-template #normalQuestions>
                                    <ng-container [ngSwitch]="q.answer_type">
                                        <ng-container *ngSwitchCase="constants.INPUT_TYPE.RADIO">
                                            <label class="form-label">{{q.title}}</label>
                                            <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                                <div class="btn_play adj">
                                                    <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                                </div>
                                            </ng-container>
                                                <app-radio-group [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" #l="ngModel" [errors]="l?.errors" appOtherInputValidator [question]="q" [required]="isAllQuestionsRequired" [name]="q.id" [pageContainsCheckBox]="isPageContainsCheckBox()" [questions]="questions" [(ngModel)]="q.user_answer" [q]="q">
                                                </app-radio-group>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="constants.INPUT_TYPE.CHECKBOX">
                                            <label class="form-label">{{q.title}}</label>
                                            <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                                <div class="btn_play adj">
                                                    <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                                </div>
                                            </ng-container>
                                            <p class="capt">Choose one or more.</p>
                                            <div class="row">
                                                <app-group [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" appOtherInputValidator [isWithCheckBox]="true" [question]="q" #group ="ngModel" [id]="q.id" [questionId]="q?.id" [required]="isAllQuestionsRequired" [(ngModel)]="q.user_answer" [name]="q.id">
                                                <ng-container  *ngFor="let ans of dataService.getDataList(q); let i=index;">
                                                        <ng-container *ngIf="ans == constants.INPUT_TYPE.OTHERS else field">
                                                            <app-other-input [errors]="group?.errors" [value]="q.user_answer" [possibleValues]="q.possible_answers" [id]="q.id+'_'+i"></app-other-input>
                                                        </ng-container>
                                                    <ng-template #field>
                                                            <app-checkbox [className]="getClass(q)" [id]="q.id+'_'+i" [value]="ans"></app-checkbox>
                                                     </ng-template> 
                                                </ng-container>
                                            </app-group>
                                            </div> 
                                        </ng-container>
                                        <ng-container *ngSwitchCase="constants.INPUT_TYPE.TEXT">
                                            <div class="form-group">
                                                <label [id]="q.id" class="form-label">{{q.title}}</label>
                                                <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                                    <div class="btn_play adj">
                                                        <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="util.isQuestionTypeIsAutoComplete(q) else rawText">
                                                    <app-autocomplete [isValid]="acom?.valid" appOtherInputValidator [onlyTextField]="true" [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" #acom="ngModel" [(ngModel)]="q.user_answer" [data]="q.user_answer" [question]="q" [questionId]="q.id" [id]="q.id" [required]="isAllQuestionsRequired" [name]="q.id"></app-autocomplete>
                                                    <ng-container *ngIf="acom?.errors!=null && acom?.errors?.otherMinimumRequired!=null">
                                                        <span class="error_msg">{{acom?.errors?.otherMinimumRequired}}</span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #rawText>
                                                    <input #l="ngModel" [isSkippedQuestionModule]="isSkippedQuestionModule" appTextValidator [question]="q" [tiggerChangeEvent]="tiggerChangeEvent" [ngClass]="{'minimum-required': l?.errors!=null && l?.errors?.otherMinimumRequired!=null}" [onlyTextField]="true"  appOtherInputValidator appInputChange appOnlyCharachters [validate]="util.isOnlyAllowCharachters(q)" [(ngModel)]="q.user_answer" [pageContainsCheckBox]="isPageContainsCheckBox()" [questionId]="q.id" [id]="q.id" [required]="isAllQuestionsRequired" [name]="q.id" type="text" class="form-control" placeholder="Your answer">
                                                    <ng-container *ngIf="l?.errors!=null && l?.errors?.otherMinimumRequired!=null; else minumumReq">
                                                        <span class="error_msg">{{l?.errors?.otherMinimumRequired}}</span>
                                                    </ng-container>
                                                    <ng-template #minumumReq>
                                                        <span *ngIf="l?.errors!=null && l?.errors?.notAllowedNumber!=null" class="error_msg">{{l?.errors?.notAllowedNumber}}</span>
                                                    </ng-template>
                                                </ng-template>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="constants.INPUT_TYPE.RANK">
                                            <app-rank-question [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" [valid]="rank?.valid" [questionId]="q.id" [q]="q" #rank="ngModel" appRankRequired [id]="q.id" [name]="q.id" [required]="isAllQuestionsRequired" [options]="q.possible_answers" [data]="q.user_answer" [(ngModel)]="q.user_answer" [editRank]="editRank" [questionId]="q.id"></app-rank-question>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                                <ng-container *ngIf="util.isQuestionHasNote(q)">
                                    <p>{{util.getQuestionNoteTxt(q)}}</p>
                                </ng-container>
                            </div>
                        </div>	
                    </div>
                    <div class="row">
                            <div class="edit_remarks">
                                <button type="button" [disabled]="!f.valid"  class="next primary_btn cntrl" [ngClass]="{'edit-ranking': isEditRankingVisible()}" *ngIf="((isPageContainsCheckBox()) || isEditRankingVisible() && !editRank) && currentPage <= dataService.getMaxPage() || !isAllQuestionsRequired" (click)="getNextPage()">{{ isEditRankingVisible() == true ?  'Confirm' : 'Next'}}</button>
                                <a class="edit_rank" *ngIf="isEditRankingVisible() && !editRank" (click)="toggleEditRanking()">Edit ranking</a>
                            </div>
                    </div>
                    <ng-container *ngFor="let img of getPageImages(); let imgIndex=index;">
                        <ng-container *ngIf="!util.isEmpty(getAnimationDirection(img)) ; else imgWithoutAnimation">
                            <span [attr.data-aos]="getAnimationDirection(img)"
                            data-aos-easing="ease-in-sine" data-aos-delay="300"  [class]="getImageClass(img)"><img [src]="imagePath + img.image"></span>
                        </ng-container>
                        <ng-template #imgWithoutAnimation>
                            <span [class]="getImageClass(img)"><img [src]="imagePath + img.image"></span>
                        </ng-template>
                    </ng-container>
                    <div class="bottom_caption" *ngIf="getDidYouKnow()">
                        <img src="assets/images/icon21.svg" alt=""><label class="heading">Did you know</label>
                        <p class="caps" [innerHTML]="getDidYouKnow()"></p>
                   </div>
                </fieldset>
                <fieldset *ngIf="!show" [@slideInOut]>
                    <button  *ngIf="dataService.isSkipNowEnable && dataService.showAnswerLater(currentPage)" type="button" (click)="onAnswerLaterClick()" class="next skip"><a>{{getSkipNowText()}}</a></button> 			
                    <div class="assessment_cnt">								
                        <div class="form_cnt">
                            <div *ngFor="let q of questions" class="form-group">
                                <ng-container *ngIf="isQuestionWithMultipleAnswer(q) == true else normalQuestions">
                                    <label class="form-label">{{q.title}}</label>
                                    <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                        <div class="btn_play adj">
                                            <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                        </div>
                                    </ng-container>
                                    <multiple-questions  [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" [form]="f" [questionId]="q.id" ngModelGroup="{{q.id}}">
                                        <ng-container *ngFor="let i of q.possible_answers | keyvalue; let ind = index;">
                                            <app-question [name]="i.key" [index]="ind+1" [lable]="i.key" [inputType]="q.answer_type" [(ngModel)]="q['user_answer'][i.key]" [(data)]="i.value" [required]="isAllQuestionsRequired"></app-question>
                                        </ng-container>
                                    </multiple-questions>
                                </ng-container>
                                <ng-template #normalQuestions>
                                    <ng-container [ngSwitch]="q.answer_type">
                                        <ng-container *ngSwitchCase="constants.INPUT_TYPE.RADIO">
                                            <label class="form-label">{{q.title}}</label>
                                            <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                                <div class="btn_play adj">
                                                    <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                                </div>
                                            </ng-container>
                                                <app-radio-group [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" #l="ngModel" [errors]="l?.errors" appOtherInputValidator [question]="q" [required]="isAllQuestionsRequired" [name]="q.id" [pageContainsCheckBox]="isPageContainsCheckBox()" [questions]="questions" [(ngModel)]="q.user_answer" [q]="q">
                                                </app-radio-group>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="constants.INPUT_TYPE.CHECKBOX">
                                            <label class="form-label">{{q.title}}</label>
                                            <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                                <div class="btn_play adj">
                                                    <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                                </div>
                                            </ng-container>
                                            <p class="capt">Choose one or more.</p>
                                            <div class="row">
                                                <app-group [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" appOtherInputValidator [isWithCheckBox]="true" [question]="q" #group ="ngModel" [id]="q.id" [questionId]="q?.id" [required]="isAllQuestionsRequired" [(ngModel)]="q.user_answer" [name]="q.id">
                                                <ng-container  *ngFor="let ans of dataService.getDataList(q); let i=index;">
                                                        <ng-container *ngIf="ans == constants.INPUT_TYPE.OTHERS else field">
                                                            <app-other-input [errors]="group?.errors" [value]="q.user_answer" [possibleValues]="q.possible_answers" [id]="q.id+'_'+i"></app-other-input>
                                                        </ng-container>
                                                    <ng-template #field>
                                                            <app-checkbox [className]="getClass(q)" [id]="q.id+'_'+i" [value]="ans"></app-checkbox>
                                                     </ng-template> 
                                                </ng-container>
                                            </app-group>
                                            </div> 
                                        </ng-container>
                                        <ng-container *ngSwitchCase="constants.INPUT_TYPE.TEXT">
                                            <div class="form-group">
                                                <label [id]="q.id" class="form-label">{{q.title}}</label>
                                                <ng-container *ngIf="!util.isEmpty(q?.video_url)">
                                                    <div class="btn_play adj">
                                                        <button type="button" class="btn btn-lg" (click)="videoService.openModel(q?.video_url, false)"><img src="assets/images/icon20.svg"> Watch Video</button>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="util.isQuestionTypeIsAutoComplete(q) else rawText">
                                                    <app-autocomplete [isValid]="acom?.valid" appOtherInputValidator [onlyTextField]="true" [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" #acom="ngModel" [(ngModel)]="q.user_answer" [data]="q.user_answer" [question]="q" [questionId]="q.id" [id]="q.id" [required]="isAllQuestionsRequired" [name]="q.id"></app-autocomplete>
                                                    <ng-container *ngIf="acom?.errors!=null && acom?.errors?.otherMinimumRequired!=null">
                                                        <span class="error_msg">{{acom?.errors?.otherMinimumRequired}}</span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #rawText>
                                                    <input #l="ngModel" [isSkippedQuestionModule]="isSkippedQuestionModule" appTextValidator [question]="q" [tiggerChangeEvent]="tiggerChangeEvent" [ngClass]="{'minimum-required': l?.errors!=null && l?.errors?.otherMinimumRequired!=null}" [onlyTextField]="true"  appOtherInputValidator appInputChange appOnlyCharachters [validate]="util.isOnlyAllowCharachters(q)" [(ngModel)]="q.user_answer" [pageContainsCheckBox]="isPageContainsCheckBox()" [questionId]="q.id" [id]="q.id" [required]="isAllQuestionsRequired" [name]="q.id" type="text" class="form-control" placeholder="Your answer">
                                                    <ng-container *ngIf="l?.errors!=null && l?.errors?.otherMinimumRequired!=null; else minumumReq">
                                                        <span class="error_msg">{{l?.errors?.otherMinimumRequired}}</span>
                                                    </ng-container>
                                                    <ng-template #minumumReq>
                                                        <span *ngIf="l?.errors!=null && l?.errors?.notAllowedNumber!=null" class="error_msg">{{l?.errors?.notAllowedNumber}}</span>
                                                    </ng-template>
                                                </ng-template>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="constants.INPUT_TYPE.RANK">
                                            <app-rank-question [isSkippedQuestionModule]="isSkippedQuestionModule" [tiggerChangeEvent]="tiggerChangeEvent" [valid]="rank?.valid" [questionId]="q.id" [q]="q" #rank="ngModel" appRankRequired [id]="q.id" [name]="q.id" [required]="isAllQuestionsRequired" [options]="q.possible_answers" [data]="q.user_answer" [(ngModel)]="q.user_answer" [editRank]="editRank" [questionId]="q.id"></app-rank-question>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                                <ng-container *ngIf="util.isQuestionHasNote(q)">
                                    <p>{{util.getQuestionNoteTxt(q)}}</p>
                                </ng-container>
                            </div>
                        </div>	
                    </div>
                    <div class="row">
                            <div class="edit_remarks">
                                <button type="button" [disabled]="!f.valid"  class="next primary_btn cntrl" [ngClass]="{'edit-ranking': isEditRankingVisible()}" *ngIf="((isPageContainsCheckBox()) || isEditRankingVisible() && !editRank) && currentPage <= dataService.getMaxPage() || !isAllQuestionsRequired" (click)="getNextPage()">{{ isEditRankingVisible() == true ?  'Confirm' : 'Next'}}</button>
                                <a class="edit_rank" *ngIf="isEditRankingVisible() && !editRank" (click)="toggleEditRanking()">Edit ranking</a>
                            </div>
                    </div>
                    <ng-container *ngFor="let img of getPageImages(); let imgIndex=index;">
                        <ng-container *ngIf="!util.isEmpty(getAnimationDirection(img)) ; else imgWithoutAnimation">
                            <span [attr.data-aos]="getAnimationDirection(img)"
                            data-aos-easing="ease-in-sine" data-aos-delay="300"  [class]="getImageClass(img)"><img [src]="imagePath + img.image"></span>
                        </ng-container>
                        <ng-template #imgWithoutAnimation>
                            <span [class]="getImageClass(img)"><img [src]="imagePath + img.image"></span>
                        </ng-template>
                    </ng-container>
                    <div class="bottom_caption" *ngIf="getDidYouKnow()">
                        <img src="assets/images/icon21.svg" alt=""><label class="heading">Did you know</label>
                        <p class="caps" [innerHTML]="getDidYouKnow()"></p>
                   </div>
                </fieldset>
            </div>
        </div>
    </form>  
</section> 
<div class="skip_ques"  data-aos="fade-up" data-aos-delay="200" *ngIf="dataService.isSkipNowEnable && dataService.isShowANswerLaterBookNow()">
    <div class="fxd_btm_p">
        <i><img src="assets/images/alert_icon.svg" class="tick" alt=""></i>
        <p class="">  Hey, you have selected {{dataService.getSkipAnswerCount()}} question<ng-container *ngIf="dataService.getSkipAnswerCount() > 1">s</ng-container> to answer later.
            <span> All questions are mandatory.</span></p>
    </div>
    <button class="primary_btn pull-right"><a (click)="navigateToSkipQuestion()">Answer Now!</a></button>
</div>
<ng-template #assesmentSuccess>
    <div class="modal-body">
        <div class="popup_section">
            <ng-container *ngIf="dataService.isSkipNowEnable && dataService.isShowANswerLaterBookNow();else allAttempted">
                <!-- <p>Hey, {{dataService.getSkipAnswerCount()}} question<ng-container *ngIf="dataService.getSkipAnswerCount() > 1">s</ng-container> remain unanswered in the previous section<ng-container *ngIf="isPopupForFirstCategory() == false">s</ng-container>.</p> -->
                <p>Yayy! You have almost completed {{assesmentSuccessPopupData?.title}} section.</p>
                <img class="business_plan" [src]="imagePath + assesmentSuccessPopupData?.icon" alt="">
            </ng-container>
            <ng-template #allAttempted>
                <img src="assets/images/icon22.svg" class="tick" alt="">
                <p>Yayy! You have completed {{assesmentSuccessPopupData?.title}} section.</p>
                <img class="business_plan" [src]="imagePath + assesmentSuccessPopupData?.icon" alt="">
            </ng-template>
        </div>
        </div>
        <div class="modal-footer d-flex flex-column" [ngClass]="{'secondary-btn-div cntrl': (((dataService.isSkipNowEnable && dataService.isShowANswerLaterBookNow()) && (isLastPageWithSkipAnswer() == false)) == false), 'skip_button': ((dataService.isSkipNowEnable && dataService.isShowANswerLaterBookNow()) && (isLastPageWithSkipAnswer() == false))}">
          <a class="secondary_btn width-250" *ngIf="isLastPageWithSkipAnswer() == false" (click)="hideModel()">Continue To Next Section</a>
          <a class="width-250" [ngClass]="{'secondary_btn skip-ans-btn': isLastPageWithSkipAnswer() == true, 'skip_twocol_btn mt-3': isLastPageWithSkipAnswer() == false}" (click)="onPopUpNavigationToSkipQuestion()" *ngIf="dataService.isSkipNowEnable && dataService.isShowANswerLaterBookNow()">Answer Skipped Questions</a>
        </div>
</ng-template>