<header data-aos="fade-right" *ngIf="isHeaderVisible() else headerContent" [ngClass]="{'book-header': isBookSessionPage()}">
<nav class="navbar navbar-light navbar-expand-lg py-3 px-4">
    <div id="logo">
            <a (click)="headerClickHandler()" queryParamsHandling="preserve"><img src="assets/images/logo2.svg"/></a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" [ngClass]="{'collapsed': !isShow}" (click)="isShow = !isShow">
      <span> </span>
      <span> </span>
      <span> </span>
    </button>
    <div class="navbar-collapse" [ngClass]="{'collapse': !isShow}">
      <ul class="navbar-nav ml-auto text-right">
        <li class="nav-item" [ngClass]="{'active': isAssesmentActive()}">
            <a class="nav-link" (click)="onAssesmentClick($event)">Assessment</a>
        </li>
        <li *ngIf="dataService.isSkipNowEnable" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/videos']" queryParamsHandling="preserve">Videos</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <ng-container *ngIf="dataService.isAllowToBookSession() == true ; else withOutLink">
                <a (click)="onLinkClick()" class="nav-link" [routerLink]="['/book-session']" queryParamsHandling="preserve">Book a Session</a>
            </ng-container>
            <ng-template #withOutLink>
              <ng-container *ngIf="dataService.isAllowToOpenViewReportPage() == true; else normalLink">
                <a (click)="onLinkClick()"  class="nav-link" [routerLink]="['/view-report']" queryParamsHandling="preserve">View Report</a>
              </ng-container>
            </ng-template>
            <ng-template #normalLink>
              <a (click)="onLinkClick()" class="nav-link" [popover]="myPopover" [popoverDismissTimeout]="2000" [popoverOnHover]="true" popoverPlacement="bottom-right">Book a Session</a>
            </ng-template>
        </li>
      </ul>
    </div>
  </nav>
</header> 
<ng-template #headerContent>
    <div class="landing_logo">
        <a (click)="headerClickHandler()" queryParamsHandling="preserve"><img  data-aos="fade-down" data-aos-delay="0.5" data-aos-duration="500" src="assets/images/logo.svg" /></a>
    </div>        
</ng-template>
<popover-content #myPopover>
    <div class="tooltip-content">Complete the questioner to schedule your counselling session to discuss the output.</div>
</popover-content>