import { CONFIGCONSTANTS } from './../config/app-constants';
import {Component, Input, Host, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { GroupComponent } from './group.component';
import * as _ from 'lodash';
@Component({
    selector: 'app-other-input',
    template: `
        <div class="col-sm-12">
            <input type="text" [ngClass]="{'minimum-required': errors!=null && errors?.otherMinimumRequired!=null}" [id]="id" (change)="onChange($event)" [value]="getOtherValues()" class="form-control" placeholder="Others">
            <ng-container *ngIf="errors!=null && errors?.otherMinimumRequired!=null">
                            <span class="error_msg">{{errors?.otherMinimumRequired}}</span>
            </ng-container>
        </div>
        <ng-content></ng-content>
    `
})
export class OtherInputComponent {
    @Input() value: any[];
    @Input() id: any;
    @Input() possibleValues: any[];
    @Input() errors: any;
    constructor(@Host() private checkboxGroup: GroupComponent) {
    }

    onChange($event) {
        const val = $event.target.value;
        this.checkboxGroup.remove(this.getOtherValues());
        if (!_.isEmpty(val)) {
            this.checkboxGroup.add(val);
        }
    }

    getOtherValues() {
        this.possibleValues = [...this.possibleValues];
        _.remove(this.possibleValues, (e) => e === CONFIGCONSTANTS.INPUT_TYPE.OTHERS);
        const a = _.differenceWith(this.value, this.possibleValues, _.isEqual);
        return a.length > 0 && !_.isEmpty(a[0]) ? a[0] : '';
    }
}
