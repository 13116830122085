import { DataService } from './../_services/data.service';
import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';

@Directive({
  selector: '[appInputChange]'
})
export class InputChangeDirective {
  @Input() questionId: any;
  @Input() pageContainsCheckBox = false;
  @Input() tiggerChangeEvent = true;
  @Input() isSkippedQuestionModule = false;
  constructor(private dataService: DataService, private el: ElementRef, private model: NgModel, private form: NgForm) { }

  @HostListener('change') onChange() {
      this.dataService.setQuestionsAnswer(this.questionId, this.model.value, this.tiggerChangeEvent, this.isSkippedQuestionModule);
  }
}
