import { CONFIGCONSTANTS } from './../../config/app-constants';
import { UtilService } from './../../_services/util.service';
import { ApiService } from './../../_services/api.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { style } from '@angular/animations';
import { DataService } from './../../_services/data.service';
import { AnimationService } from './../../_services/animation.service';
import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartType, ChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';
import * as  moment from 'moment';
@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RecommendationsComponent implements OnInit {
  sessionData = {
    date: '',
    timeSlot: '',
    zoomLink: ''
  }
  timezone = CONFIGCONSTANTS.TIME_ZONE;
  boardData = CONFIGCONSTANTS.BOARD_DATA;
  boardOrderText = CONFIGCONSTANTS.BOARD_ORDER_TEXT;
  data = {
    "is_cam_rule_out": 0,
    "is_cbse_rule_out": 0,
    "is_ib_rule_out": 0,
    "is_icse_rule_out": 0,
    "location_feasibility": "",
    "suitable_boards": "",
    "cam_per": 0,
    "cbse_per": 0,
    "ib_per": 0,
    "icse_per": 0,
    "our_recommendation":"",
    "expectations_preferences":"",
    "financial_feasibility":"",
    "graph_text": "",
    "cam_reason": [],
    "cbse_reason": [],
    "ib_reason":[],
    "icse_reason":[]
  };
  lablesWiseData = {
    "is_cbse_rule_out": {
      "per": 0,
      "rule_out": 0,
      "reason": [],
      "key": "CBSE",
      "rule_out_key": "is_cbse_rule_out"
    },
    "is_cam_rule_out": {
      "per": 0,
      "rule_out": 0,
      "reason": [],
      "key": "CAM",
      "rule_out_key": "is_cam_rule_out"
    },
    "is_ib_rule_out": {
      "per": 0,
      "rule_out": 0,
      "reason": [],
      "key": "IB",
      "rule_out_key": "is_ib_rule_out"
    },
    "is_icse_rule_out": {
      "per": 0,
      "rule_out": 0,
      "reason": [],
      "key": "ICSE",
      "rule_out_key": "is_icse_rule_out"
    }
  }
  lablesIds = {
  }
  innerWidth = window.innerWidth;
  public barChartOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false
    },
    scales: { xAxes: [{
      gridLines : {
        display : false,
      },
      scaleLabel: {
        display: true,
        labelString: 'CURRICULUM',
        fontStyle: "bold",
        fontSize: 17,
        fontFamily: 'SegoeUI'
      },
      ticks: {
        fontSize: 15,
        stepSize: 1,
        beginAtZero: true,
        fontFamily: 'SegoeUI',
      },
    }], yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'FITMENT',
        fontStyle: "bold",
        fontSize: 17,
        fontFamily: 'SegoeUI',
      },
      ticks: {
        beginAtZero: true,
        suggestedMax: 25,
        display: false,
        fontFamily: 'SegoeUI'
      },
    }] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#ffffff',
        font: {
          size: 14,
          weight: 600,
          style: 'italic',
          family: 'SegoeUI',
          lineHeight: 20
        },
        formatter: (value, context) => {          
          return this.data[this.lablesIds[context.dataIndex]] == 1 ? 'Ruled Out' : '';
        },
      }
    }
  };

  public barChartOptionsMobile : ChartOptions = {
    responsive: true,
    tooltips: {
      enabled: false
    },
    legend: {
      display: false
    },
    scales: { xAxes: [{
      gridLines : {
        display : false,
      },
      scaleLabel: {
        display: true,
        labelString: 'CURRICULUM',
        fontStyle: "bold",
        fontSize: 12,
        fontFamily: 'SegoeUI'
      },
      ticks: {
        beginAtZero: true,
        display: false,
        fontFamily: 'SegoeUI',
      },
    }], yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'FITMENT',
        fontStyle: "bold",
        fontSize: 12,
        fontFamily: 'SegoeUI',
      },
      ticks: {
        fontSize: 10,
        beginAtZero: true,
        suggestedMax: 25,
        fontFamily: 'SegoeUI'
      },
    }] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#ffffff',
        font: {
          size: 6,
          weight: 600,
          style: 'italic',
          family: 'SegoeUI',
          lineHeight: 10
        },
        formatter: (value, context) => {
          return this.lablesIds[context.dataIndex] == 1 ? 'Ruled Out' : '';
        },
      }
    }
  };

  chart: {
    barChartLabels: Label[],
    barChartLegend: boolean,
    barChartPlugins: any[]
    data: any []
  } = {
    barChartLabels : [],
    barChartLegend: true,
    barChartPlugins: [pluginDataLabels],
    data: []
  };

  constructor(private animation: AnimationService, public dataService: DataService, 
              private loader: NgxUiLoaderService, private api: ApiService,
              public util: UtilService) {
              }

  ngOnInit(): void {
    if (this.dataService.isAllowToOpenRecommendationsPage() == false) {
      this.dataService.checkUserProgressAndRedirect();
    }
    this.getSessionDetails();
    this.util.scrollPageToTop();
    this.dataService.closeResultPopup.emit();
    this.api.getResult(this.dataService.TOKEN).subscribe( data => {
      this.data.cam_per = _.get(data, 'data.result.cam_per', 0);
      this.data.cbse_per = _.get(data, 'data.result.cbse_per', 0);
      this.data.ib_per = _.get(data, 'data.result.ib_per', 0);
      this.data.icse_per = _.get(data, 'data.result.icse_per', 0);
      this.data.suitable_boards = _.get(data, 'data.suitable_boards', "");
      this.data.is_cam_rule_out = _.get(data, 'data.result.is_cam_rule_out', 0);
      this.data.is_cbse_rule_out = _.get(data, 'data.result.is_cbse_rule_out', 0);
      this.data.is_ib_rule_out = _.get(data, 'data.result.is_ib_rule_out', 0);
      this.data.is_icse_rule_out = _.get(data, 'data.result.is_icse_rule_out', 0);
      this.data.cbse_reason = _.get(data, 'data.result.cbse_reason', []);
      this.data.cam_reason = _.get(data, 'data.result.cam_reason', []);
      this.data.ib_reason = _.get(data, 'data.result.ib_reason', []);
      this.data.icse_reason = _.get(data, 'data.result.icse_reason', []);

      this.data.expectations_preferences = _.get(data, 'data.result.expectations_preferences', '');
      this.data.financial_feasibility = _.get(data, 'data.result.financial_feasibility', '');
      this.data.location_feasibility = _.get(data, 'data.result.location_feasibility', '');
      this.data.our_recommendation = _.get(data, 'data.result.our_recommendation', '');
      this.data.graph_text = _.get(data, 'data.result.graph_text', '');
      const costingData = [];

      this.lablesWiseData["is_cbse_rule_out"]['rule_out'] = this.data.is_cbse_rule_out;
      this.lablesWiseData["is_cbse_rule_out"]['per'] =  this.getNumericValue(this.data.cbse_per);
      this.lablesWiseData["is_cbse_rule_out"]['reason'] = this.data.cbse_reason;

      this.lablesWiseData["is_cam_rule_out"]['rule_out'] = this.data.is_cam_rule_out;
      this.lablesWiseData["is_cam_rule_out"]['per'] = this.getNumericValue(this.data.cam_per);
      this.lablesWiseData["is_cam_rule_out"]['reason'] = this.data.cam_reason;

      this.lablesWiseData["is_ib_rule_out"]['rule_out'] = this.data.is_ib_rule_out;
      this.lablesWiseData["is_ib_rule_out"]['per'] = this.getNumericValue(this.data.ib_per);
      this.lablesWiseData["is_ib_rule_out"]['reason'] = this.data.ib_reason;

      this.lablesWiseData["is_icse_rule_out"]['rule_out'] = this.data.is_icse_rule_out;
      this.lablesWiseData["is_icse_rule_out"]['per'] = this.getNumericValue(this.data.icse_per);
      this.lablesWiseData["is_icse_rule_out"]['reason'] = this.data.icse_reason;
      _.forEach(this.lablesWiseData, (d) => {
        if (d['rule_out'] == 1) {
          d['per'] = CONFIGCONSTANTS.RULED_OUT_PERCENTAGE;
        }
      });
      const dataArr = _.orderBy(_.values(this.lablesWiseData),'per',['desc']);
      this.chart.barChartLabels = [];
      const arr = [];
      const colors = ["#284398", "#ff5727", "#74cba9", "#88ccee"];
      _.forEach(dataArr, (e, i) => {
        this.lablesIds[i] = e['rule_out_key'];
        this.chart.barChartLabels.push(this.util.textChangeService((e['key'])));
        if (e['rule_out'] == 1) {
          colors[i] = CONFIGCONSTANTS.RULED_OUT_GRAPH_COLOR;
        }
        arr.push(e['per']);
      });

      this.chart.data = [{ data: [... arr], backgroundColor: [...colors], hoverBackgroundColor: [...colors]}];
    }, error => {
      
    });
    this.animation.init();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  getBoardData() {
    const numberOfResult = 2;
    const diffrence = 20;
    const dataArr = _.orderBy(_.values(_.pickBy(this.lablesWiseData, (value, key) => {return value['rule_out'] == 0 && value['per'] > 0 })),'per',['desc']);
    const result =_.filter(dataArr, (val, i) => {
        if (i == 0) return true;
        if (i < numberOfResult && i!=0 &&  dataArr[i-1]['per'] - val['per'] <= diffrence && dataArr[i-1]['per'] - val['per'] >= 0) return true;
    });
    return result;
  }

  getBoardOrderText(index, board) {
    return _.replace(this.boardOrderText[index], "##BOARD##", board);
  }
  navigateToBookSession() {
   this.util.navigate('/book-session');
   }

   getSessionDetails() {
    this.loader.start();
    this.api.getSessionDetails(this.dataService.TOKEN)
    .subscribe(data => {
      const apiData = _.get(data, 'data', {});
      if (!this.util.isEmpty(apiData) && !this.util.isEmpty(_.get(apiData, 'zoom_link', ''))) {
        this.sessionData.date = _.get(apiData, 'date', '');
        setTimeout(() => {
          this.sessionData.timeSlot = _.get(apiData, 'time_sloat', '');
          this.sessionData.zoomLink = _.get(apiData, 'zoom_link', '');
        }, 0);
      }
      this.loader.stop();
    }, error => {
      this.loader.stop();
    });
  }
  isShowJoinNowNotification() {
    return !this.util.isEmpty(this.sessionData.date) && !this.util.isEmpty(this.sessionData.timeSlot) && !this.util.isEmpty(this.sessionData.zoomLink);
  }
  getTime(time) {
    const arr = _.split(time, '-');
    let slot =  '';
    if (arr.length > 0 && !this.util.isEmpty(arr[0])) {
      slot = moment(arr[0], 'HH:mm:ss').format('HH:mm a');
      if (arr.length > 1 && !this.util.isEmpty(arr[1])) { 
        slot += ' to ' + moment(arr[1], 'HH:mm:ss').format('HH:mm a');
      }
    }
    return slot;
  }
  getFormatedDate(date) {
    return moment(date).format("MMMM DD");
  }
  onJoinNowClick() {
    if (this.isJoinNowEnable() == false) return;
    const url = _.get(this.sessionData, "zoomLink", null);
    if (!this.util.isEmpty(url)) {
      window.open(url, "_blank");
    }
  }

  isJoinNowVisible() {
    const duration = this.getDuration();
    if (duration != null) {
      return (duration.startDuration > 0 && duration.endDuration > 0 || duration.startDuration < 0 && duration.endDuration > 0 && duration.endDuration <= CONFIGCONSTANTS.TIME_CONFIG.SPAN) ? true : false;
    }
    return false;
  }

  isJoinNowEnable() {
    const duration = this.getDuration();
    if (duration != null) {
      return (duration.startDuration > 0 && duration.endDuration > 0 && duration.startDuration<=CONFIGCONSTANTS.TIME_CONFIG.JOIN_NOW || duration.startDuration < 0 && duration.endDuration > 0 && duration.endDuration <= CONFIGCONSTANTS.TIME_CONFIG.SPAN) ? true : false;
    }
    return false;
  }
  getDuration() {
    const arrDates = _.split(this.sessionData.timeSlot, "-");
    if (!this.util.isEmpty(this.sessionData.date) && !this.util.isEmpty(this.sessionData.timeSlot) && arrDates.length > 0) {
      const startDate = this.sessionData.date +' ' + arrDates[0];
      const endDate = this.sessionData.date +' ' + arrDates[1];
      
      const startMoment = moment(startDate, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]);
      const endMoment = moment(endDate, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]);

      const startDuration = moment.duration(startMoment.diff(moment(this.dataService.data.dateTime, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]))).asMinutes();
      const endDuration = moment.duration(endMoment.diff(moment(this.dataService.data.dateTime, [CONFIGCONSTANTS.BOOK_SESSION_CONFIG.API_RESPONSE_FORMAT]))).asMinutes();
      return {
        'startDuration': startDuration,
        'endDuration': endDuration
      };
    }
    return null;
  } 
  getNumericValue(val) {
    return !this.util.isEmpty(val) ? parseInt(val) : 0;
  }
}