<section class="multi_step_form video_section">
    <form id="msform">
        <div class="assessment_sec ">
            <div class="pagination_wrap page_wrap">		
            </div>
            <div class="fieldset_wrap">
                <fieldset>
                    <div class="assessment_cnt">
                        <!-- <button type="button" class="next skip"><a>Continue Assessmnet</a></button> -->
                        <div class="form_cnt session">
                            <br>
                            <div class="row brdr">
                                <div class="col-sm-7">
                                    <img class="mrg_top" src="assets/images/icon57.svg" alt="">
                                </div>
                                <div class="col-sm-5">
                                    <div class="right_side_buttons text-center">
                                        <p>Congratulations on successfully completing the program.</p>
                                        <button class="btn_primary" [disabled]="downloadButton.isLoading" (click)="downloadReport()"><a appLoader [text]="'Download Report'" [isLoading]="downloadButton.isLoading"></a></button><br>
                                        <p [@fade] *ngIf="downloadButton.show && !util.isEmpty(downloadButton.message)" [class]="downloadButton.css">{{downloadButton.message}}</p>
                                        <button class="btn_primary" [disabled]="emailButton.isLoading" (click)="emailReport()"><a appLoader [text]="'Email Report'" [isLoading]="emailButton.isLoading"></a></button>
                                        <p [@fade] *ngIf="emailButton.show && !util.isEmpty(emailButton.message)" [class]="emailButton.css">{{emailButton.message}}</p>
                                    </div>
                                </div>
                            </div>
                            <div [ngStyle]="{'margin-top': successFeedback.show == false ? '50px' : 'auto'}" class="feedback_section text-center">
                            <h3 [@fade] *ngIf="successFeedback.show">{{successFeedback.message}}</h3>
                                <p *ngIf="dataService.data[constants.IS_FEEDBACK_SUBMITED] == 1 && dataService.data[constants.FEEDBACK_RATING] >= 4">Tell Your Friends and Family About the Curriculum Befitting Your Child.</p>
                                <div *ngIf="dataService.data[constants.IS_FEEDBACK_SUBMITED] == 1 && dataService.data[constants.FEEDBACK_RATING] >= 4" class="form-group">
                                    <ul class="list-inline text-center">
                                        <li *ngFor="let site of socialShare.ALLOWED_SITES" class="list-inline-item"><a [shareButton]="site.title" 
                                            [url]="socialShare.URL"
                                            [description]="socialShare.DESCRIPTION"
                                        ><img [src]="site.img" [alt]="site.img"></a></li>
                                    </ul>
                                    <br>
                                </div>
                            </div>
                            <div *ngIf="dataService.data[constants.IS_FEEDBACK_SUBMITED] != 1" class="feedback_section text-center">
                                <h3>Feedback</h3>
                                <p>on a scale of 5, how happy are you with the curriculum recommendation?</p>
                                <div class="stars">
                                    <ngx-star-rating [(ngModel)]="data.rating" name="rating"></ngx-star-rating >
                                </div>
                                <div class="form-group">
                                    <textarea [(ngModel)]="data.text" name="feedback" col="5" placeholder="Share your feedback"
                                        class="form-control"></textarea><br>
                                    <button type="button" class="primary_btn cntrl" [disabled]="successFeedback.isLoading || data.rating == 0" (click)="submitReview()" appLoader [text]="'Submit'" [isLoading]="successFeedback.isLoading">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </form>
</section>