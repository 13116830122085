import { UtilService } from './../_services/util.service';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, forwardRef, Input } from '@angular/core';
import * as _ from 'lodash';
@Directive({
  selector: '[appTextValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TextValidatorDirective), multi: true }
  ]
})
export class TextValidatorDirective implements Validator{
  @Input() question;
  constructor(private util: UtilService) { }
  validate(control: AbstractControl): ValidationErrors {
    if (this.util.isNotAllowedAllNumbers(this.question) == true) {
      const exp = new RegExp("^[0-9 ]+$");
      const value = _.trim(control.value);
      if (exp.test(value) == true) {
        return { 'notAllowedNumber': 'Please enter valid response' };
      }
    }
    return null;
  }

}
