import { UtilService } from './../../_services/util.service';
import { DataService } from './../../_services/data.service';
import { CONFIGCONSTANTS } from './../../config/app-constants';
import { AnimationService } from './../../_services/animation.service';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  constant = CONFIGCONSTANTS;
  constructor(private animation: AnimationService, public dataService: DataService,
              private util: UtilService) { }

  ngOnInit(): void {
    this.dataService.checkUserProgressAndRedirect();
  }

  ngAfterViewInit(): void {
    this.animation.init();
    this.animation.showAnimationEffect();
  }

  ngOnDestroy(): void {
    this.animation.hideAnimationEffect();
  }
  onHomeButtonClick() {
    if (this.dataService.isToAllowOpenPersonalInfoPage() == true) {
      this.util.navigate('/personal-information');
    } else {
      this.util.navigate('/assessment');
    }
  }
}
