import { first } from 'rxjs/operators';
import { UtilService } from './../../_services/util.service';
import { VideoPopupService } from './../../_services/video-popup.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { CONFIGCONSTANTS } from './../../config/app-constants';
import { DataService } from './../../_services/data.service';
import { Subscription } from 'rxjs';
import { AnimationService } from './../../_services/animation.service';
import { Component, OnInit, ChangeDetectorRef, ViewChild, TemplateRef, Renderer2, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(30%)', opacity: 0 }),
        animate('600ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(30%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class AssessmentComponent implements OnInit, OnDestroy {
  @ViewChild('f', { static: true }) questionForm: NgForm;
  @ViewChild('assesmentSuccess', { static: true }) assesmentSuccessPopup: TemplateRef<any>;
  subscription: Subscription;
  data = {};
  questions = [];
  categories = [];
  currentPage;
  isAllQuestionsRequired = true;
  isSkippedQuestionModule = false;
  show = true;
  constants = CONFIGCONSTANTS;
  assesmentSuccessPopupData = {
    title: '',
    class: CONFIGCONSTANTS.CONSTANTS.ASSESMENT_POPUP_DEFAULT.CSS_CLASS,
    icon: CONFIGCONSTANTS.CONSTANTS.ASSESMENT_POPUP_DEFAULT.ICON,
    is_last: false,
    id: null
  };
  modelRef: BsModalRef;
  editRank = false;
  pageInfo = {};
  imagePath = '';
  tiggerChangeEvent = true;
  getChangesSubscription: Subscription;
  getChangePageSubscription: Subscription;
  checkChangePageEventSubscription: Subscription;
  skipIfValidSubscription: Subscription;
  getAssesmentCompletedEventSubscription: Subscription;
  getToggleEditEventSubscription: Subscription;
  emitSlideChangeEffectSubscription : Subscription;
  pageVideoOpenSubscription : Subscription;
  assesmentHideSubscription: Subscription;
  constructor(private animation: AnimationService, public dataService: DataService,
              private cd: ChangeDetectorRef, private modalService: BsModalService,
              private renderer: Renderer2, public videoService: VideoPopupService,
              public util: UtilService ) { }
  ngOnInit(): void {
    if (!this.dataService.isAllowToOpenAssesmentPage()) {
      this.dataService.checkUserProgressAndRedirect();
      return;
    }
    this.util.scrollPageToTop();
    this.animation.init();
    this.loadData();
    this.pageVideoOpenSubscription = this.dataService.pageVideoOpen.subscribe(data => {
      this.videoService.getVideoURLOfPage(this.currentPage);
    });
    this.getChangesSubscription = this.dataService.getChanges().subscribe(item => {
      this.data = item;
      this.loadData();
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
      }
    });

    this.emitSlideChangeEffectSubscription = this.dataService.emitSlideChangeEffect.subscribe(data => {
      this.toggle();
    });

    this.getChangePageSubscription = this.dataService.getChangePage().subscribe(item => {
      this.getNextPage();
    });

    this.checkChangePageEventSubscription = this.dataService.checkChangePageEvent.subscribe(item => {
      setTimeout(() => {
        if (this.questionForm.valid && !this.isPageContainsCheckBox()) {
          this.getNextPage();
        }
      }, 200);
    });
    this.skipIfValidSubscription = this.dataService.skipIfValid.pipe(first()).subscribe(data => {
      if (this.questionForm.valid) {
        this.getNextPage();
      }
    });
    this.getAssesmentCompletedEventSubscription = this.dataService.getAssesmentCompletedEvent().subscribe(data => {
      if (this.modelRef == null) {
        this.assesmentSuccessPopupData.title = data.name;
        this.assesmentSuccessPopupData.id = data.id;
        this.assesmentSuccessPopupData.is_last = _.get(data, CONFIGCONSTANTS.IS_LAST_CATEGORY, false);
        const additional = _.get(data, 'additional', {});
        const css = _.get(additional, CONFIGCONSTANTS.ADDITIONAL.ASSESMENT_CSS_CLASS, null);
        const icon = _.get(additional, CONFIGCONSTANTS.ADDITIONAL.ASSESMENT_ICON, null);
        this.assesmentSuccessPopupData.class = !this.util.isEmpty(css) ? css :
                                                CONFIGCONSTANTS.CONSTANTS.ASSESMENT_POPUP_DEFAULT.CSS_CLASS;

        this.assesmentSuccessPopupData.icon = !this.util.isEmpty(icon) ? icon :
                                                CONFIGCONSTANTS.CONSTANTS.ASSESMENT_POPUP_DEFAULT.ICON;
        this.openModel();
      }
    });
    this.getToggleEditEventSubscription = this.dataService.getToggleEditEvent().subscribe(data => {
      this.editRank = data;
    });
    this.dataService.checkIfLastPageIsAnswerLater();
    this.videoService.getVideoURLOfPage(this.currentPage);
    this.dataService.checkIfLastPageAndAllAnswerAttempted();
  }
  getCategoryName() {
    const catId = _.get(this.questions[0], 'category_id', null);
    return catId != null ? this.categories[catId].name : '';
  }
  getNextPage() {
    if (this.questionForm.invalid) {
      return;
    }
    this.editRank = false;
    this.dataService.setNextPage();
    this.toggle();
    this.questions = this.dataService.getPage(this.currentPage);
    this.categories = this.dataService.getCategories(this.currentPage);
  }

  toggle(): void {
    this.show = !this.show;
  }
  loadData() {
    this.currentPage = this.dataService.getActivePage();
    this.questions = this.dataService.getPage(this.currentPage);
    this.imagePath = this.dataService.data.page_info.path;
    this.data = this.dataService.data;
    this.categories = this.dataService.getCategories(this.currentPage);
    this.isAllQuestionsRequired = this.dataService.getIsAllQuestionsRequired();
    this.pageInfo = this.dataService.data.page_info.pages[this.currentPage];
    if (this.isEditRankingVisible() == true) {
      this.editRank = true;
    }
  }

  getPercentage(value: number, total: number) {
    return ((value * 100) / total);
  }

  getCategoryWidth() {
    const arr = _.filter(this.categories, e => {
      const count = _.get(e, 'counts.total', 0);
      return count > 0;
    });
    return arr.length > 0 ? (100 / arr.length) + '%' : '33.3%';
  }

  isQuestionWithMultipleAnswer(question: any) {
    return this.util.isKeyPresent(question, 'additional', CONFIGCONSTANTS.ADDITIONAL.IS_MULTIPLE);
  }

  isPageContainsCheckBox() {
    const questionsWithCheckBox = _.filter(this.questions, (e) => _.get(e, 'answer_type', null) === CONFIGCONSTANTS.INPUT_TYPE.CHECKBOX);
    return questionsWithCheckBox.length > 0 ? true : false;
  }

  public getClass(question: any) {
    const className = _.get(question, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.CSS_CLASS, '');
    if (!_.isEmpty(className)) {
      return className;
    }
    switch (true) {
      case this.questions.length > 3: return 'col-sm-3'; break;
      case this.questions.length > 1 && this.questions.length <= 3: return 'col-sm-6'; break;
      case this.questions.length === 1: return 'col-sm-12'; break;
      default: return 'col-sm-3';
    }
  }
  openModel() {
    this.renderer.addClass(document.body, 'successPopUp');
    this.animation.showBlueBackGroundEffect = true;
    this.animation.emmitGetBlueBackGroundEffectEvent();
    this.modelRef = this.modalService.show(this.assesmentSuccessPopup,
        { class: 'modal-md modal-dialog-centered video_modal bg_grad ' + this.assesmentSuccessPopupData.class ,
        backdrop : 'static',
        keyboard : false});
   this.assesmentHideSubscription = this.modelRef.onHide.subscribe(result => {
      setTimeout(() => {
        this.videoService.getVideoURLOfPage(this.currentPage);
      }, 0);
    })
    this.animation.showAnimationEffect(false);
  }
  hideModel(saveLastQuestion = true) {
    const isLast = this.assesmentSuccessPopupData.is_last;
    if (this.assesmentSuccessPopupData.is_last === true && saveLastQuestion == true) {
      this.dataService.setAssesmentCompleted();
    }
    this.animation.hideAnimationEffect();
    if (this.modelRef!=null) {
      this.modelRef.hide();
    }
    this.renderer.removeClass(document.body, 'successPopUp');
    this.assesmentSuccessPopupData = {
      title: '',
      class: CONFIGCONSTANTS.CONSTANTS.ASSESMENT_POPUP_DEFAULT.CSS_CLASS,
      icon: CONFIGCONSTANTS.CONSTANTS.ASSESMENT_POPUP_DEFAULT.ICON,
      is_last: false,
      id: null
    };
    this.modelRef = null;
    if (isLast === true) {
      this.dataService.checkUserProgressAndRedirect();
    }
  }

  isEditRankingVisible() {
    const multiQuestions = _.filter(this.questions, (q) => {
      return _.get(q, 'answer_type') == CONFIGCONSTANTS.INPUT_TYPE.RANK;
    });
    return multiQuestions.length > 0 ? true : false;
  }

  toggleEditRanking() {
    this.editRank = !this.editRank;
  }
  getPageImages() {
    return _.get(this.pageInfo, 'images_info', []);
  }

  getDidYouKnow() {
    return _.get(this.pageInfo, 'did_you_know', null);
  }

  getImageClass(img) {
    return _.get(img, CONFIGCONSTANTS.CONSTANTS.IMAGE.CSS_CLASS, '');
  }

  getAnimationDirection(img) {
    return _.get(img, CONFIGCONSTANTS.CONSTANTS.IMAGE.ANIMATION, '');
  }
  prevPage() {
    this.dataService.getPrevPage();
  }

  ngOnDestroy() {
    if (this.getChangesSubscription != null) {
      this.getChangesSubscription.unsubscribe();
    }

    if (this.getChangePageSubscription != null) {
      this.getChangePageSubscription.unsubscribe();
    }

    if (this.checkChangePageEventSubscription != null) {
      this.checkChangePageEventSubscription.unsubscribe();
    }


    if (this.skipIfValidSubscription != null) {
      this.skipIfValidSubscription.unsubscribe();
    }

    if (this.getAssesmentCompletedEventSubscription != null) {
      this.getAssesmentCompletedEventSubscription.unsubscribe();
    }

    if (this.getToggleEditEventSubscription != null) {
      this.getToggleEditEventSubscription.unsubscribe();
    }

    if (this.emitSlideChangeEffectSubscription != null) {
      this.emitSlideChangeEffectSubscription.unsubscribe();
    }

    if (this.pageVideoOpenSubscription != null) {
      this.pageVideoOpenSubscription.unsubscribe();
    }
    if (this.assesmentHideSubscription != null) {
      this.assesmentHideSubscription.unsubscribe();
    }
  }
  onPageChangeHandler() {
    this.toggle();
  }
  getCategoryTotalValue(catId, catObj) {
    const requestedCatIdIndex = _.findKey(this.categories, (item) => item['id'] == catId);
    let currentIdIndex = null;
    if (this.questions.length > 0) {
      currentIdIndex = _.findKey(this.categories, (item) => item['id'] == this.questions[0]['category_id']);
    }
    return requestedCatIdIndex < currentIdIndex ? catObj['counts']['total'] : "0";
  }
  navigateToSkipQuestion() {
    this.util.navigate('/skipped-questions');
  }
  onAnswerLaterClick() {
    this.dataService.emitSlideChangeEffect.emit();
    this.dataService.setAnswerLaterToPagesQuestion();
  }
  getSkipNowText() {
    return this.questionForm.valid ? 'Next' : 'Answer later';
  }
  onPopUpNavigationToSkipQuestion() {
    this.assesmentHideSubscription.unsubscribe();
    this.hideModel(false);
    this.navigateToSkipQuestion();
  }
  isLastPageWithSkipAnswer() {
    if (this.dataService.data.currentPage != this.dataService.getMaxPage() || this.dataService.isShowANswerLaterBookNow() == false) {
      return false
    }
    return true;
  }
  isPopupForFirstCategory() {
    const values = _.values(this.dataService.data.categories);
    if (values.length > 0 && values[0]['id'] == this.assesmentSuccessPopupData.id) {
      return true;
    }
    return false;
  }
}
