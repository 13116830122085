import { environment } from './../../environments/environment';
const BASE_URL = environment.apiEndpoint;
const PORTAL = environment.portalURL;
const WP_BASE_URL = environment.wp_apiEndpoint;
const WP_API_VERSION = 'v2'
const WP_URL = WP_BASE_URL + WP_API_VERSION + '/';
const MAIN_PORTAL_URL = environment.mainPortalLink;
export const CONFIG = {
    PORTAL_URL: PORTAL,
    MAIN_PORTAL_URL: MAIN_PORTAL_URL,
    GET_QUESTIONS: BASE_URL + 'questionnaire/fetch-question',
    GET_COUNTRIES: BASE_URL + 'location/get-countries',
    GET_STATES: BASE_URL + 'location/get-states',
    GET_CITIES: BASE_URL + 'location/get-cities',
    GET_CATEGORIES: BASE_URL + 'category/fetch-category',
    STORE_TOKEN: BASE_URL + 'user-token/create',
    STORE_PERSONAL_INFO: BASE_URL + 'user-information/store',
    STORE_ANSWER: BASE_URL + 'answer/store',
    STORE_USER_PROGRESS: BASE_URL + 'progress/store-progress',
    FETCH_TIE_BREAKER_QUESTIONS: BASE_URL + 'questionnaire/fetch-tie-breaker-question',
    GET_AVAILABLE_TIME_SLOTS: BASE_URL + 'session-book/get-timeslot',
    BOO_TIME_SLOT: BASE_URL + 'session-book/book-session',
    SESSION_DETAILS: BASE_URL + 'session-book/get-session',
    CALCULATE_RESULT: BASE_URL + 'result/calculate',
    GET_RESULT: BASE_URL + 'result/user-result',
    STORE_USER_FINAL_RESULT: BASE_URL + 'answer/store-user-final-result-detail',
    SAVE_FEEDBACK: BASE_URL + 'user-feedback/feedback',
    DOWNLOAD_REPORT: BASE_URL + 'user-feedback/get-report-url',
    EMAIL_REPORT: BASE_URL + 'user-feedback/email-report',
    GET_PERSONAL_INFO: WP_URL + 'user-detail',
    FETCH_VIDEOS: BASE_URL + 'video/fetch-videos',
    STORE_ASK_ME_LATER: BASE_URL + 'answer/store-ask-me-later',
};
