import { UtilService } from './../../_services/util.service';
import { Subscription } from 'rxjs';
import { trigger, state, style, transition, animate, query, group } from '@angular/animations';
import { DataService } from './../../_services/data.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as _ from  'lodash';
const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
  animations: [
    trigger('animSlider', [
      transition(':increment',  right),
      transition(':decrement', left),
    ]),
  ],
})
export class PaginationComponent implements OnInit , OnDestroy{
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  viewSpanLength = 4;
  startViewSpanIndex = 0;
  dotViewSpan = 4;
  endViewSpanIndex = this.startViewSpanIndex + this.viewSpanLength;
  subscription: Subscription;
  constructor(public dataService: DataService, private util: UtilService) { }

  ngOnInit(): void {
    this.subscription = this.dataService.getChanges().subscribe(data => {
      const pages = this.getPagesData();
      if (!(this.startViewSpanIndex <= pages.length - 1 && this.endViewSpanIndex <= pages.length - 1)) {
        this.startViewSpanIndex = pages.length - 1 - this.viewSpanLength < 0 ? 0 : pages.length - 1 - this.viewSpanLength;
        this.endViewSpanIndex = pages.length - 1;
      }
      this.setViewSpan();
    });
    this.setViewSpan();
  }
  getPagesData() {
    const pages = _.filter(this.dataService.data.pages, (value,key) => {
      return value['skip'] == false && value['total'] > 0 ? true : false;
    });
    return pages;
  }
  getNextSpan() {
    const pages = this.getPagesData();
    this.startViewSpanIndex = this.endViewSpanIndex < pages.length - 1  ? this.endViewSpanIndex + 1: this.startViewSpanIndex;
    this.endViewSpanIndex = this.startViewSpanIndex + this.viewSpanLength > pages.length - 1 ? pages.length - 1 : this.startViewSpanIndex + this.viewSpanLength;
  }

  getPrevSpan() {
    const pages = this.getPagesData();
    this.startViewSpanIndex = this.startViewSpanIndex - this.viewSpanLength - 1 < 0  ? 0 : this.startViewSpanIndex - this.viewSpanLength - 1;
    this.endViewSpanIndex = this.startViewSpanIndex + this.viewSpanLength;
  }
  setPage(data, index) {
    const pages = this.getPagesData();
    const currentPageId = this.dataService.getCurrentPageId();
    if (data['id'] == currentPageId) {
      return false;
    }
    if (((data['total'] == data['attempted'] || (index > 0 && pages[index - 1]['total'] == pages[index - 1]['attempted']) || index == 0) && data['skip']!=true) || this.checkPageInRangeOfSkipAnswer(data, index) == true) {
      this.dataService.setNextPage(data['id']);
      setTimeout(() => {
          this.dataService.emitSlideChangeEffect.emit();
      }, 100);
    }
  }
  isShowPaginationToolTip(data, index) {
    const pages = this.getPagesData();
    if (index == 0) return false;
    if ((data['total'] == data['attempted'] || (index > 0 && pages[index - 1]['total'] == pages[index - 1]['attempted'])) && data['skip']!=true) {
      return false;
    }
    if (this.checkPageInRangeOfSkipAnswer(data,index) == true) {
      return false;
    }
    return true;
  }
  ngOnDestroy() {
    if (this.subscription!=null) {
      this.subscription.unsubscribe();
    }
  }
  isFirstPage() {
    return this.startViewSpanIndex == 0;
  }

  isLastPage() {
    const pages = this.getPagesData();
    return this.endViewSpanIndex == pages.length - 1;
  }
  setViewSpan() {
    const pages = this.getPagesData();
    const pageId = this.dataService.getCurrentPageId();
    const index = _.findKey(pages, (d) => d['id'] == pageId);
    if (!(this.startViewSpanIndex <= index && this.endViewSpanIndex>=index)) {
      const obj = this.getStartEndIndex(index);
      if (!this.util.isEmpty(obj)) {
          this.startViewSpanIndex = obj['start'];
          this.endViewSpanIndex = obj['end'];
      }
    }
  }
  getStartEndIndex(index) {
    const pages = this.getPagesData();
    let start = 0;
    let end = start + this.viewSpanLength;
    while(!(index >= start && index <= end)) {
      start = end + 1;
      end = start + this.viewSpanLength;
      if (end >= pages.length -1) end = pages.length;
      if (end == pages.length -1) {
        break;
      }
    }
    return {
      start,
      end
    }
  }
  checkPageInRangeOfSkipAnswer(p, index) {
    const result = this.dataService.getMaximumSkipANswerPage();
    if (result!= null) {
      const pages = this.getPagesData();
      const searchInd = _.findIndex(pages, (q) => q['id'] == result);
      if (searchInd >= index - 1) {
        return true;
      }
    }
    return false;
  }
}
