import { UtilService } from './../_services/util.service';
import { DataService } from './../_services/data.service';
import { Component, forwardRef, Input, Host, Optional, Self, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, NgModel, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { CONFIGCONSTANTS } from './../config/app-constants';
import * as _ from 'lodash';
@Component({
    selector: 'app-group',
    template: `<ng-content></ng-content>`,
    // encapsulation: ViewEncapsulation.None,
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => GroupComponent),
          multi: true
        }
    ],
    styles: [`
        :host{
            width: 100% !important;
        }
    `]
})
export class GroupComponent implements ControlValueAccessor {
    private _model: any;
    private onChange: (m: any) => void;
    private onTouched: (m: any) => void;
    @Input() questionId: any;
    @Input() question: any;
    @Input() tiggerChangeEvent = true;
    @Input() isSkippedQuestionModule = false;
    
    constructor(private dataService: DataService, @Host() private form: NgForm) {

    } 

    get model() {
        return this._model;
    }

    writeValue(value: any): void {
        this._model = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    set(value: any) {
        this._model = value;
        this.onChange(this._model);
    }

    addOrRemove(value: any) {
        if (this.contains(value)) {
            this.remove(value);
        } else {
            this.add(value);
        }
    }

    contains(value: any): boolean {
        if (this._model instanceof Array) {
            return this._model.indexOf(value) > -1;
        } else if (!!this._model) {
            return this._model === value;
        }

        return false;
    }

    public add(value: any) {
        if (!this.contains(value)) {
            if (this._model instanceof Array) {
                const exceptionalVal: any[] =  CONFIGCONSTANTS.CHECKBOX_EXCEPTIONAL_OPTIONAS;
                if (_.includes(exceptionalVal, value)) {
                        this._model = [];
                } 
                const found = exceptionalVal.some(r => this._model.includes(r));
                if (found == true) {
                    _.forEach(exceptionalVal, (d) => {
                        _.remove(this._model, (fval) => fval == d);
                    })
                }
                this._model.push(value);
            } else {
                this._model = [value];
            }
            this.onChange(this._model);
            this.dataService.setQuestionsAnswer(this.question.id, this._model, this.tiggerChangeEvent, this.isSkippedQuestionModule);
        }
    }

    public remove(value: any) {
        const index = this._model.indexOf(value);
        if (!this._model || index < 0) {
            return;
        }

        this._model.splice(index, 1);
        this.onChange(this._model);
        this.dataService.setQuestionsAnswer(this.question.id, this._model, this.tiggerChangeEvent, this.isSkippedQuestionModule);
    }
}
