import { UtilService } from './../_services/util.service';
import { CONFIGCONSTANTS } from './../config/app-constants';
import { DataService } from './../_services/data.service';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { Directive, ElementRef, forwardRef, Input } from '@angular/core';
import * as _ from 'lodash';
@Directive({
  selector: '[appOtherInputValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => OtherInputValidatorDirective), multi: true }
  ]
})
export class OtherInputValidatorDirective implements Validator {
  @Input() question = [];
  @Input() isWithCheckBox = false;
  @Input() onlyTextField = false;
  readonly minimumCharachterRequired = 3;
  constructor(private dataService: DataService, private el: ElementRef, private util: UtilService) { }
  validate(control: AbstractControl): ValidationErrors {
    if (!this.onlyTextField) {
      let data = [...this.dataService.getDataList(this.question)];

      if (!this.isWithCheckBox) {
        if (_.includes(data, CONFIGCONSTANTS.INPUT_TYPE.OTHERS)) {
          _.remove(data, (q) => q == CONFIGCONSTANTS.INPUT_TYPE.OTHERS);

          if (!this.util.isEmpty(control.value) &&
            !_.includes(data, control.value) && (control.value as string).trim().length < this.minimumCharachterRequired) {
            return { 'otherMinimumRequired': 'Please enter at least ' + this.minimumCharachterRequired + ' charachters.' };
          }
        }
      } else {
        if (control != null && control.value != null && _.includes(data, CONFIGCONSTANTS.INPUT_TYPE.OTHERS)) {
          _.remove(data, (q) => q == CONFIGCONSTANTS.INPUT_TYPE.OTHERS);
          const userSelectData = [..._.get(control, 'value', [])];
          _.remove(userSelectData, (i) => {
            return _.includes(data, i);
          });
          const userAns = userSelectData.length > 0 ? userSelectData[0] : null;

          if (!this.util.isEmpty(userAns) && (userAns as string).trim().length < this.minimumCharachterRequired) {
            return { 'otherMinimumRequired': 'Please enter at least ' + this.minimumCharachterRequired + ' charachters.' };
          }
        }
      }
    } else {
      if (!this.util.isEmpty(control.value) && (control.value as string).trim().length < this.minimumCharachterRequired) {
        return { 'otherMinimumRequired': 'Please enter at least ' + this.minimumCharachterRequired + ' charachters.' };
      }
    }
    return null;
  }
}
