import { UtilService } from './../_services/util.service';
import { CONFIGCONSTANTS } from './../config/app-constants';
import { DataService } from './../_services/data.service';
import { Component, forwardRef, Input, Host, Optional, OnChanges, Self } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, NgModel } from '@angular/forms';
import * as _ from 'lodash';
@Component({
    selector: 'app-radio-group',
    template: `
            <div [class]="'row '+ util.getSectionClass(q)">
                <ng-container *ngFor="let ans of dataService.getDataList(q); let i=index;">
                <ng-container *ngIf="ans == constants.INPUT_TYPE.OTHERS else field">
                <div class="col-sm-12">
                    <ng-container *ngIf="util.isQuestionTypeIsAutoComplete(q) else rawText">
                        <app-autocomplete  [callStateOnChange]="false" #l="ngModel" [(ngModel)]="otherval" [data]="otherval" (autocompleteChange)="onAutoCompleteComponentChange($event)" [question]="q" [questionId]="q.id" [id]="q.id" [required]="required" [name]="q.id"></app-autocomplete>
                    </ng-container>
                    <ng-template #rawText>
                    <input type="text" appOtherInputValidator [onlyTextField]="true" [ngClass]="{'minimum-required': errors!=null && errors?.otherMinimumRequired!=null}" #rText="ngModel" (change)="InputChange(rText?.valid)" [(ngModel)]="otherval" class="form-control" [id]="q.id+'_'+i" [name]="q.id+'_other'" placeholder="Others">
                        <ng-container *ngIf="errors!=null && errors?.otherMinimumRequired!=null">
                            <span class="error_msg">{{errors?.otherMinimumRequired}}</span>
                        </ng-container>
                    </ng-template>
                </div>
                </ng-container>
                <ng-template #field>
                    <div [class]="getClass(q)">
                        <div class="radio-group">
                            <input appOnlyCharachters [validate]="util.isOnlyAllowCharachters(q)" [(ngModel)]="q.user_answer" (click)="onRadioClick($event, ans)" (change)="valueChange()" [required]="required" appPlaySound [id]="q.id+'_'+i" [name]="q.id" type="radio" [value]="ans">
                            <label [for]="q.id+'_'+i">{{ans}}</label>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </div>`,
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => RadioGroupComponent),
          multi: true
        }
    ],
    styles: [`
    :host {
        width: 100%;
    }
    `]
})

export class RadioGroupComponent implements ControlValueAccessor, OnChanges {
    @Input() pageContainsCheckBox = false;
    @Input() required = true;
    @Input() questions: any[];
    @Input() errors:any;
    constants = CONFIGCONSTANTS;
    private _model: any;
    private onChange: (m: any) => void;
    private onTouched: (m: any) => void;
    otherval = '';
    @Input() tiggerChangeEvent = true;
    @Input() isSkippedQuestionModule = false;
    @Input() q: any;

    constructor(public dataService: DataService, @Optional() @Host() private form: NgForm, public util: UtilService) {

    }

    ngOnChanges() {
        const data = this.dataService.getDataList(this.q);
        _.remove(data, (qn)=> qn == CONFIGCONSTANTS.INPUT_TYPE.OTHERS);
        const ans = _.get(this.q,'user_answer', '');
        this.otherval = "";
        if (!_.includes(data, ans)) {
            this.otherval = ans;
        }
    }

    writeValue(value: any): void {
        this._model = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    set(value: any) {
        this._model = value;
        this.onChange(this._model);
    }

    valueChange() {
       this.otherval = '';
       this.set(_.get(this.q, 'user_answer'));
       this.dataService.setQuestionsAnswer(_.get(this.q, 'id'), this._model, this.tiggerChangeEvent, this.isSkippedQuestionModule);
    }

    async InputChange(valid) {
        const data = this.dataService.getDataList(this.q);
        _.remove(data, (qn)=> qn == CONFIGCONSTANTS.INPUT_TYPE.OTHERS);
        if (!_.includes(data, this.otherval)) {
            _.set(this.q, 'user_answer', this.otherval);
        } else {
            _.set(this.q, 'user_answer', this.otherval);
            this.otherval = "";
        }
        this.set(_.get(this.q, 'user_answer'));
        if (valid == true) {
            this.dataService.setQuestionsAnswer(_.get(this.q, 'id'), this._model, this.tiggerChangeEvent, this.isSkippedQuestionModule);
        }
    }

    public getClass(question: any) {
        const className = _.get(question, 'additional.' + CONFIGCONSTANTS.ADDITIONAL.CSS_CLASS, '');
        if (!_.isEmpty(className)) {
          return className;
        }
        switch (true) {
          case this.questions.length > 3: return 'col-sm-3'; break;
          case this.questions.length > 1 && this.questions.length <= 3: return 'col-sm-6'; break;
          case this.questions.length === 1: return 'col-sm-12'; break;
          default: return 'col-sm-3';
        }
    }

    onAutoCompleteComponentChange($event) {
        if (!this.util.isEmpty(this.otherval)) {
            this.InputChange(true);
        }
    }

    onRadioClick($event, value) {
        if (value == this._model) {
            this.valueChange();
        }
    }
}

